import React, { useCallback, useMemo, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { StyledSelect } from '../Sidebar/StyledSelect';
import CircularProgress from '@material-ui/core/CircularProgress';
import { css } from 'emotion'
import theme from '../../shared/mikeSharedTheme'
import { useDispatch, useSelector } from 'react-redux';
import { chartBulletSetSelected, chartDataGet, fastWaveConfigSet, setChartAxisRange, setSelectedBoundaryOrForcing } from '../../actions/mapContent';
import { IState } from '../../reducers';
import { IListItem } from '../../reducers/mapContent';
import { IChartItem, IInitialChartData } from '../../model/IInitialChartData';
import { IChartBullet } from '../../model/IChartData';
import Chart from './Chart';
import { addMessage } from '../../actions/message';
import { JOBS, MAX_SELECTED_NODES, MIN_SELECTED_NODES } from '../../shared/constants';
import { useIntl } from 'react-intl';
import { IFastWaveConfig, IJob } from '../../model/IFastWaveConfig';
import { getJob } from '../../helpers/fastwave';
import { JOBS_IN_PROGRESS } from '../../model/IJobStatus';
import { createContainer } from '../../actions/job';
import MikeButton from '../mike-button';

const selectContainerStyle = css`
  padding-right: ${theme.spacing(1)}px;
  padding-left: ${theme.spacing(1)}px;
  display: flex;
  align-items: center;
`
const labelStyle = css`
  padding: ${theme.spacing(1)}px;
  white-space: nowrap;
  padding-bottom: ${theme.spacing(1)}px; 
`
const placeHolderStyle= css`
  width: ${theme.spacing(8)}px;  
`
const spinnerContainerStyle = css`  
  align-items: center;
  justify-content: center;
`

const flexStyle = css`
  display: flex;  
  align-items: center; 
`
const spaceBetweenStyle = css`
  display: flex; 
  justify-content: space-between; 
  align-items: center;
  width: ${theme.spacing(98)}px;   
`

interface IProps {
  selectForTestRun?: boolean;
}

const VALUEFIELD = "id";
const PRIMARYFIELD ="displayName";
const NAMEFIELD = "name";
const XFIELD = "x";
const YFIELD = "y";

const ChartContainer = (props: IProps) => {

  const {selectForTestRun} = props;

  const dispatch = useDispatch();
  const intl = useIntl();

  const boundariesAndForcings: Array<IListItem>  = useSelector(
    (state: IState) => state.mapContent.boundaryAndForcings
  ); 

  const selectedBoundaryOrForcing: IListItem  = useSelector(
    (state: IState) => state.mapContent.selectedBoundaryOrForcing
  ); 

  const selectedXItem: IChartItem  = useSelector(
    (state: IState) => state.mapContent.selectedChartXItem
  ); 

  const selectedYItem: IChartItem  = useSelector(
    (state: IState) => state.mapContent.selectedChartYItem
  ); 

  const previewData: IInitialChartData = useSelector(
    (state: IState) => state.mapContent.initialChartData
  ); 

  const chartBullets: Array<IChartBullet>  = useSelector(
    (state: IState) => state.mapContent.chartBullets
  ); 

  const loadingChartData: boolean  = useSelector(
    (state: IState) => state.mapContent.loadingChartData
  ); 

 const chartAxisRange = useSelector(
  (state: IState) => state.mapContent.chartAxisRange
); 

const selectedEvents: Array<string> = useSelector(
  (state: IState) => state.mapContent.selectedEvents
); 

const jobs: Array<IJob> = useSelector(
  (state: IState) => state.job.jobs
);  

const config: IFastWaveConfig = useSelector(
  (state: IState) => state.mapContent.fastWaveConfig
); 

const readyForTestModelRun = useMemo(() => {
  return config && config.test_event_time && config.setup ? true : false
}, [config])

const jobIsRunning = useMemo(() => {
  const job = getJob(jobs, JOBS.TESTSETUP)
  const jobStatus = job !== undefined && job.status ? job.status : ""
  return jobStatus && JOBS_IN_PROGRESS.includes(jobStatus.toLowerCase())    
}, [jobs])

const [selectedTimeStep, setSelectedTimeStep] = useState("")

  const handleChangeChartAxisRange = useCallback((axisRange) => {   
    dispatch(setChartAxisRange(axisRange))
  }, [dispatch])
  
  const handleSwitchSelection = useCallback((id: string) => { 
    if (!selectForTestRun){
      if (selectedEvents && selectedEvents.length  === MIN_SELECTED_NODES && selectedEvents.includes(id)){
        dispatch(addMessage(intl.formatMessage({id: 'chartContainer.pleaseKeepAtLeast'}) + " " + MIN_SELECTED_NODES + " " + intl.formatMessage({id: 'chartContainer.nodesSelected'})))
      }
      else if (selectedEvents && selectedEvents.length === MAX_SELECTED_NODES && !selectedEvents.includes(id)){
        dispatch(addMessage(intl.formatMessage({id: 'chartContainer.pleaseDoNotSelectMoreThan'}) + " " + MAX_SELECTED_NODES + " " + intl.formatMessage({id: 'chartContainer.nodes'})))
      }
      else
      {
        dispatch(chartBulletSetSelected(id, selectForTestRun)) 
      }
    }
    else
    { 
      dispatch(chartBulletSetSelected(id, selectForTestRun)) 
      const selected = chartBullets.find(d =>  d.id === id)   
      const timeStep = selected.Time    
      setSelectedTimeStep(timeStep) 
      const configIsUptodate = config && config.test_event_time && config.test_event_time === timeStep ? true : false
      if (!configIsUptodate){
        const updatedConfig = {...config, test_event_time: timeStep }
        dispatch(fastWaveConfigSet(updatedConfig, true))
      } 
    }   
  }, [chartBullets, config, dispatch, intl, selectForTestRun, selectedEvents])

  const handleChangeBoundary = (event: any) => { 
    dispatch(setChartAxisRange(null)) 
    const newBoundaryId = event.target.value
    const newBoundary = boundariesAndForcings.find((d: any) => d.id === newBoundaryId)
    dispatch(setSelectedBoundaryOrForcing(newBoundary))
  }

  const handleChangeXAxis = useCallback((event: any) => {   
    dispatch(setChartAxisRange(null))   
    const newXId = event.target.value
    const newXItem = previewData.items.find(d => d.id === newXId)
    dispatch(chartDataGet(newXItem, selectedYItem))
  }, [dispatch, previewData, selectedYItem])

  const handleChangeYAxis = useCallback((event: any) => {   
    dispatch(setChartAxisRange(null)) 
    const newYId = event.target.value
    const newYItem = previewData.items.find(d => d.id === newYId)
    dispatch(chartDataGet(selectedXItem, newYItem))
  }, [dispatch, previewData, selectedXItem]) 

  if (!selectedBoundaryOrForcing){
    return null
  }

  
  const onRunTest = () => {
    const timeStepForTestRun = selectedTimeStep ? selectedTimeStep : config.test_event_time
    dispatch(createContainer(JOBS.TESTSETUP, {...config, test_event_time: timeStepForTestRun }))
  }

  return (    
    loadingChartData ? 
      <div className={spinnerContainerStyle}><CircularProgress/></div>:       
        previewData && previewData.items ? <>
      <div className={selectContainerStyle}>
        <div className={spaceBetweenStyle}>
          <div className={flexStyle}>
            <Typography variant="body2" className={labelStyle}>{intl.formatMessage({id: 'chartContainer.selectBoundaryOrForcing'}) + ":"}</Typography>  
            <StyledSelect
              fullWidth={false}
              name={NAMEFIELD}
              value={selectedBoundaryOrForcing && selectedBoundaryOrForcing.id ? selectedBoundaryOrForcing.id: undefined}         
              valueField={VALUEFIELD}
              primaryField={PRIMARYFIELD}
              items={boundariesAndForcings}
              onChange={handleChangeBoundary}
            />
          </div>
          {selectForTestRun && 
          <MikeButton onClick={onRunTest} buttontype="secondary" active={jobIsRunning} disabled={!readyForTestModelRun}>
            {intl.formatMessage({id: 'setup.runTestButton'})}
          </MikeButton>
          }  
        </div>          
      </div> 
      <div className={selectContainerStyle}>       
        <Typography variant="body2" className={labelStyle}>{intl.formatMessage({id: 'chartContainer.axisConfig'})}</Typography>
        <div className={placeHolderStyle}/>
        <Typography variant="body2" className={labelStyle}>{intl.formatMessage({id: 'chartContainer.xLabel'}) + ":"}</Typography>
        <StyledSelect
          fullWidth={true}
          name={XFIELD}
          value={selectedXItem && selectedXItem.id ? selectedXItem.id : undefined}         
          valueField={VALUEFIELD}
          primaryField={PRIMARYFIELD}
          items={previewData.items}
          onChange={handleChangeXAxis}
        />
        <Typography variant="body2" className={labelStyle}>{intl.formatMessage({id: 'chartContainer.yLabel'}) + ":"}</Typography>
        <StyledSelect
          fullWidth={true}
          name={YFIELD}
          value={selectedYItem && selectedYItem.id ? selectedYItem.id : undefined}         
          valueField={VALUEFIELD}
          primaryField={PRIMARYFIELD}
          items={previewData.items}
          onChange={handleChangeYAxis}
        />
      </div>
      {selectedXItem && selectedXItem.id && previewData.axises && previewData.axises.length > 1 && selectedYItem &&    
      <Chart 
        onChangeAxisRange={handleChangeChartAxisRange} 
        isTimeSeries={selectedXItem.id === previewData.axises[0].id} selectForTestRun={selectForTestRun} 
        axisLabels={[selectedXItem.displayName, selectedYItem.displayName + " (" + selectedYItem.unit + ")"]} 
        switchSelection={handleSwitchSelection} 
        data={chartBullets}
        chartAxisRange={chartAxisRange}
      /> }

    </> : null
  );
};

export default ChartContainer;