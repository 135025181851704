import { call, put } from "redux-saga/effects";
import getConfig from "../apis/config";
//import getMock from "../apis/mock";
import { IConfig } from "../model/IConfig";
import { setConfig } from "../../src/actions/auth";
//import { csvToObject } from "../helpers/csvToObject";
// eslint-disable-next-line import/no-webpack-loader-syntax


export default function* bootstrap(): any {
  try {  
    const config: IConfig = yield call(getConfig); 
    yield put(setConfig(config));
  } catch (error) {
    // tslint:disable-next-line:no-console
    console.log("Failed to read app config", error);
  }
}
