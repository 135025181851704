import * as React from 'react';
import { IMikeTheme } from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme';
import {
  Typography,
  Breadcrumbs,
  makeStyles,
  createStyles,
  Link,
  Tooltip,
} from '@material-ui/core';
import { IState } from '../../reducers';
import ProjectLink from './ProjectLink';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { IGetProjectPath } from '@mike/mike-shared-frontend/mike-project-explorer/models/IGetProject';
import { useSelector } from 'react-redux';
import { IUser } from '../../model/IUser';

interface IProps { 
  rootBreadcrumpsName?: string;
}

const useStyles = makeStyles((theme: IMikeTheme) => {
  return createStyles({
    mikeBreadcrumpsLinkLabel: {
      cursor: 'pointer',
      color: theme.palette.text.primary,
      '&:hover': {
        color: theme.palette.text.primary,
      },
      '&:active': {
        color: theme.palette.text.primary,
      },
      '&:focus': {
        color: theme.palette.text.primary,
      },
      maxWidth: '15vw',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',
    },
    mikeBreadcrumpsLastLabel: {
      fontWeight: 'bold',
      color: theme.palette.text.primary,
      maxWidth: '15vw',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',
    },
    mikeBreadcrumpsTenantLabel: {    
      color: theme.palette.text.primary,
      maxWidth: '15vw',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',
    },
  });
});

/**
 * @name AppBreadcrumpsNav
 * @summary Renders a list of links. Meant to be used as breadcrumbs in the MikeTopbar.
 */
export const AppBreadcrumpsNav = (props: IProps) => {
  const {   
   
    rootBreadcrumpsName,
    ...rest
  } = props;

  const breadcrumpsContent: Array<IGetProjectPath> = useSelector((state: IState) => state.folders.projectPath);
  const  user: IUser = useSelector((state: IState) => state.auth.user);  
  const classes = useStyles(props);

  const handleRootClick = (e: any) => {
    e.preventDefault(); 
  };

  return (
    <Breadcrumbs
      maxItems={5}
      separator={<NavigateNextIcon color="primary" fontSize="small" />}
      aria-label="mikebreadcrumb"
      {...rest}>
      {rootBreadcrumpsName && (
        <Tooltip title={rootBreadcrumpsName}>
          <Link className={classes.mikeBreadcrumpsLinkLabel} onClick={handleRootClick}>
            <Typography className={classes.mikeBreadcrumpsLinkLabel} variant="body2">
              {rootBreadcrumpsName}
            </Typography>
          </Link>
        </Tooltip>
      )}
      {breadcrumpsContent.map((breadcrump: IGetProjectPath, index: number) => {
        const last = index === breadcrumpsContent.length - 1;
        const tenant = index === 0 && breadcrump.name === user.tenantName
        return (
          <Tooltip key={index + '_' + breadcrump.name} title={breadcrump.name}>
            <Typography
              variant="body2"
              className={
                tenant ? classes.mikeBreadcrumpsTenantLabel : last ? classes.mikeBreadcrumpsLastLabel : classes.mikeBreadcrumpsLinkLabel
              }>
              {tenant ? (
                <ProjectLink
                key={index}
                to={'/'}
                text={breadcrump.name}                
              />
              ) : (
                <ProjectLink
                  key={index}
                  to={'/project/' + breadcrump.id}
                  text={breadcrump.name}                
                />
              )} 
            </Typography>
          </Tooltip>
        );
      })}
    </Breadcrumbs>
  );
};

export default AppBreadcrumpsNav;
