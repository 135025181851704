/**
 * Feature flags are meant for being able to turn new development off untill it is ready.
 * These flags should be deleted once the implementation is ready, at the latest when the feature have been seen working in production.
 *
 * Note: would be nice to not have more than 5 of these at a time :)
 */
export const FeatureFlags = {  
  // if 'ENABLE_OWN_ENVIRONMENTAL_DATA_OPTION' is truthy in localStorage, the option for uploading own environmental data will be enabled.
  // enableOwnEnvironmentalDataOption: localStorage.getItem('ENABLE_OWN_ENVIRONMENTAL_DATA_OPTION') && localStorage.getItem('ENABLE_OWN_ENVIRONMENTAL_DATA_OPTION') === 'true',  
};

export default FeatureFlags;
