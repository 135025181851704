import { css } from 'emotion'
import theme from './mikeSharedTheme'

export const titleContainer = css`
  background-color: ${theme.palette.mediumGrey.main};
  padding-bottom: ${theme.spacing(1)}px;
  padding-top: ${theme.spacing(1)}px;
  display: flex;
  justify-content: space-between;
`
export const firstOutlineTitleContainer = css`  
  padding-bottom: ${theme.spacing(1)}px;
  padding-top: ${theme.spacing(1)}px;
`

export const titleButtonsContainer = css`  
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const outlineTitleContainer = css`  
  padding-bottom: ${theme.spacing(1)}px;
  padding-top: ${theme.spacing(3)}px;
`

export const centerSpinnerStyle = css`
  display: flex;
  justify-content: center;
  align-content: center;  
`
export const titleStyle = css`
  padding-bottom: ${theme.spacing(1)}px;
  padding-top: ${theme.spacing(1)}px;
  padding-left: ${theme.spacing(2)}px;
  padding-right: ${theme.spacing(2)}px;
`
export const subTitleStyle = css`
  padding-left: ${theme.spacing(2)}px;
  padding-right: ${theme.spacing(2)}px;
  color: ${theme.palette.primary.light};
`

export const buttonsContainerStyle = css`
  display: flex;
  justify-content: flex-end;
  padding: ${theme.spacing(2)}px; 
`

export const spinnerContainerStyle = css`
  height: 50vh; 
  align-items: center;
  justify-content: center;
`
export const chartContainerStyle = css`
  padding-top: ${theme.spacing(1)}px;
`
export const radioStandardStyle = css`
  padding: 5px;
  margin: 0 5px;
  font-size: 100%;  
`

export const fieldsetWidthStyle = css`  
  width: ${theme.spacing(44)}px;  
  `

  export const dialogActionsStyle= css` 
    background-color: ${theme.palette.background.paper};
    color: ${theme.palette.background.paper};
    padding-right: ${theme.spacing(3)}px;
    width: 100%;
    z-index: 11;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.16);
    `
