import React, { useEffect, useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';
import { IState } from '../../reducers'
import { IGetProject } from '../../model/IGetProject';
import AllProjectsTable from './RootProjectsTable';
import { useNavigate } from 'react-router-dom'
import { getProjects, setProjectsPagination, setProjectsRowsPerPage, setProjectsNamePrefix, setProjectsSort, setCreateRootProjectPanelOpen, deleteRootProjects } from '../../actions/projects';
import SearchInput from '../SearchInput'
import { IMikeTheme } from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme';
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { MikeButton, MikeSlidingPanel } from '@mike/mike-shared-frontend';
import CreateFolderForm from '../CreateFolderForm';
import { useIntl } from 'react-intl';
import { ReactComponent as Plus } from "@mike/mike-shared-frontend/media/icons/Plus";
import { iconWhiteStyle } from '../EditPointsForm/iconStyles';
import { mikePalette } from '../../shared/mikeSharedTheme';
import MikeDialog from '../DialogComponents/MikeDialog';

const useStyles = makeStyles((theme: IMikeTheme) =>
  createStyles({  
    container: {
      overflowY:'auto',
    },
    appBarSpacer: {
      height: theme.spacing(4),
      backgroundColor: theme.palette.lightGrey && theme.palette.lightGrey.main
    },
    tableTopHeader: { 
      position: 'sticky',
      top: 0, //theme.spacing(4),    
      zIndex: 1,
      backgroundColor: theme.palette.lightGrey && theme.palette.lightGrey.main,
    },
    title: {
      paddingLeft: theme.spacing(4),
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(2)
    },
    flex: {    
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',  
      width: '99vw',   
      paddingLeft: theme.spacing(4),
      paddingBottom: theme.spacing(5),
      paddingRight: theme.spacing(4),
    },
    button: {
      border: '2px solid ' + mikePalette.secondary.main,
      color: mikePalette.background.default,
      '&[disabled]': {
        color: mikePalette.background.paper,
        opacity: 0.5,
        cursor: 'not-allowed',
        border: '2px solid ' + mikePalette.secondary.light,
      }
    }   
  })
)

const RootProjects = () => {   
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();

  useEffect(() => {
    dispatch(setProjectsNamePrefix(''))    
    dispatch(getProjects(0, 25, 'CreatedAt', 'desc', ''))
   }, [dispatch]);

  const isRootContributor: boolean = useSelector((state: IState) => state.auth.isRootContributor); 

   const projects: IGetProject[] = useSelector((state: IState) => state.projects.projects); 
  
  const sortBy: string = useSelector(
    (state: IState) => state.projects.sortBy
  );  

  const sortOrder: 'asc' | 'desc' = useSelector(
    (state: IState) => state.projects.sortOrder
  );  

  const rowsPerPage: number = useSelector(
    (state: IState) => state.projects.rowsPerPage
  );

  const namePrefix: string = useSelector(
    (state: IState) => state.projects.namePrefix
  );

  const loading: boolean = useSelector(
    (state: IState) => state.projects.loading
  ); 

  const page: number = useSelector(
    (state: IState) => state.projects.page
  ); 

  const totalCount: number = useSelector(
    (state: IState) => state.projects.totalCount
  ); 

  const createRootProjectPanelIsOpen = useSelector(
    (state: IState) => state.projects.createPanelIsOpen
  ); 

  const [selectedRows, setSelectedRows] = useState(new Array<IGetProject>());
  const [projectsToBeDeleted, setProjectsToBeDeleted] = useState(new Array<IGetProject>());
  const [projectToBeEdited, setProjectToBeEdited] = useState<IGetProject | null>(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const onDeleteOneProject =  (deleteProject: IGetProject) => {   
    if (deleteProject.capabilities.canDelete) {
      setProjectsToBeDeleted([deleteProject])
      setDeleteConfirmationOpen(true)
    }
  }

  const onEditProject =  useCallback((editProject: IGetProject) => {   
    if (editProject.capabilities.canEdit) {
      setProjectToBeEdited(editProject)
      dispatch(setCreateRootProjectPanelOpen(true))   
    }
  }, [dispatch])

/*   const onDeleteProjects = React.useCallback(() => {
    setProjectsToBeDeleted(selectedRows)
    setDeleteConfirmationOpen(true)
  }, [selectedRows]) */

  const onCancelDeleteProjects = () => {
    setProjectsToBeDeleted(new Array<IGetProject>())
    setDeleteConfirmationOpen(false)
  }

  const onOkDeleteProjects = React.useCallback(() => {
    const ids = projectsToBeDeleted.filter((project: IGetProject) => project.capabilities.canDelete).map((project: IGetProject) => project.id)
    dispatch(deleteRootProjects(ids))
    setProjectsToBeDeleted(new Array<IGetProject>())
    setDeleteConfirmationOpen(false)
  }, [dispatch, projectsToBeDeleted])

  const onSelectionChange = (selectedItems: Array<IGetProject>) => {
    setSelectedRows(selectedItems)
  };
  
  const handleSelect = useCallback((selectedProject: IGetProject) => {
    navigate('/project/' + selectedProject.id);
  }, [navigate])

  const handleRequestSort = useCallback((orderBy: string | ((item: any) => string | number), order: 'asc' | 'desc') => {   
    dispatch(setProjectsSort(orderBy.toString(), order)) 
    dispatch(setProjectsPagination(0))   
  }, [dispatch])

  const handleChangePage = useCallback((newPage: number) => { 
     dispatch(setProjectsPagination(newPage))
  }, [dispatch])

  const handleChangeRowsPerPage = useCallback((newRowsPerPage: number) => {  
    dispatch(setProjectsRowsPerPage(newRowsPerPage))
  }, [dispatch])

  const [topOffset, setTopOffset] = useState(0)
  const measuredRef = useCallback(node => {
    if (node !== null) {
      setTopOffset(node.getBoundingClientRect().height)
    }
  }, [])

  const handleSetFilter = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {   
    dispatch(setProjectsNamePrefix(e.target.value))     
    dispatch(setProjectsPagination(0))
  }, [dispatch]);

  const handleClearFilter = useCallback(() => { 
    dispatch(setProjectsNamePrefix('')) 
    dispatch(setProjectsPagination(0))
  }, [dispatch]);

  const onShowCreateRootProject = useCallback((show?: boolean) => {
    setProjectToBeEdited(null)
    dispatch(setCreateRootProjectPanelOpen(show))    
  }, [dispatch]);

  return (
   <>
    <div className={classes.tableTopHeader} ref={measuredRef}>
      <div className={classes.appBarSpacer} />  
      <Typography className={classes.title} variant={'h1'}>{"Folders"}</Typography> 
      <div className={classes.flex}>
        <SearchInput        
          value={namePrefix}
          onChangeValue={handleSetFilter}
          onResetValue={handleClearFilter}
        />  
        <MikeButton className={classes.button} onClick={() => onShowCreateRootProject()} disabled={!isRootContributor}>
          <Plus className={iconWhiteStyle(!isRootContributor)} />
          {intl.formatMessage({ id: 'createFolder.title'})}
        </MikeButton>
      </div>
    </div>
    <AllProjectsTable     
      data={projects}
      loading={loading}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      onItemClick={handleSelect}
      onHandleRequestSort={handleRequestSort}
      order={sortOrder}
      orderBy={sortBy}
      page={page}
      rowsPerPage={rowsPerPage}
      topOffset={topOffset}
      selectable={false}
      totalCount={totalCount}
      pagination={totalCount > 10}
      notSortableColumns={["description"]}
      onSelectionChange={onSelectionChange}
      selectedItems={selectedRows}
      onDeleteOneProject={onDeleteOneProject}
      onEdit={onEditProject}
    />

      <MikeDialog 
        open={deleteConfirmationOpen} 
        onCancel={onCancelDeleteProjects} 
        onOk={onOkDeleteProjects}
        dialogTitle={intl.formatMessage({id: 'warnings.pleaseConfirm'})}
        contentTitle={intl.formatMessage({id: projectsToBeDeleted.length === 1 ? 'projects.deleteOneConfirmation' : 'projects.deleteManyConfirmation'})}
        message={''}    
        okButtonLabel={intl.formatMessage({id: 'projects.delete'})}
      />

    <MikeSlidingPanel
      position="right"
      isOpen={createRootProjectPanelIsOpen}
      onClose={() => onShowCreateRootProject(false)}
      titleArea={intl.formatMessage({ id: projectToBeEdited ? 'editFolder.title' : 'createFolder.title'})}
      contentArea={<CreateFolderForm project={projectToBeEdited} />}
      actionsArea={null}
      noGrayOverlay={false}
    />
  </>
  )
}

export default RootProjects