import ActionType from "../actions/ActionType";
import { IAction } from "../actions/Action";

export interface ICheckState 
{
  meshAndPointsCompleted: boolean; 
}

const initState = 
{ 
  meshAndPointsCompleted: false 
}

const check = (state = initState, action: IAction<string>) => {
  switch (action.type) {
      case ActionType.CHECK_MESH_AND_POINTS_SET:
          return { ...state, meshAndPointsCompleted: action.data };
      default:
          return state;
  }
};

export default check