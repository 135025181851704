import  ActionType  from "./ActionType";

export const publishDataToMOOD = () => ({
  type: ActionType.PUBLISH_DATA_TO_MOOD
})

export const setPublishingResultsToMOOD = (publishing = true) => ({
  type: ActionType.PUBLISHING_DATA_TO_MOOD,
  data: publishing
})

