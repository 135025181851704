import { IProgressItem } from '@mike/mike-shared-frontend/mike-topbar-progress-spinner';
import ActionType from "./ActionType";
import { IAction } from "./Action"

export const removeProgressItem = (progressItem: IProgressItem): IAction<IProgressItem> => ({
  type: ActionType.REMOVE_PROGRESS_ITEM,
  data: progressItem
})

export const updateOrAddProgressItem = (progressItem: IProgressItem): IAction<IProgressItem> => ({
  type: ActionType.UPDATE_OR_ADD_PROGRESS_ITEM,
  data: progressItem
})

/* export const progressItemComplete = (progressItem: IProgressItem): IAction<IProgressItem> => ({
  type: ActionType.PROGRESS_ITEM_COMPLETE,
  data: progressItem
})

export const progressItemFailed = (progressItem: IProgressItem): IAction<IProgressItem> => ({
  type: ActionType.PROGRESS_ITEM_FAILED,
  data: progressItem
}) */
