import React, { ReactNode } from 'react';
import { Grid, Grow, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { MuiThemeProvider } from '@material-ui/core/styles';
import MikeNavWarnBanner from '@mike/mike-shared-frontend/mike-nav-warn-banner/MikeNavWarnBanner';
import mikeTheme from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme';
import { ReactComponent as Logo } from '@mike/mike-shared-frontend/media/icons/LandingPage';
import { ReactComponent as MeshGenerator } from '@mike/mike-shared-frontend/media/icons/MeshGenerator';
import { useIntl } from 'react-intl'
import './landingpage.css';

const splash = require('./splashscreen.jpg');

const useStyles = makeStyles((_theme) =>
  createStyles({
    banner: {
      position: 'absolute',
      top: 0,
      zIndex: 11,
      width: '100%',
    },
    wrapper: {
      padding: '50px 40px 25px 40px',
    },
    header: {
      display: 'inline-block',
      verticalAlign: 'top',
      margin: '0 0 35px 15px',
    },
  }),
);

type Props = {
  children?: ReactNode;
};

const LandingLayout = ({ children }: Props) => {
  const intl = useIntl();
  const classes = useStyles();

  const background = {
    backgroundImage: `url(${splash})`,
  };

  return (
    <MuiThemeProvider theme={mikeTheme}>
      <div className={classes.banner}>
        <MikeNavWarnBanner showAlways={false} />
      </div>
      <div className="LandingPage" style={background}>
        <div className="LandingPage-logo">
          <Logo />
        </div>

        <div className="LandingPage-content">
          <Grow in={true}>
            <div className={classes.wrapper}>
              <Grid container justifyContent="center" alignItems="center" spacing={0}>
                <Grid item={true} xs={12}>
                  <MeshGenerator />
                  <Typography color="primary" gutterBottom={true} variant="h1" className={classes.header}>
                    {intl.formatMessage({id: 'app.newRegisteredName'})}
                  </Typography>
                </Grid>

                {children}
              </Grid>
            </div>
          </Grow>
        </div>
      </div>
    </MuiThemeProvider>
  );
};

export default LandingLayout;
