import uniqid from 'uniqid'
import ActionType from "./ActionType";

export const exportAndDownloadDataset = (
  fileName: string,
  datasetId: string, 
  data: object,
  datasetType: string,
  download = false, 
  setPreviousExtraction = false
) => ({
  type: ActionType.EXPORT_AND_DOWNLOAD_DATASET,
  data: {id: uniqid.time(),
  fileName: fileName,
  datasetId: datasetId, 
  data: data,
  datasetType: datasetType,
  download: download,
  setPreviousExtraction
  }
})
