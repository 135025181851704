import React, { ReactNode } from 'react';
import { IMikeTheme } from '../../shared/mikeSharedTheme';
import { makeStyles, createStyles } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import classNames from 'classnames';
import { getPadding, IColumn } from './support';
import { IAction } from './Actions';
import ContextHelp from '../ContextHelp';

const useStyles = makeStyles((theme: IMikeTheme) => {
  return createStyles({
    tableHeader: {
      zIndex: 1,
    },
    noBorderBottom: {
      borderBottom: 'none',
    },
    actionButton: {
      width: 40,
      paddingRight: theme.spacing(4),
    },
    stickyHeader: (props: IProps) => ({
      position: 'sticky',
      top: props.topOffset,
      zIndex: 1,
      whiteSpace: 'nowrap',
    }),
    stickyActionHeader: (props: IProps) => ({
      position: 'sticky',
      top: props.topOffset,
      width: 40,
      zIndex: 1,
    }),
  });
});

interface IProps {
  actions?: Array<IAction>;
  columns: Array<IColumn>;
  firstHeaderCell?: ReactNode;
  noBottomBorder?: boolean;
  notSortableColumns?: Array<any>;
  onRequestSort: (
    orderBy: string | ((item: any) => string | number),
    order: 'asc' | 'desc'
  ) => void;
  sortDirection: 'asc' | 'desc' | undefined;
  sortBy: string | number | ((item?: any) => string | number);
  topOffset: number;
  selectable?: boolean;
}

const EnhancedTableHead = (props: IProps) => {
  const classes = useStyles(props);
  const {
    actions,
    columns,
    firstHeaderCell,
    noBottomBorder = false,
    notSortableColumns = [],
    onRequestSort,
    sortDirection,
    sortBy,
    selectable
  } = props;

  const createSortHandler = (col: IColumn) => (_event: any) => {
    onRequestSort(
      col.comparator ? col.comparator : col.field,
      sortDirection === 'desc' ? 'asc' : 'desc'
    );
  };

  return (
    <TableHead component="thead" className={classes.tableHeader}>
      <TableRow className={classes.tableHeader}>
        {selectable && 
          <TableCell
            component="th"
            padding="checkbox"
            className={classNames(classes.stickyHeader, noBottomBorder && classes.noBorderBottom)}
          >
            {firstHeaderCell && firstHeaderCell}
          </TableCell>
        }
        {columns.map((col: IColumn) => {
          return (
            <TableCell
              className={classNames(classes.stickyHeader, noBottomBorder && classes.noBorderBottom)}
              key={col.field}
              align={col.numeric ? 'right' : 'left'}
              padding={getPadding(col)}
              component="th"
              sortDirection={sortDirection}
            >
              {!notSortableColumns.includes(col.field) ? (
                <TableSortLabel
                  active={col.comparator ? sortBy === col.comparator : sortBy === col.field}
                  direction={sortDirection}
                  onClick={createSortHandler(col)}
                >
                  {col.label}
                </TableSortLabel>
              ) : (
                col.label
              )}
              {col.info && <ContextHelp primary helpTexts={col.info}/>}
            </TableCell>
          );
        })}
        {actions ? (
          <TableCell
            className={classNames(
              classes.actionButton,
              classes.stickyActionHeader,
              noBottomBorder && classes.noBorderBottom
            )}
            align="center"
          />
        ) : null}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
