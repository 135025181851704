import React from 'react';
import { ReactComponent as BathymetryBuilder } from '@mike/mike-shared-frontend/media/icons/BathymetryBuilder';
import { ReactComponent as DataAdmin } from '@mike/mike-shared-frontend/media/icons/DataAdmin';
import { ReactComponent as DataLink } from '@mike/mike-shared-frontend/media/icons/DataLink';
import { ReactComponent as FloodScreener } from '@mike/mike-shared-frontend/media/icons/FloodScreener';
import { ReactComponent as GenericApp } from '@mike/mike-shared-frontend/media/icons/GenericApp';
import { ReactComponent as MeshGenerator } from '@mike/mike-shared-frontend/media/icons/MeshGenerator';
import { ReactComponent as AdminCenter } from '@mike/mike-shared-frontend/media/icons/AdminCenter';
import { ReactComponent as FastWaveEmulator } from '@mike/mike-shared-frontend/media/icons/FastWaveEmulator';

export const getAppIcon = (appId: string) => {
  let appIcon;
  switch (appId) {
    case 'dataAdmin':
    case 'Cloud Admin':
      appIcon = <DataAdmin />;
      break;
    case 'adminCenter':
      appIcon = <AdminCenter />;
      break;
    case 'dataLink':
    case 'Data Link':
      appIcon = <DataLink />;
      break;
    case 'floodScreener':
    case 'Flood Screener':
      appIcon = <FloodScreener />;
      break;
    case 'meshBuilder':
    case 'Mesh Builder':
      appIcon = <MeshGenerator />;
      break;      
    case 'bathymetryBuilder':
    case 'Bathymetry Builder':
      appIcon = <BathymetryBuilder />;
      break;
    case 'MIKE Fast Wave Emulator':
    case 'Fast Wave Emulator':
    case 'MetOcean Simulator':
    case 'Metocean Simulator':
      appIcon = <FastWaveEmulator/>;
      break;
    default:
      appIcon = <GenericApp />;
      break;
  }
  return appIcon;
};
/* 
export default AppIcon */
