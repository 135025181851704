import React from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center'
    },
    icon: {
      paddingTop: theme.spacing(0.5),
      marginRight: theme.spacing(1)
    },
    longText: {
      maxWidth: '25vw',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'table-cell',
      paddingRight: 8
    }
  })
)

interface IProps {
  text: string
  icon?: React.ReactNode
}

const IconAndLongText = (props: IProps) => {
  const { text, icon } = props
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {icon && (
        <div className={classes.icon}>
          {icon}
        </div>
      )}
      <Tooltip title={text}>
        <div className={classes.longText}>{text}</div>
      </Tooltip>
    </div>
  )
}

export default React.memo(IconAndLongText)