import ActionType from "./ActionType";
import { IGetProject } from '../model/IGetProject';
import { ICreateProject } from "../model/ICreateProject";

export const createRootProject = (data: ICreateProject) =>({
  type: ActionType.CREATE_ROOTPROJECT,
  data
})

export const updateProject = (data: IGetProject) =>({
  type: ActionType.UPDATE_ROOTPROJECT,
  data
})

export const setUpdatingProject = (updating = true) => ({
  type: ActionType.UPDATING_PROJECT,
  data: updating
})

export const setCreatingRootProject = (creating = true) => ({
  type: ActionType.CREATING_ROOTPROJECT,
  data: creating
})

export const setCreateRootProjectPanelOpen = (open = true) => ({
  type: ActionType.SET_ROOTPROJECT_PANEL_OPEN,
  data: open
})

export const getProjects = (offset: number, rowsPerPage: number, sortBy: string, sortOrder: string, namePrefix: string) => ({
  type: ActionType.GET_PROJECTS,
  data: {
    offset: offset,    
    rowsPerPage: rowsPerPage, 
    sortBy: sortBy, 
    sortOrder: sortOrder, 
    namePrefix: namePrefix
  }
})

export const setLoadingProjects = (loading = true) => ({
  type: ActionType.LOADING_PROJECTS,
  data: loading
})

export const setProjects = (projects: IGetProject[], totalCount: number, limit: number, offset: number) => ({
  type: ActionType.SET_PROJECTS,
  data: {
    projects: projects,
    totalCount: totalCount,
    limit: limit,
    offset: offset
  }
})

export const setProjectsPagination = (page: number) => ({
  type: ActionType.SET_PROJECTS_PAGINATION,
  data: page
})

export const setProjectsRowsPerPage = (rowsPerPage: number) => ({
  type: ActionType.SET_PROJECTS_ROWSPERPAGE,
  data: rowsPerPage
})

export const setProjectsSort = (sortBy: string, sortOrder: 'asc' |'desc') => ({
  type: ActionType.SET_PROJECTS_SORT,
  data: {sortBy: sortBy, sortOrder: sortOrder}
})

export const setProjectsNamePrefix = (namePrefix: string) => ({
  type: ActionType.SET_PROJECTS_NAMEPREFIX,
  data: namePrefix
})

export const setDeletingRootProjects = (deleting = true) => ({
  type: ActionType.DELETING_ROOTPROJECTS,
  data: deleting
})

export const deleteRootProjects = (ids: Array<string>) =>({
  type: ActionType.DELETE_ROOTPROJECTS,
  data: ids
})