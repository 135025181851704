import React, { ChangeEvent } from 'react'

import { Input, InputAdornment } from '@material-ui/core';

import { ReactComponent as Close } from '@mike/mike-shared-frontend/media/icons/Close';
import { ReactComponent as Search } from '@mike/mike-shared-frontend/media/icons/Search';

import { makeStyles, createStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme =>
  createStyles({
    searchField: {
      padding: theme.spacing(1 / 4, 2),
      width: 320,
      height: 40,
      backgroundColor: theme.palette.background.paper,
      borderRadius: '25px',
      border: '1px solid ' + theme.palette.divider
    }
  })
)

interface IProps {
  value: string;
  onChangeValue: (event: ChangeEvent<HTMLInputElement>) => void;
  onResetValue: () => void;
}

const SearchInput = (props: IProps) => {
  const classes = useStyles() 
  
    const { value, onChangeValue, onResetValue } = props;
    return (
      <Input
        type="input"
        autoFocus
        onChange={onChangeValue}
        className={classes.searchField}
        disableUnderline={true}
        placeholder={"What are you searching for"}
        value={value}
        startAdornment={
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        }
        endAdornment={
          value !== '' ? (
            <InputAdornment position="end" onClick={onResetValue}>
              <Close />
            </InputAdornment>
          ) : null
        }
      />
    );
  };

export default SearchInput
