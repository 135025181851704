import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, createStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography';
import { IMikeTheme } from '../../shared/mikeSharedTheme'
import { Button } from '@material-ui/core';
import { fastWaveConfigSet, setDrawing, uploadBathymetry, uploadOutlineOrAOIOrShoreline } from '../../actions/mapContent' 
import { IState } from '../../reducers';
import { deleteOutputFolder, getProjectContent, setFilter, setProjectContentDialogOpen } from '../../actions/projectContent'
import { addMessage } from '../../actions/message'
import { IGetProject } from '../../model/IGetProject'
import {  BATHYMETRY, FLIPLAND, MESH, OUTLINE, SHORELINE } from '../../shared/constants';
import UploadButton from '../UploadButton/UploadButton';
import { css } from 'emotion'
import theme from '../../shared/mikeSharedTheme'
import { IFastWaveConfig } from '../../model/IFastWaveConfig';
import { titleContainer, titleStyle, firstOutlineTitleContainer } from '../../shared/styles';
import { ReactComponent as Duplicate } from "@mike/mike-shared-frontend/media/icons/Duplicate"
import { AOI, GEBCO, ICreateMeshDataset, ICreateMeshParameter } from '../../reducers/createMesh';
import MikeButton from '../mike-button';
import { createAutoMesh, interpolateMesh, updateParameters } from '../../actions/createMesh';
import BathymetryTable from '../BathymetryTable';
import { useIntl } from 'react-intl';
import { ELEVATION, POINT_Z } from '../Viewer';
import  { IMeshInputEntity } from '../../model/CreateMeshScenarioOptions';
import { iconSecondaryStyle } from '../EditPointsForm/iconStyles';
import { IWarning, WARNINGTYPE } from '../InitialSelection';
import MikeDialog from '../DialogComponents/MikeDialog';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MeshInputTable, { getDatasetType } from '../MeshInputTable';

const subTitleStyle = css`
  padding-left: ${theme.spacing(2)}px;
  padding-right: ${theme.spacing(2)}px;
  color: ${theme.palette.primary.light};
`
const createMeshContainerStyle = css`  
  display: flex;
  justify-content: flex-end;  
`
const createMeshButtonStyle = css`  
  padding: ${theme.spacing(2)}px;
`
const ParameterLabelCss = css`
  display: inline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  padding-left: ${theme.spacing(1)}px; 
`;

const styles = makeStyles((theme: IMikeTheme) => { 
  return createStyles({    
    switchLabel: {      
      color: theme.palette.primary.main,
      '& .MuiTypography-root, .MuiTypography-root.Mui-disabled, .MuiFormControlLabel-root': {
        color: theme.palette.primary.main,
        fontSize: 14
      }
    },
    exportTitle: {
      padding: theme.spacing(3) + 'px '+ theme.spacing(2) + 'px',
      borderTop: '2px solid',
      borderColor: theme.palette.secondary.light
    },
    geometryButtonsContainer: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      paddingRight: theme.spacing(2)     
    },
    geometryButton: {
      paddingRight: theme.spacing(1),
      whiteSpace: 'nowrap'
    },
    drawingActive: {
      paddingRight: theme.spacing(1),
      backgroundColor: theme.palette.secondary.light
    },
    uploadIcon: {
      marginRight: theme.spacing(1)
    },
    pointsContainer: {
      backgroundColor: theme.palette.mediumGrey && theme.palette.mediumGrey.main
    },
    drawStyle: {
      marginLeft: '-10px',
      '& path': {
        fill: '#fff'
      }
    },   
    spinnerContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(2)
    }
  })
})

const AutoMesh = () => {   
  const classes = styles()
  const dispatch = useDispatch();  
  const intl = useIntl();
  const showCopyXYZFromPlatform = false

  const project: IGetProject | null = useSelector(
    (state: IState) => state.projectContent.project
  );

  const {meshInputEntities, loadingShoreline, shorelines, checkingMeshIsExported, interpolatingMesh, 
    canInterpolateMesh, creatingMesh, loadingOutline, loadingAreasOfInterest,
     meshOutline, meshAreasOfInterest, bathymetryDatasets, createMeshPayload } = useSelector(
    (state: IState) => state.createMesh
  ); 

  const fastWaveConfig: IFastWaveConfig = useSelector(
    (state: IState) => state.mapContent.fastWaveConfig
  );  

  const invertMesh = useMemo(() => {
    const params: Array<ICreateMeshParameter>  = createMeshPayload && createMeshPayload.parameterDescriptions ? createMeshPayload.parameterDescriptions.filter((p: ICreateMeshParameter) => p.entityType.toLowerCase() === MESH.toLowerCase()) : []   
    const invertMeshParam = params.find((p: ICreateMeshParameter) => p.valueType.toLowerCase() === "boolean" && p.name.toLowerCase() === FLIPLAND)
    return invertMeshParam !== undefined ? invertMeshParam : null
  }, [createMeshPayload])

  const supportedEntities = useMemo(() => {
    return meshInputEntities.map((entity: IMeshInputEntity) => entity.type.toLowerCase())
  }, [meshInputEntities])

  const outlineDefined = useMemo(() => {return meshOutline !== null}, [meshOutline])
  const areaOfInterestDefined = useMemo(() => {return meshAreasOfInterest && meshAreasOfInterest.length > 0}, [meshAreasOfInterest])
  const shorelineDefined = useMemo(() => {return shorelines && shorelines.length > 0}, [shorelines])

  const disableCreateMeshButton = useMemo(() => {
    const busy = loadingOutline || loadingAreasOfInterest || loadingShoreline || creatingMesh
    if (busy){
      return true
    }
    if (supportedEntities.length === 0){
      return true
    }
    if (supportedEntities.includes(OUTLINE) && !outlineDefined){
      return true
    }
    if (supportedEntities.includes(SHORELINE) && !shorelineDefined){
      return true
    }
    if (supportedEntities.includes(AOI) && !areaOfInterestDefined){
      return true
    }

    return false
  }, [supportedEntities, creatingMesh, outlineDefined, areaOfInterestDefined, shorelineDefined, loadingOutline, loadingAreasOfInterest, loadingShoreline])

  const canUpdateContent = useMemo(() => {
    return project && project.capabilities && project.capabilities.canUpdateContent
  }, [project]) 

  const dataFromDataLinkAlreadyExtracted = useMemo(() => {
    return fastWaveConfig && fastWaveConfig.data_link_output_file && fastWaveConfig.data_link_output_file.dataset_id
  }, [fastWaveConfig]) 

  const [ fileTemp, setFileTemp ] = useState<any>();
  const [warning, setWarning] = React.useState<IWarning | null>(null);

  const handleCreateMesh = useCallback(() => {   
    dispatch(createAutoMesh()) 
  }, [dispatch])

  const handleInterpolateMesh = useCallback(() => {   
    dispatch(interpolateMesh(bathymetryDatasets.map((b: ICreateMeshDataset) => {return {variableId: b.id, propertyName: b.name.startsWith(GEBCO) ?  ELEVATION : POINT_Z}}))) 
  }, [dispatch, bathymetryDatasets])

  const openPlatformExplorer = useCallback((filter: string) => {   
    if (project){
      const canListContent = project.capabilities && project.capabilities.canListContent
      if (canListContent){ 
        dispatch(setFilter(filter))
        dispatch(getProjectContent(project, filter))
        dispatch(setProjectContentDialogOpen(true, true))
      }
      else{
        dispatch(addMessage(intl.formatMessage({id: 'project.noReadAccess'}) +  " " + project.id + ". " + intl.formatMessage({id: 'project.contactOwner'})));
      } 
    }    
  }, [dispatch, intl, project])


  const handleImportGeometryFromPlatform = useCallback((filter: string) => {     
    if (dataFromDataLinkAlreadyExtracted){
      setWarning({warning: intl.formatMessage({id: 'warnings.outputAndConfigDeleted'}), type: WARNINGTYPE.COPYMESHINPUT, filter})       
    }
    else {
      openPlatformExplorer(filter)
    } 
  }, [intl, openPlatformExplorer, dataFromDataLinkAlreadyExtracted])

      
  const onDropBathymetry = useCallback(
  (files) => {
    if (files.length > 0){       
      const file = files[0]   
      dispatch(uploadBathymetry(file, file.name)) 
    }
  }, [dispatch]) 


  const interpolationIsRunning = useMemo(() => {
    return interpolatingMesh || checkingMeshIsExported
  }, [interpolatingMesh, checkingMeshIsExported])
 
  const reset = useCallback(() => { 
    const copyConfig = {...fastWaveConfig}
    delete copyConfig.data_link_output_file
    dispatch(fastWaveConfigSet(copyConfig));
    dispatch(deleteOutputFolder());    
  }, [dispatch, fastWaveConfig])

  const handleWarningConfirmed = useCallback(() => {
    setWarning(null)
    const warningType = warning && warning.type ? warning.type : ''
    switch (warningType){
      case WARNINGTYPE.UPLOADMESHINPUT:
        reset();
        dispatch(uploadOutlineOrAOIOrShoreline(fileTemp, fileTemp.name, warning.filter));  
        setFileTemp(null);
        break;
      case WARNINGTYPE.COPYMESHINPUT:  
        reset();    
        openPlatformExplorer(warning.filter);
        break;
      case WARNINGTYPE.DRAWMESHINPUT:
        reset(); 
        dispatch(setDrawing(warning.filter))  
        break;
      default:
        break;
    }
    
  }, [warning, reset, dispatch, fileTemp, openPlatformExplorer])

  const handleOnCancel = (_event?, reason?) => {
    if(!reason || (reason !== 'backdropClick' && reason !== 'escapeKeyDown')) {
      setWarning(null);
      setFileTemp(null);
    }
  }

  const handleChangeInvertMesh = useCallback((event) => {
    dispatch(updateParameters({[event.target.name]: event.target.checked}, MESH))
  }, [dispatch])

  const onUploadFromComputer = (files, entityType: string) => {
    const datasetType = getDatasetType(entityType);    
    if (files.length > 0){       
      const file = files[0]   
      if (dataFromDataLinkAlreadyExtracted){
        setFileTemp(file)
        setWarning({warning: intl.formatMessage({id: 'warnings.outputAndConfigDeleted'}), type: WARNINGTYPE.UPLOADMESHINPUT, filter: datasetType})       
      }
      else{           
        dispatch(uploadOutlineOrAOIOrShoreline(file, file.name, datasetType));             
      }
    }
  };

  const onUploadFromCLoud  = useCallback((entityType: string) => {
    const datasetType = getDatasetType(entityType)
    if (dataFromDataLinkAlreadyExtracted){
      setWarning({warning: intl.formatMessage({id: 'warnings.outputAndConfigDeleted'}), type: WARNINGTYPE.COPYMESHINPUT, filter: datasetType})       
    }
    else {
      openPlatformExplorer(datasetType)
    } 
  }, [dataFromDataLinkAlreadyExtracted, intl, openPlatformExplorer]);

  const onDraw  = useCallback((entityType: string) => {
    const datasetType = getDatasetType(entityType)
    if (dataFromDataLinkAlreadyExtracted){  
      setWarning({warning: intl.formatMessage({id: 'warnings.outputAndConfigDeleted'}), type: WARNINGTYPE.DRAWMESHINPUT, filter: datasetType})       
    }
    else{
      dispatch(setDrawing(datasetType)) 
    } 
  }, [dataFromDataLinkAlreadyExtracted, dispatch, intl]);

  return (
    <>
      <MikeDialog 
        open={warning && warning.warning ? true : false} 
        onCancel={handleOnCancel} 
        onOk={handleWarningConfirmed}
        dialogTitle={intl.formatMessage({id: 'warnings.pleaseConfirm'})}
        contentTitle={intl.formatMessage({id: 'warnings.confirmDialogContentTitle'})}
        message={warning && warning.warning ? warning.warning : ''}    
        okButtonLabel={intl.formatMessage({id: 'warnings.change'})}
      />
   
      <div >     
        <div className={titleContainer}>
          <div>
            <Typography variant="h5" className={titleStyle}>{intl.formatMessage({id: 'autoMesh.mesh'})}</Typography>
            <Typography variant="body2" className={subTitleStyle}>{intl.formatMessage({id: 'autoMesh.outlineAndAOI'})}</Typography>
          </div>
        </div>
        <div className={firstOutlineTitleContainer}>
          <MeshInputTable 
            canUpdateContent={canUpdateContent}
            onUploadFromComputer={onUploadFromComputer}
            onUploadFromCLoud={onUploadFromCLoud}
            onDraw={onDraw}
          />
        </div>
      
        <div className={titleContainer}>
          <div>
            <Typography variant="h5" className={titleStyle}>{intl.formatMessage({id: 'autoMesh.bathymetry'})}</Typography>
            <Typography variant="body2" className={subTitleStyle}>{intl.formatMessage({id: 'autoMesh.loadGebcoOrOwn'})}</Typography>
          </div> 
        </div>
       
        <div>
          <BathymetryTable 
            data={bathymetryDatasets}           
            canUpdateContent={canUpdateContent}
          />
          <div className={createMeshContainerStyle}>
            {showCopyXYZFromPlatform && 
              <Button disabled={!canUpdateContent} variant="text" onClick={() => {handleImportGeometryFromPlatform(BATHYMETRY)}} className={classes.geometryButton}>
                <Duplicate className={iconSecondaryStyle(!canUpdateContent)} width={24} height={24} viewBox={"0 0 40 40"}/>
                {intl.formatMessage({id: 'platform.copy'})}                 
              </Button> 
            }  
            <UploadButton 
              info={[intl.formatMessage({id: 'autoMesh.xyz_hint'})]}
              disabled={!canUpdateContent} 
              fileType={['.asc', '.vti', '.vtu', '.xyz']} 
              label={intl.formatMessage({id: 'autoMesh.addBathymetry'})} 
              onDrop={onDropBathymetry}/> 
          </div>        
        </div>
         
        <div className={createMeshContainerStyle}>
        
          {invertMesh && 
          <FormControlLabel
            control={
              <Switch
                checked={invertMesh.value === true ? true : false}
                onChange={handleChangeInvertMesh}
                name={invertMesh.name}
                inputProps={{ 'aria-label': invertMesh.name }}                
                />
              }
              label={invertMesh.displayName} 
              title={invertMesh.displayName}
              checked={invertMesh.value === true ? true : false}
              className={classes.switchLabel}
              classes={{ label: ParameterLabelCss }}
            />
          }
         
          <div className={createMeshButtonStyle}>
            <MikeButton disabled={disableCreateMeshButton} buttontype="secondary" active={creatingMesh} onClick={handleCreateMesh}>Create Mesh</MikeButton>
            {creatingMesh ? <Typography variant="body2">{intl.formatMessage({id: 'autoMesh.creatingMesh'}) + "..."}</Typography> : <div/>}
          </div>
          
          <div className={createMeshButtonStyle}>
            <MikeButton disabled={!canInterpolateMesh} active={interpolationIsRunning} onClick={handleInterpolateMesh} buttontype="secondary">Interpolate</MikeButton>
            {interpolationIsRunning ? <Typography variant="body2">{intl.formatMessage({id: 'autoMesh.interpolating'}) + "..."}</Typography> : <div/>}
          </div>
        </div>
      </div>
    </>
  )
}

export default AutoMesh