import React, { useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import qs from 'qs';
import { useOidc } from '@axa-fr/react-oidc';
import { MikeButton } from '@mike/mike-shared-frontend';
import LandingLayout from './LandingLayout';
import { useLocation } from 'react-router-dom';
import { LOGGINGOUT } from './MikeLogin';
import { defineMessages } from 'react-intl';
import { intl } from '../../..';

const useStyles = makeStyles((_theme) =>
  createStyles({
    notes: {
      marginTop: 20,
      display: 'inline-block',
    },
  }),
);

const messages = defineMessages({ 
  landingPageButtonText: { id: 'landingPage.buttonText'},
  landingPageHeader: { id: 'landingPage.header'},
  landingPageLinkLabel: { id: 'landingPage.linkLabel'},
  landingPageLinkText: { id: 'landingPage.linkText'}
})

const LandingPage = () => {
  const classes = useStyles();
  const { login } = useOidc();

  const { search, pathname } = useLocation();

  const pathAndSearch = search.length > 1 ? pathname + search : pathname;

  useEffect(() => {
    const loggingOut = sessionStorage.getItem(LOGGINGOUT);
    sessionStorage.removeItem(LOGGINGOUT);
    if (loggingOut !== 'true') {
      let extras: { prompt: string; tenantId?: string } = { prompt: 'none' };
      if (search) {
        const parameters = qs.parse(search.replace('?', '')) || {};
        if (parameters.tenantId) {
          extras = { ...extras, tenantId: parameters.tenantId.toString() };
        }
      }
      login(pathAndSearch, extras);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LandingLayout>
      <>
        <Grid item container justifyContent="center" xs={12}>
          <MikeButton color="secondary" variant="contained" onClick={() => login(pathAndSearch)}>
            {intl.formatMessage(messages.landingPageButtonText)}
          </MikeButton>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="caption" className={classes.notes}>
            <label>{intl.formatMessage(messages.landingPageLinkLabel) + ' '}</label>
            &nbsp;
            <a href="mailto:mike@dhigroup.com">{intl.formatMessage(messages.landingPageLinkText)}</a>.
          </Typography>
        </Grid>
      </>
    </LandingLayout>
  );
};

export default LandingPage;
