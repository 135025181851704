import { ENVIRONMENTALDATA, EXTRACTION_END_COLUMN, EXTRACTION_MODEL_TYPE_COLUMN, EXTRACTION_START_COLUMN } from "../shared/constants";
import { customDateTimeFormat } from "./fixTime";

const getLine = (lines: Array<string>, name: string) => {
  return lines.find((line: string) => line.startsWith(name))
}

const getDate = (line: string) => {
  const columns = line.split(":")
  if (columns.length > 2){
    const dateTime = columns[1].trim()
    const dateTimeParts = dateTime.split(" ")
    if (dateTimeParts.length > 0){
      const date = new Date(dateTimeParts[0] + "T00:00:00")
      return customDateTimeFormat(date)
    }
  }
  return null
}

const getOwnDataDate = (line: string) => {
  const columns = line.split(":")
  if (columns.length > 1){
    const d = line.replace(columns[0] + ":", "")
    const da = d.replace("UTC", "")
    const dat = da.trim()
    const date = new Date(dat.replace(" ", "T"))
    return customDateTimeFormat(date)
  }
}

export const isOwnUserData = (info: string) => {
  const lines = info.split("\n");   
  const modelTypeLine = getLine(lines, EXTRACTION_MODEL_TYPE_COLUMN)
  const columns = modelTypeLine.split(":")
  if (columns.length > 1){
    const modelType = columns[1].trim()   
    return modelType && modelType.toLowerCase().startsWith(ENVIRONMENTALDATA)
  }
  return false
}

export const extractionPeriod = (info: string) => {
  try{
    const lines = info.split("\n");  
    const isOwnData = isOwnUserData(info)
    const startLine = getLine(lines, EXTRACTION_START_COLUMN)
    const endLine = getLine(lines, EXTRACTION_END_COLUMN)
    if (startLine && endLine){
      const startDate = isOwnData ? getOwnDataDate(startLine) : getDate(startLine)
      const endDate = isOwnData ? getOwnDataDate(endLine) : getDate(endLine)
      if (startDate && endDate){
        return [startDate, endDate]
      }
    }
  }
  catch{
    console.log("Failed to get extraction period from Data Link's info file")
    return null;
  }  
}