import React from 'react';

import AddIcon from '@material-ui/icons/Add';
import Tooltip from '@material-ui/core/Tooltip';

import { ViewerToolsButtonStyle } from '../ViewerStyles';
import MikeVisualizer from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/MikeVisualizer';

const { zoomIn } = MikeVisualizer;

/**
 * @name ViewerToolZoomIn
 * @summary Allows zooming in.
 */
export const ViewerToolZoomIn = () => {
  return (
    <Tooltip title={'Zoom in'} placement="right">
      <button className={ViewerToolsButtonStyle} onClick={zoomIn}>
        <AddIcon />
      </button>
    </Tooltip>
  );
};

export default ViewerToolZoomIn