import { takeEvery, put, call, select } from 'redux-saga/effects'
import { getUser } from '../reducers/state'
import ActionType from '../actions/ActionType'
import { getCapabilities } from '../apis/metadataApi'
import { setIsRootContributor } from '../actions/auth'
import { getWorkspaceScenarios } from '../actions/createMesh'
import { loadTenants } from '../actions/iam'
import { getApps } from '../actions/applications'
import { coordinateSystemsGet } from '../actions/mapContent'

export default function* watchAuth() { 
  yield takeEvery(ActionType.SET_IS_AUTHENTICATED, handleIsAuthenticated)
}

function* handleIsAuthenticated(action) {
  const isAuthenticated = action.data
  const user = yield select(getUser)
  if (isAuthenticated && user) {
    
    try {
      const capabilities = yield call(getCapabilities, user.tenantId)
 
      if (capabilities?.canCreateContent === true) {
        yield put(setIsRootContributor(true))
      } else {
        yield put(setIsRootContributor(false))
      }
    } catch (error) {     
      yield put(setIsRootContributor(false))
    }
  }
  if (isAuthenticated && user){
    yield put(getWorkspaceScenarios())   
    yield put(loadTenants());
    yield put(getApps())  
    yield put(coordinateSystemsGet())  
  }
}