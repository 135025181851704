import ITenant from '../model/ITenant';
import ActionType from './ActionType';

export const loadTenants = () => ({
  type: ActionType.GET_MY_TENANTS,
});

export const loadingTenants = (loading = true) => ({
  type: ActionType.LOADING_MY_TENANTS,
  data: loading,
});

export const setMyTenants = (tenants: Array<ITenant>) => ({
  type: ActionType.SET_MY_TENANTS,
  data: tenants,
});
