import { mikePalette } from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme'
import { css } from 'emotion'

export const iconStyle = (disabled = false) => {
  return css`
    path {
      fill: ${disabled ? mikePalette.primary.light : mikePalette.primary.main};
    }
  `
}

export const iconActionStyle = (disabled = false) => {
  return css`
    path {
      fill: ${disabled
        ? mikePalette.secondary.light
        : mikePalette.secondary.main};
    }
  `
}

export const actionStyle = (disabled = false) => {
  return css`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    color: ${disabled
      ? mikePalette.primary.light
      : mikePalette.primary.main};
    background-color: ${mikePalette.mediumGrey.light};
    cursor: ${disabled ? 'no-drop' : 'pointer'};
    padding-bottom: 0px;
    padding-top: 0px;
    padding-right: 4px;
  `
}

export const tableRowButtonStyle = (disabled = false) => {
  return css`
    width: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: ${disabled
      ? mikePalette.primary.light
      : mikePalette.primary.main};
    background-color: transparent;
    cursor: ${disabled ? 'no-drop' : 'pointer'};
    padding-bottom: 0px;
    padding-top: 0px;
    padding-right: 4px;
  `
}

export const tableCellButtonStyle = () => {
  return css`  
    &.MuiTableCell-body {     
      padding-right: 4px;
    }
  `
}
