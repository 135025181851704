import React, {useCallback, useMemo } from "react";

import theme, { FONTFAMILY } from '../../shared/mikeSharedTheme'
import Plot from 'react-plotly.js';
import { IChartBullet } from "../../model/IChartData";

const XRANGE0 = "xaxis.range[0]"
const XRANGE1 = "xaxis.range[1]"
const YRANGE0 = "yaxis.range[0]"
const YRANGE1 = "yaxis.range[1]"

export interface IAxisRange {
"xaxis.range[0]": any;
"xaxis.range[1]": any;
"yaxis.range[0]": any;
"yaxis.range[1]": any;  
}

interface IProps{
  axisLabels: Array<string>,
  data: Array<IChartBullet>,
  selectForTestRun?: boolean;
  switchSelection: (id: string) => void;
  isTimeSeries: boolean;
  chartAxisRange: IAxisRange;
  onChangeAxisRange: (axisRange) => void;
}
function Chart(props: IProps) {
  const { axisLabels, data, selectForTestRun, switchSelection, isTimeSeries, onChangeAxisRange,chartAxisRange } = props

  const chartData = useMemo(() => {
   if (data){
    const xData = data.map((d: IChartBullet) => d.x)
    const yData = data.map((d: IChartBullet) => d.y)
    const ids = data.map((d: IChartBullet) => d.id)
    const selectedData = data.filter((d: IChartBullet) => d.isSelected)
    const selectedX = selectedData.map((d: IChartBullet) => d.x)
    const selectedY = selectedData.map((d: IChartBullet) => d.y)
    const selectedIds = selectedData.map((d: IChartBullet) => d.id)
    const traces = [{
      x: xData,
      y: yData,
      ids: ids,
      type: 'scattergl',
      mode: isTimeSeries ? 'lines' : 'markers',
      name: isTimeSeries ? 'Time series' : 'Nodes'
    },
    {
      x: selectedX,
      y: selectedY,
      ids: selectedIds,
      type: 'scattergl',
      mode: 'markers',
      name: 'Selected nodes',      			
      marker: {
        size: 12,
        color: theme.palette.error.main,
        symbol: "triangle-up"   
      }
    }]
    if (selectForTestRun){
      const testRunData = data.filter((d: IChartBullet) => d.isSelectedForTestRun);
      const testRunTrace = {
        x:  testRunData.map((d: IChartBullet) => d.x),
        y: testRunData.map((d: IChartBullet) => d.y),
        ids: testRunData.map((d: IChartBullet) => d.id),
        type: 'scattergl',
        mode: 'markers',
        name: 'Selected for test run',      			
        marker: {
          size: 12,
          color: '#188D01',
          symbol: "square"   
        }
      }
      return traces.concat(testRunTrace)
    }
    else {
      return traces;
    }    
  }} 
 , [data, isTimeSeries, selectForTestRun]) 

 const layout = useMemo(() => {
    return {  
      title: {
        text: '<b>Data at central point of selected boundary or forcing</b>',
        xref: 'container',
        x: 0.03,
        font: {
          family: FONTFAMILY,
          size: 14,
          color: theme.palette.primary.main
        }
      },
      xaxis: 
      {        
        type: isTimeSeries && 'date',
        title:  axisLabels && axisLabels.length > 0 ? axisLabels[0] : '',
        // tickformat: '%b %Y',
        hoverformat: isTimeSeries ? '%H:%M - %e %b %Y' : '.2f',         
        range: chartAxisRange && chartAxisRange[XRANGE0] && chartAxisRange[XRANGE1] ? [chartAxisRange[XRANGE0], chartAxisRange[XRANGE1]] : undefined       
      },
      yaxis: { 
        hoverformat: '.2f',
        title: axisLabels && axisLabels.length > 1 ? axisLabels[1] : '',
        range: chartAxisRange && chartAxisRange[YRANGE0] && chartAxisRange[YRANGE1] ? [chartAxisRange[YRANGE0], chartAxisRange[YRANGE1]] : undefined     
      },
      showlegend: true,        
      legend: {      
        orientation: 'v'
      },
      font: {
        family: FONTFAMILY,
        size: '0.875rem',
        color: theme.palette.primary.main
      }
    }
 }, [axisLabels, chartAxisRange, isTimeSeries])

  const handleOnClickPoint = useCallback((point) => {    
    const id = point?.id;
    if (id){
      switchSelection(id)
    }  
  }, [switchSelection]);

  const handleRelayout = (axisRange: IAxisRange) => {
    onChangeAxisRange(axisRange)
  }

  return (
    <Plot
      config={{ responsive: true,  modeBarButtonsToRemove: ['select2d', 'lasso2d']}}
      data={chartData}
      style={{ width: '100%', height: '350px' }}
      layout={layout}
      onClick={(e) => handleOnClickPoint(e?.points?.[0])}        
      onRelayout={(e) => handleRelayout(e)}
    />   
  );
}
export default Chart;