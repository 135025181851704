export interface IJobStatus {
  jobId: string;
  projectId: string;
  jobState: string;
  hasError: boolean,
  statusMessage: string;
}

export const JOB_STATUSES = { 
  SCHEDULED: 'scheduled', 
  COMPLETED: 'completed',
  STARTED: 'started',
  PROCESSING: 'processing',
  FAILED: 'failed',
  PENDING: 'pending', 
  RUNNING: 'running',
  ERROR: 'error',
  FINISHED: 'finished',
  CANCELLED: 'cancelled'
}

export const DATA_LINK_JOBS_IN_PROGRESS = [JOB_STATUSES.SCHEDULED, JOB_STATUSES.STARTED, JOB_STATUSES.PROCESSING]

export const JOBS_IN_PROGRESS = [JOB_STATUSES.PENDING, JOB_STATUSES.RUNNING]
