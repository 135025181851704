import { format, parse } from 'date-fns';
export const DATEFORMAT = "yyyy-MM-dd'T'HH:mm:ss"
export const DATEFORMATMILLISECONDS = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"

export const addHours = (numOfHours: number, date = new Date()) => {
  date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);

  return date;
}

export const customDateTimeFormat = (date: Date | number) => {
  return format(date, "yyyy-MM-dd'T'HH:mm:ss")
}

export const chartTimeFormat = (date: Date | number) => {
  return format(date, 'HH:mm - MMM dd, yyyy')
}

export const parseToTime = (dateTime: string) => {   
  return parse(dateTime, "yyyy-MM-dd'T'HH:mm:ss",new Date()).getTime()
}

export const dateTimeFormat = (date: Date | number) => {
  return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
}




