import { TRANSFER_STATUSES } from '../apis/transfer'
import { parseError } from '../helpers/errors'
import { IGetDataset } from '../model/IGetDataset'
import ActionType from './ActionType'

export const copyDatasetToOtherProject = (projectId: string, dataset: IGetDataset, datasetType: string) => ({
  type:  ActionType.COPY_DATASET,
  data: {projectId, dataset, datasetType}
})

export const copyDatasetsToOtherProject = (projectId: string, datasets: Array<IGetDataset>, datasetType: string) => ({
  type:  ActionType.COPY_DATASETS,
  data: {projectId, datasets, datasetType}
})

export const copyComplete = (id: string) => ({
  type: ActionType.COPY_COMPLETE,
  data: id
})

export const copyFail = (id: string, error) => ({
  type: ActionType.COPY_FAIL,
  data: {id: id,
    error: parseError(error, { id })}
})

export const removeCopy = (id: string) => ({
  type: ActionType.REMOVE_COPY,
  data: id
})

export const importFile = (id: string, fileName: string, fileUrl: string, projectId: string, data: object, importType: string, startContainer = "") => ({
  type: ActionType.IMPORT_FILE,
  data: {id: id,
  fileName: fileName,
  fileUrl : fileUrl,
  projectId: projectId,
  data: data,
  importType: importType,
  startContainer: startContainer
}
})

export const updateFile = (projectId: string, id: string, fileName: string, fileUrl: string, size: number, startContainer = "") => ({
 type: ActionType.UPDATE_FILE,
 data: {projectId: projectId, id: id, fileName: fileName, fileUrl: fileUrl, size: size, startContainer: startContainer}
})

export const importStart = (
  id: string,
  createdBy: string,
  projectId: string,
  state: TRANSFER_STATUSES,
  data: object,
  initialId = null
) => ({
  type: ActionType.IMPORT_START,
  data: {id: id,
  createdBy: createdBy,
  projectId: projectId,
  status: state,
  data: data,
  initialId: initialId}
})

export const updateProgress = (id: string, progress, title: string) => ({
  type: ActionType.UPDATE_OR_ADD_PROGRESS_ITEM,
  data: {id: id,
  progress: progress,
  title: title}
})


export const importComplete = (id: string, importType: string) => ({
  type: ActionType.IMPORT_COMPLETE,
  data: {id: id, importType: importType}
})


export const importFail = (id: string, error) => ({
  type: ActionType.IMPORT_FAIL,
  data: {id: id,
  error: parseError(error, { id })}
})

export const removeImport = (id: string) => ({
  type: ActionType.REMOVE_IMPORT,
  data: id
})
