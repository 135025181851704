import round from 'lodash/round'
import toNumber from 'lodash/toNumber'

const roundNumber = (value: any, decimals: number ) => {
  const x = toNumber(value)
  // check if the passed value is a number
  if(typeof x == 'number' && !isNaN(x)){
  
      // check if it is integer
      if (Number.isInteger(x)) {
          return x.toString();
      }
      else {
        const y = round(x, decimals);
        return y.toString()
      }
  
  } else {
      return x.toString();
  }
}

export const csvToTable = (csv: string) => {
  try{
    const lines = csv.split("\n");
   
    const headers = lines[0].split(",");  
    if (headers.length > 0){
      const columns = headers.map((column: string) => {return {field: column.trim(), label: column.trim()}})
      const rows = [];
      for (let i = 1; i < lines.length; i++) {
        const obj = {};
  
        if(lines[i] === undefined || lines[i].trim() === "") {
            continue;
        }
  
        const words = lines[i].split(",");
        for(let j = 0; j < words.length; j++) {
            obj[headers[j].trim()] = words[j];
        }
  
        rows.push(obj);
      }

      const firstColumn = columns[0]
      const fc = rows.map((row) => {
        return {field: row[firstColumn.field], label: row[firstColumn.field]}
      })
      const flippedColumns = [firstColumn, ...fc]
      const keys = flippedColumns.map(c => c.field)
      keys.shift()
      
      const newRows = []     
      const cols = [...headers]
      cols.shift()
      cols.forEach((c) => {
        const col = c.trim()
        const rowObj = {[firstColumn.field] : col }        
        keys.forEach((key: string, index: number) => {         
          const row = rows[index]
          rowObj[key] = roundNumber(row[col], 3)                    
        })
        newRows.push(rowObj) 
      })  

      return {columns: flippedColumns, rows: newRows}
    }
    else{
      return null;
    }


  }
  catch{
    console.log("Failed to convert test model run csv to json")
    return null;
  }  
}