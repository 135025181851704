import { takeEvery, call, select, put } from 'redux-saga/effects'
import ActionType from '../actions/ActionType'
import { IGetProject } from '../model/IGetProject';
import { getProject, getUser } from '../reducers/state';
import { IUser } from '../model/IUser';
import { publishData } from '../apis/moodApi';
import { setPublishingResultsToMOOD } from '../actions/mood';

export default function* watchMOOD() {
  yield takeEvery(ActionType.PUBLISH_DATA_TO_MOOD, handlePublishData)
}

export function* handlePublishData() { 
  const project : IGetProject | null = yield select(getProject);
  const user: IUser = yield select(getUser);

  if (user && user.tenantId && user.tenantName && project && project.id && project.name){
    try{
      yield put(setPublishingResultsToMOOD())
      yield call(publishData, user.tenantId, user.tenantName,  project.id, project.name);
    }
    catch (error) {          
      console.log(error)    
    }
    finally{
      yield put(setPublishingResultsToMOOD(false))
    }
  }
}