import { metadata } from './support'

export enum TRANSFER_STATUSES  {
  NONE = 'None',
  PENDING = 'Pending',
  IN_PROGRESS = 'InProgress',
  COMPLETED = 'Completed',
  ERROR = 'Error'
}

//TODO: Currently not in use
export const getTransfers = async ({ statuses = [] } = {}) => {
  const statusQuery = statuses.map(status => 'status=' + status).join('&')
  const { data } = await metadata.getJson(
    `conversion/transfer/list-summaries?${statusQuery}`,
    "2"
  )
  return data
}

export const getTransfer = (id: string) =>
  metadata.getJson(`conversion/transfer/${id}`, "2")

export const transferUploadConvert = (body: object) =>
  metadata.postJson('conversion/upload-convert', body, "3")

export const transferConvert = (datasetId: string, body: object) =>
  metadata.postJson(`metadata/dataset/${datasetId}/convert`, body, "3")

export const transferDownloadConvert = (datasetId: string, body: object) =>
  metadata.postJson(`metadata/dataset/${datasetId}/download-convert`, body, "3")
