import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'

const useStyles = makeStyles({
  longText: {
    maxWidth: '25vw',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'table-cell',
    paddingRight: 8
  }
})

interface IProps {
  text: string
}

const LongText = (props: IProps) => {
  const { text } = props
  const classes = useStyles()

  return (
    <Tooltip title={text ? text : ""}>
      <div className={classes.longText}>{text}</div>
    </Tooltip>
  )
}

export default React.memo(LongText)