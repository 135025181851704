import React from 'react';
import { ReactComponent as ZoomToArea } from '@mike/mike-shared-frontend/media/icons/ZoomToArea';
import Tooltip from '@material-ui/core/Tooltip';
import { ViewerToolsButtonStyle, ViewerToolsDisabledStyle } from '../ViewerStyles';
import MikeVisualizer from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/MikeVisualizer';
// import { IViewerBounds } from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/IMikeVisualizerModels';
import { IState } from '../../../reducers';
import { useSelector } from 'react-redux';
// import { FeatureCollection } from 'geojson';
// import bbox from '@turf/bbox';
// import { Feature } from "geojson";

const { resetCameraToBounds } = MikeVisualizer;

/**
 * @name ViewerToolZoomToExtent
 * @summary Allows zooming to extent.
 */
export const ViewerToolZoomToExtent = () => {

/*   const points: Array<Feature>  = useSelector(
    (state: IState) => state.mapContent.points // points shall be inside the mesh
  ); 

  const meshBoundary  = useSelector(
    (state: IState) => state.mapContent.meshBoundary // to be replaced, currently created by reading the border points from .mesh files
  );*/

  const meshExtent  = useSelector(
    (state: IState) => state.mapContent.meshExtent
  );

  const zoomToBoundary = () =>{
  /*  if (meshBoundary){
      const bboxToExt = bbox(meshBoundary)
      resetCameraToBounds([bboxToExt[0], bboxToExt[2], bboxToExt[1], bboxToExt[3], 0, 0] as IViewerBounds)
    }
     if (points && points.length > 0){
      const bboxToExt = bbox({type: "FeatureCollection", features: points})
      resetCameraToBounds([bboxToExt[0], bboxToExt[2], bboxToExt[1], bboxToExt[3], 0, 0] as IViewerBounds)
    }  || (points && points.length > 0) */
    meshExtent && resetCameraToBounds(meshExtent)    
  } 

  return (
    <Tooltip title={'Zoom to extent'} placement="right">
      {meshExtent ? 
        <button className={ViewerToolsButtonStyle} onClick={zoomToBoundary}>
          <ZoomToArea />
        </button> :
        <div className={ViewerToolsDisabledStyle}>
          <ZoomToArea />
        </div>
      }
    </Tooltip>
  )
};

export default ViewerToolZoomToExtent