
import { ILayerConfig, ILayerGroup, ILegendLayer } from "../reducers/legend"
import ActionType from "./ActionType"

export const loadBackgroundLayer = (datasetId: string, update = false) => ({
  type: ActionType.LEGENDGROUP_GET_BACKGROUND_VTP,
  data: {datasetId, update}
})

export const setLoadingBackgroundLayer = (datasetId: string, loading = true) => ({
  type: ActionType.LEGENDGROUP_LOADING_BACKGROUND_VTP,
  data: {datasetId,loading}
})

export const addLayerGroup = (title: string) => ({
  type: ActionType.LEGENDGROUP_ADD_GROUP,
  data:  title
})

export const setLegendGroupLayersLoaded = (title: string) => ({
  type: ActionType.LEGENDGROUP_SET_LAYERS_LOADED,
  data:  title
})

export const getBackgroundLayer = (projectId: string, hiddenLayerIds: Array<string>) => ({
  type: ActionType.GET_BACKGROUND_LAYERS,
  data:  {projectId, hiddenLayerIds}
})

export const updateHiddenIds = (ids: Array<string>) => ({
  type: ActionType.LAYERCONFIG_UPDATE_HIDDEN_IDS,
  data: ids
})

export const addHiddenId = (id: string) => ({
  type: ActionType.LAYERCONFIG_ADD_HIDDEN_ID,
  data: id
})

export const removeHiddenId = (id: string) => ({
  type: ActionType.LAYERCONFIG_REMOVE_HIDDEN_ID,
  data: id
})

export const removeLayerGroup = (title: string) => ({
  type: ActionType.LEGENDGROUP_REMOVE_GROUP,
  data: title
})

export const addLayer = (groupTitle: string, layer: ILegendLayer) => ({
  type: ActionType.LEGENDGROUP_ADD_LAYER,
  data:  {groupTitle, layer}
})

export const addLayers = (groupTitle: string, layers: Array<ILegendLayer>) => ({
  type: ActionType.LEGENDGROUP_ADD_LAYERS,
  data:  {groupTitle, layers}
})

export const updateLayer = (groupTitle: string, layer: ILegendLayer) => ({
  type: ActionType.LEGENDGROUP_UPDATE_LAYER,
  data:  {groupTitle, layer}
})

export const deleteBackground = (id: string) => ({
  type: ActionType.DELETE_BACKGROUND,
  data: id
})

export const removeLayers = (groupTitle: string, layerIds: Array<string>) => ({
  type: ActionType.LEGENDGROUP_REMOVE_LAYERS,
  data:  {groupTitle, layerIds}
})

export const deleteLayer = (legendGroup: ILayerGroup, id: string) => ({
  type: ActionType.LEGENDGROUP_DELETE_LAYER,
  data: {legendGroup, id}
})

export const hideLayer = (layerGroup: ILayerGroup, layer: ILegendLayer) => ({
  type: ActionType.LEGENDGROUP_HIDE_LAYER,
  data: {layerGroup, layer}
})

export const hideLayerByNames = (layerGroupName: string, layerNames: Array<string>) => ({
  type: ActionType.LEGENDGROUP_HIDE_LAYER_BY_NAME,
  data: {layerGroupName, layerNames}
})

export const showLayer = (layerGroup: ILayerGroup, layer: ILegendLayer) => ({
  type: ActionType.LEGENDGROUP_SHOW_LAYER,
  data: {layerGroup, layer}
})

export const legendGroupHideAll = (legendGroup: ILayerGroup) => ({
  type: ActionType.LEGENDGROUP_HIDE_ALL,
  data: legendGroup
})

export const legendGroupShowAll = (legendGroup: ILayerGroup) => ({
  type: ActionType.LEGENDGROUP_SHOW_ALL,
  data: legendGroup
})

export const legendGroupReset = () => ({
  type: ActionType.LEGENDGROUP_RESET
})

export const setLayerConfig = (layerConfig: ILayerConfig, store = false) => ({
  type: ActionType.LAYERCONFIG_SET,
  data: {layerConfig, store}
})

export const getLayerConfig = (projectid: string) => ({
  type: ActionType.LAYERCONFIG_GET,
  data: projectid
})

export const setLoadingLayerConfig = (loading = true) => ({
  type: ActionType.LAYERCONFIG_LOADING,
  data: loading
})