import ActionType from './ActionType';
import { IGetProject, IGetProjectPath } from '../model/IGetProject';
import { ICreateProject } from '../model/ICreateProject';

export const createFolder = (data: ICreateProject, projectId: string) =>({
  type: ActionType.CREATE_FOLDER,
  data: {
    data,
    projectId
  }
})

export const updateFolder = (data: IGetProject) =>({
  type: ActionType.UPDATE_FOLDER,
  data
})

export const deleteFolders = (ids: Array<string>) =>({
  type: ActionType.DELETE_FOLDERS,
  data: ids
})

export const setFolderPath = (path: Array<IGetProjectPath>) => ({
  type: ActionType.SET_FOLDER_PATH,
  data: path
})

export const setCreatingFolder = (creating = true) => ({
  type: ActionType.CREATING_FOLDER,
  data: creating
})

export const setCreateFolderPanelOpen = (open = true) => ({
  type: ActionType.SET_FOLDER_PANEL_OPEN,
  data: open
})

export const getFolders = (projectId: string, offset: number, rowsPerPage: number, sortBy: string, sortOrder: string, namePrefix: string) => ({
  type: ActionType.GET_FOLDERS,
  data: {
    projectId,
    offset: offset,    
    rowsPerPage: rowsPerPage, 
    sortBy: sortBy, 
    sortOrder: sortOrder, 
    namePrefix: namePrefix
  }
})

export const setLoadingFolders = (loading = true) => ({
  type: ActionType.LOADING_FOLDERS,
  data: loading
})

export const setDeletingFolders = (deleting = true) => ({
  type: ActionType.DELETING_FOLDERS,
  data: deleting
})

export const setFolders = (path: Array<IGetProjectPath>,  projectId: string, projects: IGetProject[], totalCount: number, limit: number, offset: number) => ({
  type: ActionType.SET_FOLDERS,
  data: {
    path,
    projectId,
    folders: projects,
    totalCount: totalCount,
    limit: limit,
    offset: offset
  }
})

export const setFoldersPagination = (page: number) => ({
  type: ActionType.SET_FOLDERS_PAGINATION,
  data: page
})

export const setFoldersRowsPerPage = (rowsPerPage: number) => ({
  type: ActionType.SET_FOLDERS_ROWSPERPAGE,
  data: rowsPerPage
})

export const setFoldersSort = (sortBy: string, sortOrder: 'asc' |'desc') => ({
  type: ActionType.SET_FOLDERS_SORT,
  data: {sortBy: sortBy, sortOrder: sortOrder}
})

export const setFoldersNamePrefix = (namePrefix: string) => ({
  type: ActionType.SET_FOLDERS_NAMEPREFIX,
  data: namePrefix
})