import { isNumber, toNumber } from 'lodash';
import { Big } from 'big.js';
import { formatDate } from '@mike/mike-shared-frontend/mike-shared-helpers/date';
import { Feature, Point } from 'geojson';
import MikeVisualizerLib from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/MikeVisualizer';
import { MESH_LAYER_ID } from '../reducers/legend';
import vtkPointPicker from 'vtk.js/Sources/Rendering/Core/PointPicker';

type IWktParser = (wkt: string) => IWktParserResult;

interface IWktParserResult {
  AUTHORITY: { EPSG: string };
  AXIS: { Northing: string };
  GEOGCS: {
    name: string;
    // DATUM: {…},
    // PRIMEM: {…},
    // UNIT: {…},
    // AUTHORITY: {…}
  };
  PROJECTION: string;
  UNIT: {
    name: string;
    convert: number;
    // AUTHORITY: {…}
  };
  a: number;
  central_meridian: number;
  datumCode: string;
  ellps: string;
  false_easting: number;
  false_northing: number;
  k0: number;
  lat0: number;
  latitude_of_origin: number;
  long0: number;
  name: string;
  projName: string;
  rf: number;
  scale_factor: number;
  srsCode: string;
  to_meter: number;
  type: string;
  units: string;
  x0: number;
  y0: number;
}

export async function getDecimalsForPointsFromWKT(wkt: string) {
  const wktParser: IWktParser = (await import('wkt-parser')).default;
  const parsedWkt = wktParser(wkt);
  const unitIs = (str: string) => parsedWkt.units.toLowerCase().includes(str);
  let decimals = 6;
  if (unitIs('metre') || unitIs('meter')) {
    decimals = 2;
  } 
  return decimals
}

export function getElevation(x: number, y: number, elevations) {
  const { getState } = MikeVisualizerLib;
  const { renderer } = getState();    
  const actor = renderer.getActors().find((a) => a.getActorId() === MESH_LAYER_ID);
  if (actor){    
    const pointPicker = vtkPointPicker.newInstance();
    pointPicker.setPickFromList(1);
    pointPicker.initializePickList();
    pointPicker.addPickList(actor);
    pointPicker.pick([x, y, 0.0], renderer)
    if (pointPicker.getActors().length > 0) {
      const id: number = pointPicker.getPointId(); 
      const z = elevations[id]
      return z
    }
  }
}

/**
 * Encodes a string to make it URL-safe.
 *
 * To be more stringent in adhering to RFC 3986 (which reserves !, ', (, ), and *), even though these characters have no formalized URI delimiting uses, the following can be safely used.
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
 *
 * @param str
 */
export function betterEncodeURIComponent(str: string): string {
  return encodeURIComponent(str).replace(/[!'()*]/g, (c) => {
    return '%' + c.charCodeAt(0).toString(16);
  });
}

type InputDate = string | Date | number | null | undefined;
/**
 * Formats a Datestring into a short format. Will not include Time
 *
 * @param inputDate
 */
export function getShortDate(inputDate: InputDate): string {
  return formatDate(inputDate);
}

/**
 * Formats a Datestring into a short format. Will include Time
 *
 * @param inputDate
 */
export function getShortDateTime(inputDate: InputDate): string {
  return formatDate(inputDate, true);
}

/**
 * Dynamically rounds to a given number of digits depending on the magnitude of the value to be rounded.
 * Main rule is that the number will be rounded with maximum number of fraction digits corresponding to numberOfDigits.
 * Absolute values < 1 will be formatted with significant number digits corresponding to numberOfDigits.
 * Large values with more integer-digits that numberOfDigits will be rounded with no fraction digits.
 *
 * @param val the number to be rounded
 * @param numberOfDigits The number of digits to be used for rounding.
 * @param maxLength the max length of the formatted number before switching to exponential format
 *
 */
export function toLocaleRoundedString(val, numberOfDigits = null, maxLength = null): string {
  if (!val) {
    return val !== 0 ? val : val.toString();
  }

  let options = {};

  // Handle values parsed in as strings
  const value = isNumber(val) ? val : toNumber(val);

  if (!isNumber(value) || !value) {
    return val.toString();
  }

  // if numberOfDigits is not provided don't round
  if (!isNumber(numberOfDigits)) {
    return toFormattedNumber(value, {}, maxLength);
  }

  // if numberOfDigits is zero round to zero decimals
  if (numberOfDigits === 0) {
    options = { maximumFractionDigits: 0 };
    return toFormattedNumber(value, options, maxLength);
  }

  // For absolute number below 1 round to significant number of digits
  const absVal = Math.abs(value);
  if (absVal < 1) {
    options = {
      maximumSignificantDigits: numberOfDigits,
    };
    return toFormattedNumber(value, options, maxLength);
  }

  // if the integer part has more or equal digits than numberOfDigits, round to the integer part
  const integerLength = Math.trunc(absVal).toString().length;
  if (integerLength >= numberOfDigits) {
    options = { maximumFractionDigits: 0 };
    return toFormattedNumber(value, options, maxLength);
  }

  // if the integer part has less digits than numberOfDigits round to the significant digits
  options = { maximumSignificantDigits: numberOfDigits };
  return toFormattedNumber(value, options, maxLength);
}

/**
 * Converts a given number to a formated one.
 *
 * @param value the number to be formatted
 * @param options the formatting options
 * @param maxLength the max length of the formatted number before switching to exponential format
 */
function toFormattedNumber(value: number, options, maxLength = null): string {
  const locale = undefined; // use browser default
  const { maximumSignificantDigits, maximumFractionDigits } = options;
  let roundedValue;

  if (isNumber(maximumSignificantDigits)) {
    roundedValue = roundSignif(value, maximumSignificantDigits);
  } else if (isNumber(maximumFractionDigits)) {
    roundedValue = roundDecimal(value, maximumFractionDigits);
  } else {
    roundedValue = value;
  }

  const formatted = roundedValue.toLocaleString(locale, {
    ...options,
    useGrouping: false,
  });

  // if maxLength is given switch to exponential format if string is too long
  if (isNumber(maxLength) && formatted.length > maxLength) {
    const digits = Math.max(1, maxLength - 4); // make room for 'e+XX' - but at least always inlude one digit

    roundedValue = roundSignif(value, digits);
    return roundedValue.toExponential(); // todo hevo will always use point for decimal symbol. Should it be replaced by comma depeneing on locale?
  }

  return formatted;
}

export function toFeaturesWithRoundedCoords(features: Array<Feature<Point>>, decimals: number) {
  const featuresWithRoundedCoords = features.map((feature: Feature<Point>) => {
    const geometry: Point = feature.geometry
    const coords = geometry && geometry.coordinates ? feature.geometry.coordinates : []
    const roundedCoords =  coords.map((coord: number) => toRoundedNumber(coord, decimals))
    return {...feature, geometry: {...feature.geometry, coordinates: roundedCoords}}
  })
  return featuresWithRoundedCoords
}

/**
 * Dynamically rounds to a given number of digits depending on the magnitude of the value to be rounded.
 * Main rule is that the number will be rounded with maximum number of fraction digits corresponding to numberOfDigits.
 * Absolute values < 1 will be formatted with significant number digits corresponding to numberOfDigits.
 *
 * @param val the number to be rounded
 * @param numberOfDigits The number of digits to be used for rounding.
 *
 */
export function toRoundedNumber(val, numberOfDigits): number {
  if (!val) {
    return val;
  }

  if (!numberOfDigits) {
    return roundDecimal(val);
  }

  const absVal = Math.abs(val);

  // For small numbers we will use significant digits
  if (absVal < 1) {
    return roundSignif(val, numberOfDigits);
  }

  // by default round to the number of digits provided
  return roundDecimal(val, numberOfDigits);
}

/**
 * Round to a specified number of digits after the decimal. 0 means round to integer value.
 * Negative values mean round to digits before decimal (to an integer which is a multiple of 10**-digits).
 *
 * @param x
 * @param digits
 */
export function roundDecimal(x, digits = 0) {
  if (!isNumber(x)) {
    return 0;
  }

  const bigX = new Big(x);
  const rounded = +bigX.round(digits); // round away from zero

  // avoid return -0 when rounding of negative numbers like -0.123 to zero digits
  return rounded ? +rounded : 0;
}

/**
 * Round to a specified number of significant digits.
 *
 * @param x
 * @param digits
 */
export function roundSignif(x, digits = 1) {
  if (digits < 1) {
    throw new Error('Significant digits must be 1 or greater');
  }

  if (x === 0) {
    return 0;
  }

  const scaleFactor = Math.floor(Math.log10(Math.abs(x)));

  return roundDecimal(x, digits - scaleFactor - 1);
}
