import MIKE_COLORS from "@mike/mike-shared-frontend/mike-shared-styles/mike-colors";

export const colors = [
  '#008BEC',
  '#F9BA32',
  '#10E7DC',
  '#FE7860',
  '#AB8BE5',
  '#2EB67D',
  '#FCE51E',
  '#60BFF5',
  '#69426A',
  '#187206',
];

export const boundaryColor = (id: string) => {
  try{    
    return colors[parseInt(id) % colors.length];
  }
  catch{
    return MIKE_COLORS.BRANDBLUE_DEFAULT
  }  
}