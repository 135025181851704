import { DATALINKJOBS, JOBS } from "../shared/constants";

export const getNiceJobName = (name: string) => {
  switch (name) {
    case JOBS.TESTSETUP:
      return "Test model";
    case JOBS.AUTOSETUP:
      return "Full model";
    case JOBS.MDA:
      return "Apply settings"
    case JOBS.ENVIRONMENTALDATA:
      return "Upload environmental data" 
    default:
      return "";
  }
}

export const getNiceDataLinkJobName = (name: string) => {
  switch (name) {
    case DATALINKJOBS.BOUNDARYEXTRACTION:
      return "Boundary Extraction";
    case DATALINKJOBS.DATAEXTRACTION:
      return "Data extraction";  
    default:
      return "";
  }
}