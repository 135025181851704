

import React, { useMemo, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { css } from 'emotion'
import theme from '../../shared/mikeSharedTheme'
import { useDispatch, useSelector } from 'react-redux';
import { IFastWaveConfig, IJob } from '../../model/IFastWaveConfig';
import { IState } from '../../reducers';
import { JOBS_IN_PROGRESS } from '../../model/IJobStatus';
import { getJob } from '../../helpers/fastwave';
import { JOBS } from '../../shared/constants';
import { subTitleStyle, titleContainer, titleStyle } from '../../shared/styles';
import ScrollableTable from '../TableComponents/ScrollableTable';
import { useIntl } from 'react-intl';
import { ReactComponent as RightCompress } from "@mike/mike-shared-frontend/media/icons/RightCompress"
import { ReactComponent as Left } from "@mike/mike-shared-frontend/media/icons/Left"
import MikeButton from '../mike-button';
import { createContainer } from '../../actions/job';

const placeHolderStyle = css`
  height: ${theme.spacing(2)}px;
`
const tableContainerStyle = css`
  width: calc(100% - 800px);
`;

const popupStyle = css`
  z-index: 10;
  position: absolute;
  width: calc(100% - 800px);
  height: calc(100% - 60px);
  top: 60px;
  left: 800px;
  background-color: ${theme.palette.lightGrey.main};
`;

const collapseContainerStyle = css`
    z-index: 11;
    position: absolute;
    width: 40px;
    height: 40px;
    top: 50%;
    right: 0;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center; 
`;

const paddingStyle = css`
  padding: ${theme.spacing(2)}px;
`;

const noTestRunMessageStyle = css`
  padding-bottom: ${theme.spacing(1)}px;
`

const noTestRunTableStyle = css`
  height: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const collapseButtonStyle = css`
  width: 40px;
  min-width: 40px;
  height: 40px;
  &.MuiIconButton-root {
    padding: 0;
  }
`
const linkButtonStyle = css`
  &.MuiButton-root {   
    padding: 0;  
    padding-top: 2px;  
    height: 1.286rem;
    white-space: nowrap;
    &:disabled {
      height: 1.286rem;
    }
  }
`;

const textAndlinkButtonContainerStyle = css`
  display: flex;
  align-items: center;
`



const TestRunTable = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  
  const testRunOutput = useSelector(
    (state: IState) => state.mapContent.testRunOutput
  );  

  const loadingTestRunOutput = useSelector(
    (state: IState) => state.mapContent.loadingTestRunOutput
  ); 

  const jobs: Array<IJob> = useSelector(
    (state: IState) => state.job.jobs
  );  

  const jobIsRunning: boolean = useMemo(() => {
    const job = getJob(jobs, JOBS.TESTSETUP);
    const status = job !== undefined && job.status ? job.status : "";
    return status && JOBS_IN_PROGRESS.includes(status.toLowerCase()) ? true : false; 
  }, [jobs])

  const config: IFastWaveConfig = useSelector(
    (state: IState) => state.mapContent.fastWaveConfig
  ); 
  
  const readyForTestModelRun: boolean = useMemo(() => {
    return config && config.test_event_time && config.setup ? true : false
  }, [config])

  const showTable = testRunOutput && testRunOutput.columns && testRunOutput.rows

  const [showMap, setShowMap] = useState(false)

  const toggleCollapse = () => {
    setShowMap((previous) => {return !previous})
  };

  const handleRunTest = () => {   
    dispatch(createContainer(JOBS.TESTSETUP))
  }
 
  return (   
    <>
     <div className={collapseContainerStyle}>    
        <IconButton onClick={toggleCollapse} className={collapseButtonStyle}>
          {showMap ? <Left/> : <RightCompress/>}
        </IconButton>      
     </div>
   {!showMap && 
    <div className={popupStyle}>
      <div className={titleContainer}>
        <div>
          <Typography variant="h5" className={titleStyle}>{intl.formatMessage({id: 'testRunTable.scatterPlots'})}</Typography>
          <Typography variant="body2" className={subTitleStyle}>{intl.formatMessage({id: 'testRunTable.clickOnNodes'})}</Typography>
        </div>
      </div>
     
        {!showTable ?
          <div className={noTestRunTableStyle}>            
            <Typography className={noTestRunMessageStyle} variant="body2">{intl.formatMessage({id: 'testRunTable.noTestRuns'})}</Typography>
            <Typography className={noTestRunMessageStyle} variant="body2">{intl.formatMessage({id: 'testRunTable.clickOnANode'})}</Typography>
            <div className={textAndlinkButtonContainerStyle}>
              <Typography variant="body2">{intl.formatMessage({id: 'testRunTable.toSelect'})}</Typography>
              <MikeButton className={linkButtonStyle} id="meshButton" buttontype="text" onClick={handleRunTest} disabled={!readyForTestModelRun || jobIsRunning}>
                {intl.formatMessage({id: 'testRunTable.testRun'})}               
              </MikeButton>
            </div>          
          </div> :
        <>
          <div className={placeHolderStyle}/>
          <Typography className={paddingStyle} variant="h5">{intl.formatMessage({id: 'testRunTable.resultOfTestRun'})}</Typography>
          
          <div className={tableContainerStyle}/>                       
            <ScrollableTable 
              columns={testRunOutput.columns}
              notSortableColumns={testRunOutput.columns.map((column) => column.field) as []}
              data={testRunOutput.rows}
              idField={testRunOutput.columns[0].field}
              onCellClick={() => {}}
              onSelectionChange={(selectedItems: Array<any>) => {console.log(selectedItems)}}
              order={'asc'}
              orderBy={testRunOutput.columns[0].field}
              selectable={false}
              loading={jobIsRunning || loadingTestRunOutput}
              selectedRows={new Array<any>()}
            />          
          </>
          }
    </div>}
    </>
  );
};

export default TestRunTable;