import { all } from "redux-saga/effects";

import watchAddMessage from './message'
import {watchProject } from "./projectContent"
import { watchGetPointLayer} from "./mapContent"
import watchUploadAndImport from './saveToPlatform'
import watchUploads from './uploads'
import watchImports from './imports'
import watchExportAndDownload from './exportAndDownload'
import watchExports from './readFromPlatform'
import watchProgress from './progressItems'
import watchJobs from './job'
import watchCheck from './check'
import watchIAM from "./iam";
import watchCreateMesh from './createMesh';
import watchUseOwnEnvironmentalData from './ownData';
import watchMOOD from './mood';
import watchGetProjects from './projects';
import watchGetFolders from './folders';
import watchAuth from './auth'

export default function* rootSaga() {
  yield all([ 
    watchAuth(),
    watchGetProjects(),
    watchIAM(), 
    watchAddMessage(), 
    watchProject(),   
    watchUploadAndImport(), 
    watchUploads(), 
    watchImports(), 
    watchGetPointLayer(), 
    watchExportAndDownload(), 
    watchExports(), 
    watchProgress(),
    watchJobs(),
    watchCheck(),
    watchCreateMesh(),
    watchUseOwnEnvironmentalData(),
    watchMOOD(),
    watchGetFolders()
  ]);
}
