const MIKEPREFIX = 'MIKE ';
export const ERROR = 'Error';

export function appName(name: string): string {
  const applicationName = name.startsWith(MIKEPREFIX) ? name.slice(MIKEPREFIX.length) : name;
  return applicationName;
}

export function path(appId: string, projectId: string, tenantId: string): string {
  let projectPath = '';  
  const tenantQueryParameter = tenantId ? '?tenantId=' + tenantId : ''
  const dataLinkQueryParameter = tenantId ? '&tenantId=' + tenantId : ''
  switch (appId) {
    case 'dataLink':     
    case 'DataLink':
    case 'Data Link':
      projectPath = '/map?projectID=' + projectId + dataLinkQueryParameter;
      break;
    case 'floodScreener':
      projectPath = ''; // app does not support deep links yet 'map?projectID=' + projectId
      break;
    case 'meshBuilder':
    case 'Mesh Builder':
      projectPath = 'project/' + projectId + '/workspace-list' + tenantQueryParameter;
      break;
    case 'bathymetryBuilder':
    case 'Bathymetry Builder':
      projectPath = 'project/' + projectId + tenantQueryParameter;
      break;
    case 'MIKE 3D World':
      projectPath = 'project/' + projectId + tenantQueryParameter;
      break;
    case 'adminCenter':
      projectPath = ''; // Admin Center is not based on projects
      break;
    case 'dataAdmin':
    case 'Cloud Admin':
      projectPath = 'project/' + projectId + tenantQueryParameter;
      break;
    case 'MIKE Fast Wave Emulator':
    case 'Fast Wave Emulator':
      projectPath = 'project/' + projectId + tenantQueryParameter;
      break;
    default:
      break;
  }
  return projectPath;
}
