import React, { useCallback, useState } from 'react';
import { IMikeTheme } from '../../shared/mikeSharedTheme';
import { makeStyles, createStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import EnhancedTableHead from './EnhancedTableHead';
import TableBodySkeleton from './TableBodySkeleton';
import Actions, { IAction } from './Actions';
import { IColumn, getRender, getPadding } from './support';

const useStyles = makeStyles((theme: IMikeTheme) => {
  return createStyles({
    root: {
      width: '100%',
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
    },
    table: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    tableHeader: {
      zIndex: 1,
    },
    actionButton: {
      width: 40,
      paddingRight: theme.spacing(4),
    },
  });
});



export interface IProps {
  actions?: Array<IAction>;
  loading: boolean;
  columns: Array<IColumn>;
  idField: string;
  rowsPerPage?: number;
  data: Array<any>;
  // eslint-disable-next-line no-undef
  firstTableCellRender?: (
    item: any,
    isSelectable: boolean,
    isSelected: boolean,
    onClick: (event: any, item: any) => void
  ) => React.ReactNode;
  page?: number;
  totalCount?: number;
  notSortableColumns?: Array<string>;
  topOffset?: number;
  onChangePage?: (page: number) => void;
  onChangeRowsPerPage?: (rowsPerPage: number) => void;
  onCellClick: (item: any) => void;
  onHandleRequestSort?: (
    orderBy: string | ((item: any) => string | number),
    order: 'asc' | 'desc'
  ) => void;
  onSelectionChange: (selectedItems: Array<any>) => void;
  order: 'asc' | 'desc';
  orderBy: string | ((item: any) => string | number);
  rowsPerPageOptions?: Array<number> | Array<{ value: number; label: string }>;
  selectedRows: Array<any>;
  pagination?: boolean;
  selectable: boolean;
  skeletonRowCount?: number;
  onDragEnd?: (reorderedData: Array<any>) => void;
  actionsPopupStateChange?: (open: boolean, row: object) => void;
}

const DataTable = (props: IProps) => {
  const classes = useStyles();
  const {
    actions,
    loading,
    columns,
    idField,
    firstTableCellRender,
    rowsPerPage = 10,
    data = [],
    page = 0,
    totalCount,
    notSortableColumns,
    topOffset = 0,
    onChangePage,
    onChangeRowsPerPage,
    onCellClick,
    onHandleRequestSort,
    onSelectionChange,
    order,
    orderBy,
    rowsPerPageOptions = [10, 20, 25],
    selectedRows = [],
    pagination = false,
    selectable,
    skeletonRowCount = 10,
    onDragEnd,
    actionsPopupStateChange
  } = props;

  const _handleRequestSort = useCallback(
    (sortBy: string | ((item: any) => string | number), sortDir: 'asc' | 'desc') => {
      if (onHandleRequestSort) {
        onHandleRequestSort(sortBy, sortDir);
      }
    },
    [onHandleRequestSort]
  );

  const _handleChangePage = useCallback(
    (_event, thePage) => {
      if (onChangePage) {
        onChangePage(thePage);
      }
    },
    [onChangePage]
  );

  const _handleChangeRowsPerPage = useCallback(
    (event) => {
      if (onChangeRowsPerPage) {
        onChangeRowsPerPage(event.target.value);
      }
    },
    [onChangeRowsPerPage]
  );

  const _handleCellClick = useCallback(
    (event, item) => {
      event.stopPropagation();
      onCellClick(item);
    },
    [onCellClick]
  );

  const _handleActionClick = (event: any) => {
    event.stopPropagation();
  };

  const _handleSelect = useCallback(
    (event, item) => {
      event.stopPropagation();
      const selectedIndex = selectedRows.findIndex(
        (selectedRow) => selectedRow[idField] === item[idField]
      );
      let newSelected = Array<any>();
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedRows, item);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedRows.slice(1));
      } else if (selectedIndex === selectedRows.length - 1) {
        newSelected = newSelected.concat(selectedRows.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedRows.slice(0, selectedIndex),
          selectedRows.slice(selectedIndex + 1)
        );
      }
      onSelectionChange(newSelected);
    },
    [onSelectionChange, selectedRows, idField]
  );

  const _isSelected = (item: any) =>
    selectedRows.filter((selectedRow) => selectedRow[idField] === item[idField]).length > 0;

  const [draggedRow, setDraggedRow] = useState();
  const [targetRow, setTargetRow] = useState();

  const handleOnDragStart = (row: any) => {
    setDraggedRow(row)
  }

  const handleOnDragEnter = (row: any) => {
    setTargetRow(row)
  }

  const handleOnDragEnd = useCallback((e) => {
    if (draggedRow && targetRow && draggedRow[idField] !== targetRow[idField]){
      const targetId = data.findIndex((row: any) => row[idField] === targetRow[idField])            
      const updatedRows = data.filter((row: any) =>  row[idField] !== draggedRow[idField])
      updatedRows.splice(targetId, 0, draggedRow)  
      onDragEnd(updatedRows)
    }
    else{
      e.preventDefault();
      e.stopPropagation();
    }
  }, [data, draggedRow, idField, onDragEnd, targetRow])

  return (
    <Paper className={classes.root} elevation={0}>
      <Table className={classes.table}>
        <EnhancedTableHead
          actions={actions}
          topOffset={topOffset}
          columns={columns}
          notSortableColumns={notSortableColumns}
          onRequestSort={_handleRequestSort}
          sortDirection={order}
          sortBy={orderBy}
          selectable={selectable}
        />

        {loading ? (
          <TableBodySkeleton
            skeletonColumnCount={actions ? columns.length + 1 : columns.length}
            skeletonRowCount={skeletonRowCount}
          />
        ) : (
          <TableBody>
            {data.map((item: any) => {
              const rowKey = item[idField];
              const isSelected = _isSelected(item);

              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isSelected}
                  tabIndex={-1}
                  key={rowKey}
                  selected={isSelected}
                  component= "tr"
                  draggable={onDragEnd !== undefined}
                  onDragStart={() => onDragEnd && handleOnDragStart(item)}
                  onDragEnter={() => onDragEnd && handleOnDragEnter(item)}
                  onDragEnd={onDragEnd && handleOnDragEnd}
                >
                  {firstTableCellRender ? (
                    firstTableCellRender(item, selectable, isSelected, _handleSelect)
                  ) : selectable ? (
                    <TableCell padding="checkbox" onClick={(event) => _handleSelect(event, item)}>
                      <Checkbox checked={isSelected} />
                    </TableCell>
                  ) : (
                    null //<TableCell padding="checkbox" />
                  )}

                  {columns.map((col) => {
                    const value = item[col.field];
                    const render = getRender(col);
                    const callback = col.callback

                    return (
                      <TableCell
                        className={col.className ? col.className(item) : ''}
                        key={col.field}
                        padding={getPadding(col)}
                        align={col.numeric ? 'right' : 'left'}
                        onClick={(event) => callback ? callback(item) : _handleCellClick(event, item)}
                      >
                        {render(value, item, data)}
                      </TableCell>
                    );
                  })}
                  {actions ? (
                    <TableCell
                      align="left"
                      className={classes.actionButton}
                      onClick={(event) => _handleActionClick(event)}
                    >
                      <IconButton>
                        <Actions actions={actions} row={item} actionsPopupStateChange={actionsPopupStateChange}/>
                      </IconButton>
                    </TableCell>
                  ) : null}
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
      {pagination && totalCount && (
        <TablePagination
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          rowsPerPageOptions={rowsPerPageOptions}
          onPageChange={_handleChangePage}
          onRowsPerPageChange={_handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
};

export default DataTable;
