import ActionType from '../actions/ActionType'
import { IAction } from '../actions/Action'
import { IError } from '../model/IError'

export interface IErrorsState {
  errors: IError[]; 
}

const initState = { errors: Array<IError>() }

const errors = (state = initState, action: IAction<any>) => {
  switch (action.type) {
    case ActionType.ADD_ERRORS:       
      return { ...state, errors: [...state.errors, action.data] };  
    case ActionType.ADD_ERROR:       
      return { ...state, errors: [...state.errors, action.data] };       
    case ActionType.REMOVE_ERROR: {
      const rmState = state.errors.filter(item => {
        return item.id !== action.data;
      }); 
      return {
          ...state,
          errors: [...rmState]
      };   
    }
          
    default:
        return state;
  }
};

export default errors
