import { IState } from "./index";

export const getLayerConfiguration = (state: IState) => state.legend.layerConfig;

export const getUserTenants = (state: IState) => state.auth.tenants;

export const getUser = (state: IState) => state.auth.user;

export const environmentalDataType = (state: IState) => state.ownData.environmentalDataType;

export const getIsMapReady = (state: IState) => state.mapContent.mapIsReady;

export const getFeatureFlags = (state: IState) => state.auth.featureFlags;

export const getConfiguration = (state: IState) => state.auth.config;

export const getCoordSystems = (state: IState) => state.mapContent.coordinateSystems;

export const getSetups = (state: IState) => state.mapContent.setups;

export const getProject = (state: IState) => state.projectContent.project;

export const getPoints = (state: IState) => state.mapContent.points;

export const getDrawing = (state: IState) => state.mapContent.drawing;

export const getUploadPointsUrl = (state: IState) => state.mapContent.uploadPointsUrl;

export const getUploadedPoints = (state: IState) => state.mapContent.uploadedPoints

export const getExistingPointNames = (state: IState) => state.mapContent.existingPointNames

export const getSelectedMesh = (state: IState) => state.mapContent.selectedMesh

export const getFastWaveConfig = (state: IState) => state.mapContent.fastWaveConfig

export const getElevationName = (state: IState) => state.mapContent.elevationName

export const getBoundaryConditionDataset = (state: IState) => state.mapContent.boundaryConditionDataset

export const getExtractionInfoDataset = (state: IState) => state.mapContent.extractionInfoDataset

export const getSelectedBoundaryOrForcing = (state: IState) => state.mapContent.selectedBoundaryOrForcing

export const getInitialChartData = (state: IState) => state.mapContent.initialChartData

export const getDataLinkExtractionBuilderData = (state: IState) => state.mapContent.dataLinkExtractionBuilderData

export const getDataLinkProviders = (state: IState) => state.mapContent.dataLinkProviders

export const dataLinkOverlappingPeriod = (state: IState) => state.mapContent.dataLinkOverlappingPeriod

export const getExtractionPeriodStart = (state: IState) => state.mapContent.extractionPeriodStart

export const getExtractionPeriodEnd = (state: IState) => state.mapContent.extractionPeriodEnd

export const getSelectedEvents = (state: IState) => state.mapContent.selectedEvents

export const getEventsSelector = (state: IState) => state.mapContent.events

export const getEPSGCode = (state: IState) => state.mapContent.epsgCode

export const getCurrentProj4String = (state: IState) => state.mapContent.proj4String

export const getJobs = (state: IState) => state.job.jobs

export const getStoredJobs = (state: IState) => state.job.storedJobs

// export const getAbortCanCreateMeshCheck = (state: IState) => state.createMesh.abortCanCreateMeshCheck

export const getAbortDotMeshExportedCheck = (state: IState) => state.createMesh.abortDotMeshIsExportedCheck

// export const getCheckingCanCreateMesh = (state: IState) => state.createMesh.checkingCanCreateMesh

export const getLayerGroups = (state: IState) => state.legend.layerGroups

export const getBathymetryDatasets = (state: IState) => state.createMesh.bathymetryDatasets

export const getAOIS = (state: IState) => state.createMesh.meshAreasOfInterest

export const getShorelines = (state: IState) => state.createMesh.shorelines

export const getCreateAutoMeshPayload = (state: IState) => state.createMesh.createMeshPayload

export const getWSScenarios = (state: IState) => state.createMesh.scenarios

export const getWorkspcaceScenario = (state: IState) => state.createMesh.workspaceScenario

export const getMeshInputEntities = (state: IState) => state.createMesh.meshInputEntities

export const getDomain = (state: IState) => state.createMesh.meshOutline