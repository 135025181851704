import * as React from 'react';
import { ClassNameMap } from '@material-ui/styles/withStyles';
import classNames from 'classnames';
import { IMikeTheme } from '../../shared/mikeSharedTheme';
import {
  Typography,
  Breadcrumbs,
  Link,
  makeStyles,
  createStyles,
  Tooltip,
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { IGetProjectPath } from '../../model/IGetProject';

export type MikeProjectBreadcrumpsNavClassKey =
  | 'root'
  | 'mikeBreadcrumpsLinkLabel'
  | 'mikeBreadcrumpsLastLabel'
  | 'mikeBreadcrumpsEllipsis';

interface IProps {
  breadcrumpsContent: Array<IGetProjectPath>;
  classes?: Partial<ClassNameMap<MikeProjectBreadcrumpsNavClassKey>>;
  currentProjectName?: string;
  onClickCallBack: (project: IGetProjectPath) => void;
}

const useStyles = makeStyles((theme: IMikeTheme) => {
  return createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
      paddingTop: theme.spacing(1),
    },
    mikeBreadcrumpsLinkLabel: {
      cursor: 'pointer',
      color: theme.palette.text.primary,
      '&:hover': {
        color: theme.palette.text.primary,
      },
      '&:active': {
        color: theme.palette.text.primary,
      },
      '&:focus': {
        color: theme.palette.text.primary,
      },
    },
    mikeBreadcrumpsLastLabel: {
      fontWeight: 'bold',
    },
    mikeBreadcrumpsEllipsis: {
      maxWidth: '25vw',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',
    },
  });
});

/**
 * @name MikeProjectBreadcrumpsNav
 * @summary Renders a list of links. Meant to be used as breadcrumbs in the MikeProjectExplorer.
 */
export const MikeProjectBreadcrumpsNav = (props: IProps) => {
  const { breadcrumpsContent, classes = {}, currentProjectName, onClickCallBack, ...rest } = props;
  const className = useStyles(props);

  const handleClick = (project: IGetProjectPath) => (e: any) => {
    e.preventDefault();
    onClickCallBack(project);
  };

  return (
    <Breadcrumbs
      className={classNames(classes.root, className.root)}
      maxItems={3}
      separator={<NavigateNextIcon color="primary" fontSize="small" />}
      aria-label="mikebreadcrumb"
      {...rest}
    >
      {breadcrumpsContent.map((breadcrump: IGetProjectPath, index: number) => {
        return (
          <Tooltip key={breadcrump.id} title={breadcrump.name}>
            <Link
              key={index}
              className={classNames(
                classes.mikeBreadcrumpsLinkLabel,
                className.mikeBreadcrumpsLinkLabel,
                classes.mikeBreadcrumpsEllipsis,
                className.mikeBreadcrumpsEllipsis
              )}
              onClick={handleClick(breadcrump)}
            >
              <Typography
                className={classNames(
                  classes.mikeBreadcrumpsLinkLabel,
                  className.mikeBreadcrumpsLinkLabel,
                  classes.mikeBreadcrumpsEllipsis,
                  className.mikeBreadcrumpsEllipsis
                )}
                variant="body2"
              >
                {breadcrump.name}
              </Typography>
            </Link>
          </Tooltip>
        );
      })}
      {currentProjectName && (
        <Typography
          className={classNames(
            classes.mikeBreadcrumpsEllipsis,
            className.mikeBreadcrumpsEllipsis,
            classes.mikeBreadcrumpsLastLabel,
            className.mikeBreadcrumpsLastLabel
          )}
          variant="body2"
        >
          {currentProjectName}
        </Typography>
      )}
    </Breadcrumbs>
  );
};

export default MikeProjectBreadcrumpsNav;
