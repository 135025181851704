import React from 'react';

import RemoveIcon from '@material-ui/icons/Remove';
import Tooltip from '@material-ui/core/Tooltip';

import { ViewerToolsButtonStyle } from '../ViewerStyles';
import MikeVisualizer from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/MikeVisualizer';

const { zoomOut } = MikeVisualizer;

/**
 * @name ViewerToolZoomOut
 * @summary Allows zooming out.
 */
export const ViewerToolZoomOut = () => {
  return (
    <Tooltip title={'Zoom out'} placement="right">
      <button className={ViewerToolsButtonStyle} onClick={zoomOut}>
        <RemoveIcon />
      </button>
    </Tooltip>
  );
};

export default ViewerToolZoomOut