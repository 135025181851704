

import React, { useCallback, useEffect, useMemo } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { MikeButton } from '@mike/mike-shared-frontend';
import Typography from '@material-ui/core/Typography'
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MikeDialogTitle from '@mike/mike-shared-frontend/mike-project-explorer/DialogComponents/MikeDialogTitle';
import { css } from 'emotion'
import theme from '../../shared/mikeSharedTheme'
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../reducers';
import { showSuccessDialog } from '../../actions/mapContent';
import { useIntl } from 'react-intl';
import { publishDataToMOOD } from '../../actions/mood';
import { getJob } from '../../helpers/fastwave';
import { CSVEXTENSION, DFS0EXTESION, JOBS } from '../../shared/constants';
import { JOBS_IN_PROGRESS } from '../../model/IJobStatus';
import { IGetDataset } from '../../model/IGetDataset';
import { IJob } from '../../model/IFastWaveConfig';
import { downloadResults } from '../../actions/projectContent';
import { downloadButtonStyle, downloadTextStyle, orTextStyle } from '../Sidebar';
import { EVENT_COMPLETED, EVENT_COMPLETED_DESC, EVENT_DOWNLOAD, EVENT_DOWNLOAD_DESC, EVENT_PUBLISHED, EVENT_PUBLISHED_DESC } from '../../shared/matomoEvents';
import { IGetProject } from '../../model/IGetProject';

const titleStyle = css`
  padding: ${theme.spacing(1)}px;
  z-index: 11;
  border-radius: 4px 4px 0px 0px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
  height: ${theme.spacing(8)}px;
  color: ${theme.palette.background.paper};
  width: 100%;
`;

const firstLineStyle = css`
  display: flex;
  align-items: center;
  padding-top: ${theme.spacing(2)}px; 
  padding-bottom: ${theme.spacing(1)}px; 
`

const secondLineStyle = css`
  display: flex;
  align-items: center;  
  padding-bottom: ${theme.spacing(2)}px; 
`

const linkButtonStyle = css`
  &.MuiButton-root {
    padding: ${theme.spacing(0.5)}px; 
    height: 1.286rem;
  }
`;

const SuccessDialog = () => {
  const intl = useIntl() 
  const dispatch = useDispatch()

  const isOpen: boolean = useSelector(
    (state: IState) => state.mapContent.showSuccessDialog
  );   

  const project: IGetProject | null = useSelector(
    (state: IState) => state.projectContent.project
  );
  
  const publishingResultsToMOOD: boolean = useSelector(
    (state: IState) => state.mood.publishingResultsToMOOD
  ); 

  const hasModelResults: boolean = useSelector((state: IState) => state.projectContent.hasModelResults)

  const selectedMesh: IGetDataset  = useSelector(
    (state: IState) => state.mapContent.selectedMesh
  ); 

  const jobs: Array<IJob> = useSelector(
    (state: IState) => state.job.jobs
  ); 

  const runModelJob = getJob(jobs, JOBS.AUTOSETUP)
  const runModelJobStatus = runModelJob !== undefined && runModelJob.status ? runModelJob.status : ""
  const runModelJobIsRunning = runModelJobStatus && JOBS_IN_PROGRESS.includes(runModelJobStatus.toLowerCase()) 

  const canPublishModelResults = useMemo(() => {
    return hasModelResults && selectedMesh && !runModelJobIsRunning 
  }, [hasModelResults, runModelJobIsRunning, selectedMesh])

  const projectLabel = useMemo(() => {
    if (project && project.id){
      return "in project " + project.id
    }
    return ""
  }, [project])

  useEffect(() => {
    if (isOpen && projectLabel){
      window._paq.push(['trackEvent', EVENT_COMPLETED, EVENT_COMPLETED_DESC + projectLabel]);
    }
  }, [isOpen, projectLabel])
 

  const handleCancel = (_event?, reason?) => {
    if(!reason || (reason !== 'backdropClick' && reason !== 'escapeKeyDown')) {
      dispatch(showSuccessDialog(false))
    }
  }

  const publishToMood = useMemo(() => {
    const prefix = intl.formatMessage({id: 'successDialog.orModelResultsCanBePublished'})
    return publishingResultsToMOOD ? prefix  + " " +  intl.formatMessage({id: 'successDialog.toMOOD'}) : prefix
  }, [intl, publishingResultsToMOOD])

 
  const handlePublishDataToMOOD = useCallback(() => {
    window._paq.push(['trackEvent', EVENT_PUBLISHED, EVENT_PUBLISHED_DESC + projectLabel]);
    dispatch(publishDataToMOOD())
  }, [dispatch, projectLabel])

  const handleDownloadResults = useCallback((fileExtension: string) => {
    window._paq.push(['trackEvent', EVENT_DOWNLOAD, EVENT_DOWNLOAD_DESC + projectLabel]);
    dispatch(downloadResults(fileExtension))
  }, [dispatch, projectLabel])

  return (
    <Dialog maxWidth={'sm'} onClose={handleCancel} open={isOpen} >
      <MuiDialogTitle className={titleStyle}>
        <MikeDialogTitle title={intl.formatMessage({id: 'successDialog.dataReady'})} onClose={handleCancel} />
      </MuiDialogTitle>
      <MuiDialogContent >        
        <div className={firstLineStyle}>
          <Typography className={downloadTextStyle} variant="body2">{intl.formatMessage({id: 'successDialog.dataCanBeDownloaded'})}</Typography>
          <MikeButton className={downloadButtonStyle} buttontype="text" onClick={() => handleDownloadResults(CSVEXTENSION)} disabled={!canPublishModelResults}>
            {intl.formatMessage({id: 'sideBar.asCSV'})}                  
          </MikeButton> 
          <Typography className={orTextStyle} variant="body2">{intl.formatMessage({id: 'sideBar.or'})}</Typography> 
          <MikeButton className={downloadButtonStyle} buttontype="text" onClick={() => handleDownloadResults(DFS0EXTESION)} disabled={!canPublishModelResults}>
            {intl.formatMessage({id: 'sideBar.asDFS0'})}                  
          </MikeButton>     
        </div>
        <div className={secondLineStyle}>
          <Typography variant="body2">{publishToMood}</Typography>            
          <MikeButton className={linkButtonStyle} buttontype="text" onClick={handlePublishDataToMOOD} active={publishingResultsToMOOD} disabled={!canPublishModelResults}>
            {intl.formatMessage({id: 'successDialog.toMOOD'})}
          </MikeButton>            
        </div>      
    </MuiDialogContent>
  </Dialog>
  );
};

export default SuccessDialog;