import { dataLink } from './support'

export const getDataLinkMeshStatus = async (projectId: string) => {
  return dataLink.getJson(`Mesh/ListMeshStatus?projectGuid=${projectId}`, "1")
}

export const uploadMeshToDLStorage = (projectId: string, meshNames: Array<string>) => {
  return dataLink.postJson(`Mesh/ImportPlatformMeshes?projectGuid=${projectId}&sourceProjectGuid=${projectId}`, meshNames, "1")
}

export const getExtractionBuilderJobs = (jobIds: Array<string>) => {
  return dataLink.postJson(`ExtractionBuilder/Jobs`, jobIds, "1")
}

export const getExtractionBuilderData = (projectId: string, meshName: string) => {
  return dataLink.getJson(`ExtractionBuilder/GetExtractionBuilderData?projectGuid=${projectId}&meshName=${meshName}&modelType=MIKE21SW`, "1")
}

export const startExtraction = (projectId: string, meshName: string, startDate: string, endDate: string, body: any) => {
  return dataLink.postJson(`ExtractionBuilder/StartExtraction?projectGuid=${projectId}&destinationProjectGuid=${projectId}&meshName=${meshName}&startDate=${startDate}&endDate=${endDate}&initConditionsDate=2022-07-05T00:00:00.000Z&saveForcingsInNativeGridResolution=true&extrapolateSpatialGaps=false&modelType=MIKE21SW`, body, "1")
}

export const deleteMeshFromDLStorage = (projectId: string, meshName: string) => {
  dataLink.postJson(`Mesh/DeleteMeshes?projectGuid=${projectId}`, [meshName], "1")
}

export const cancelExtraction = (jobId: string) => {
  return dataLink.postJson(`ExtractionBuilder/CancelExtraction?jobGuid=${jobId}`, {}, "1")
}
