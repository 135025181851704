import { IFastWaveConfig, IJob } from "../model/IFastWaveConfig";
import  ActionType  from "./ActionType";

export const setJobStatus = (jobId: string, jobIndex: string, jobStatus: string, start: string) => ({ 
  type: ActionType.SET_JOB_STATUS, 
  data: {jobId: jobId, jobIndex: jobIndex, jobStatus: jobStatus, start: start} 
})

export const setJobId = (jobIndex: string ,jobId: string, start: string, jobName: string, projectId: string) => ({ 
  type: ActionType.SET_JOB, 
  data: {jobId, jobIndex, start, jobName, projectId}
})

export const createContainer = (jobIndex: string, configToBeUpdated?: IFastWaveConfig | null, skipCheckIfContainerNeedsToRerun = true) => ({ 
  type: ActionType.CREATE_JOB,
  data: {jobIndex: jobIndex, configToBeUpdated: configToBeUpdated, skipCheckIfContainerNeedsToRerun: skipCheckIfContainerNeedsToRerun}
})

export const cancelJobs = (jobIds: Array<string>) => ({
  type: ActionType.CANCEL_JOBS,
  data: jobIds
})

export const cancelJob = (jobId: string) => ({
  type: ActionType.CANCEL_JOB,
  data: jobId
})

export const setDataLinkJobStatus = (jobId: string, jobIndex: string, jobStatus: string, start: string, status: any) => ({ 
  type: ActionType.SET_DATA_LINK_JOB_STATUS, 
  data: {jobId: jobId, jobIndex: jobIndex, jobStatus: jobStatus, start: start, status: status} 
})

export const setDataLinkJobId = (jobIndex: string ,jobId: string, start: string, jobName, projectId: string) => ({ 
  type: ActionType.SET_DATA_LINK_JOB, 
  data: {jobId, jobIndex, start, jobName, projectId}
})

export const jobsGet = (projectId: string) => ({
  type: ActionType.JOBS_GET,
  data: projectId
})
 
export const jobsSet = (jobs: Array<IJob>, projectId: string) => ({
  type: ActionType.JOBS_SET,
  data: {jobs, projectId}
})

export const jobsSave = (jobs: Array<IJob>) => ({
  type: ActionType.JOBS_SAVE,
  data: jobs
})