import { applyMiddleware, createStore, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import appReducer from "./reducers";
import bootstrap from "./sagas/bootstrap";
import rootSaga from "./sagas";

const composeEnhancer: typeof compose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(appReducer,    
    composeEnhancer(
    applyMiddleware(  
    sagaMiddleware)));
  sagaMiddleware.run(rootSaga);
  sagaMiddleware.run(bootstrap);

  const storeConfig = {
    store
  };
  
  export default storeConfig;
