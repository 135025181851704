import React, { useEffect, useCallback, useState, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';
import { IState } from '../../reducers'
import { IGetProject, IGetProjectPath } from '../../model/IGetProject';
import FolderTable from './FolderTable';
import { useNavigate, useParams } from 'react-router-dom'
import { getFolders, setFoldersPagination, setFoldersRowsPerPage, setFoldersNamePrefix, setFoldersSort, setCreateFolderPanelOpen, deleteFolders } from '../../actions/folders'
import SearchInput from '../SearchInput'
import { IMikeTheme } from '@mike/mike-shared-frontend/mike-shared-styles/mikeSharedTheme';
import { Link } from 'react-router-dom'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { MikeButton, MikeSlidingPanel } from '@mike/mike-shared-frontend';
import CreateFolderForm from '../CreateFolderForm';
import { useIntl } from 'react-intl';
import { ReactComponent as PlayFilled } from "@mike/mike-shared-frontend/media/icons/PlayFilled";
import { ReactComponent as Plus } from "@mike/mike-shared-frontend/media/icons/Plus";
import { iconSecondaryStyle, iconWhiteStyle } from '../EditPointsForm/iconStyles';
import { mikePalette } from '../../shared/mikeSharedTheme';
import MikeDialog from '../DialogComponents/MikeDialog';

const useStyles = makeStyles((theme: IMikeTheme) =>
  createStyles({ 
    appBarSpacer: {
      height: theme.spacing(4),
      backgroundColor: theme.palette.lightGrey && theme.palette.lightGrey.main
    },
    tableTopHeader: { 
      position: 'sticky',
      top: 0, //theme.spacing(4),    
      zIndex: 1,
      backgroundColor: theme.palette.lightGrey && theme.palette.lightGrey.main,
    },
    title: {
      paddingLeft: theme.spacing(4),
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(2)
    },
    flex: {    
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',  
      width: '99vw',   
      paddingLeft: theme.spacing(4),
      paddingBottom: theme.spacing(5),
      paddingRight: theme.spacing(4),
    },
    button: {     
      border: '2px solid ' + mikePalette.secondary.main,
      color: mikePalette.background.default,
      '&[disabled]': {
        color: mikePalette.background.paper,
        opacity: 0.5,
        cursor: 'not-allowed',
        border: '2px solid ' + mikePalette.secondary.light,
      }
    },
    buttonPadding: {
      paddingLeft: theme.spacing(1)
    },
    link: {
      textDecoration: 'none',
      display: 'content'
    },
    buttonsContainer: {      
      display: 'flex',      
    }   
  })
)

const Folders = () => {   
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const intl = useIntl();

  useEffect(() => {
    if (id){
      dispatch(setFoldersNamePrefix('')) 
      dispatch(getFolders(id, 0, 25, 'createdAt', 'desc', ''))
    }
   }, [dispatch, id]);

   const projects: IGetProject[] = useSelector(
    (state: IState) => state.folders.folders
  ); 
  
  const sortBy: string = useSelector(
    (state: IState) => state.folders.sortBy
  );  

  const sortOrder: 'asc' | 'desc' = useSelector(
    (state: IState) => state.folders.sortOrder
  );  

  const rowsPerPage: number = useSelector(
    (state: IState) => state.folders.rowsPerPage
  );

  const namePrefix: string = useSelector(
    (state: IState) => state.folders.namePrefix
  );

  const loading: boolean = useSelector(
    (state: IState) => state.folders.loading
  ); 

  const page: number = useSelector(
    (state: IState) => state.folders.page
  ); 

  const totalCount: number = useSelector(
    (state: IState) => state.folders.totalCount
  ); 

  const createFolderPanelIsOpen: boolean = useSelector(
    (state: IState) => state.folders.createFolderPanelIsOpen
  ); 

  const projectPath: Array<IGetProjectPath> = useSelector(
    (state: IState) => state.folders.projectPath
  ); 

  const canEditFolder = useMemo(() => {
    if (projectPath && projectPath.length > 0){
      const itemInPath = projectPath.find((path: IGetProjectPath) => path.id === id)
      if (itemInPath !== undefined){
        if (!itemInPath.isDeleted && itemInPath.capabilities && itemInPath.capabilities.canUpdateContent){
          return true
        }
      }
    }
    return false
  }, [projectPath, id])
  
  const handleSelect = useCallback((selectedProject: IGetProject) => {
    navigate('/project/' + selectedProject.id);
  }, [navigate])

  const handleRequestSort = useCallback((orderBy: string | ((item: any) => string | number), order: 'asc' | 'desc') => {   
    dispatch(setFoldersSort(orderBy.toString(), order)) 
    dispatch(setFoldersPagination(0))   
  }, [dispatch])

  const handleChangePage = useCallback((newPage: number) => { 
     dispatch(setFoldersPagination(newPage))
  }, [dispatch])

  const handleChangeRowsPerPage = useCallback((newRowsPerPage: number) => {  
    dispatch(setFoldersRowsPerPage(newRowsPerPage))
  }, [dispatch])

  const [topOffset, setTopOffset] = useState(0)
  const measuredRef = useCallback(node => {
    if (node !== null) {
      setTopOffset(node.getBoundingClientRect().height)
    }
  }, [])

  const [selectedRows, setSelectedRows] = useState(new Array<IGetProject>());
  const [projectsToBeDeleted, setProjectsToBeDeleted] = useState(new Array<IGetProject>());
  const [projectToBeEdited, setProjectToBeEdited] = useState<IGetProject | null>(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const onEditProject =  useCallback((editProject: IGetProject) => {   
    if (editProject.capabilities.canEdit) {
      setProjectToBeEdited(editProject)
      dispatch(setCreateFolderPanelOpen(true))   
    }
  }, [dispatch])
  
  const onDeleteOneProject = React.useCallback(
    (deleteProject: IGetProject) => {
      if (deleteProject.capabilities.canDelete) {
        setProjectsToBeDeleted([deleteProject])
        setDeleteConfirmationOpen(true)
      }
    },
    [setDeleteConfirmationOpen, setProjectsToBeDeleted]
  )

  const onCancelDeleteProjects = () => {
    setProjectsToBeDeleted(new Array<IGetProject>())
    setDeleteConfirmationOpen(false)
  }

  const onOkDeleteProjects = React.useCallback(() => {
    const ids = projectsToBeDeleted.filter((project: IGetProject) => project.capabilities.canDelete).map((project: IGetProject) => project.id)
    dispatch(deleteFolders(ids))
    setProjectsToBeDeleted(new Array<IGetProject>())
    setDeleteConfirmationOpen(false)
  }, [dispatch, projectsToBeDeleted])

  const onSelectionChange = (selectedItems: Array<IGetProject>) => {
    setSelectedRows(selectedItems)
  };

  const handleSetFilter = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {   
    dispatch(setFoldersNamePrefix(e.target.value))     
    dispatch(setFoldersPagination(0))
  }, [dispatch]);

  const handleClearFilter = useCallback(() => { 
    dispatch(setFoldersNamePrefix('')) 
    dispatch(setFoldersPagination(0))
  }, [dispatch]);

  const onShowCreateFolder = useCallback((show?: boolean) => { 
    setProjectToBeEdited(null)
    dispatch(setCreateFolderPanelOpen(show))    
  }, [dispatch]);

  return (
   <>
    <div className={classes.tableTopHeader} ref={measuredRef}>
      <div className={classes.appBarSpacer} />
      <Typography  className={classes.title} variant={'h1'}>{"Subfolders"}</Typography>
      <div className={classes.flex}>
        <SearchInput        
          value={namePrefix}
          onChangeValue={handleSetFilter}
          onResetValue={handleClearFilter}
        /> 
        <div className={classes.buttonsContainer}>          
          <Link to={canEditFolder ? `/project/${id}/initialselection` : ""} className={classes.link}>
            <MikeButton className={classes.button} disabled={!canEditFolder}>
              <PlayFilled className={iconWhiteStyle(!canEditFolder)}/>
              {intl.formatMessage({ id: 'app.goToFWE'})}
            </MikeButton>  
          </Link>
          <div className={classes.buttonPadding}>
            <MikeButton buttontype="secondary" disabled={!canEditFolder} onClick={() => onShowCreateFolder()}>
              <Plus className={iconSecondaryStyle(!canEditFolder)} />
              {intl.formatMessage({ id: 'createFolder.title'})}
            </MikeButton>
          </div> 
        </div>       
      </div>  
    </div>
    <FolderTable     
      data={projects}
      loading={loading}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      onItemClick={handleSelect}
      onHandleRequestSort={handleRequestSort}
      order={sortOrder}
      orderBy={sortBy}
      page={page}
      rowsPerPage={rowsPerPage}
      topOffset={topOffset}
      selectable={false}
      totalCount={totalCount}
      pagination={totalCount > 10}
      notSortableColumns={["description"]}
      onSelectionChange={onSelectionChange}
      selectedItems={selectedRows}
      onDeleteOneProject={onDeleteOneProject}
      onEdit={onEditProject}
    />
    <MikeDialog 
      open={deleteConfirmationOpen} 
      onCancel={onCancelDeleteProjects} 
      onOk={onOkDeleteProjects}
      dialogTitle={intl.formatMessage({id: 'warnings.pleaseConfirm'})}
      contentTitle={intl.formatMessage({id: projectsToBeDeleted.length === 1 ? 'project.deleteOneConfirmation' : 'project.deleteManyConfirmation'})}
      message={''}    
      okButtonLabel={intl.formatMessage({id: 'project.delete'})}
    />
    <MikeSlidingPanel
      position="right"
      isOpen={createFolderPanelIsOpen}
      onClose={() => onShowCreateFolder(false)}
      titleArea={intl.formatMessage({id: projectToBeEdited ? 'editFolder.title' : 'createFolder.title'})}
      contentArea={<CreateFolderForm project={projectToBeEdited}/>}
      actionsArea={null}
      noGrayOverlay={false}
    />
  </>
  )
}

export default Folders