import React, { useCallback, useMemo } from 'react'
import { Formik, Form, } from 'formik'
import TextField from '../formik/TextField'
import * as Yup from "yup";
import { useIntl } from 'react-intl'
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../reducers';
import { IFastWaveConfig, IJob } from '../../model/IFastWaveConfig';
import { css } from 'emotion'
import theme from '../../shared/mikeSharedTheme'
import MikeButton from '../mike-button/MikeButton';
import { getJob } from '../../helpers/fastwave';
import { createContainer } from '../../actions/job';
import { DATALINKJOBS, JOBS, MAX_RESULTING_DIMENSIONS, MAX_SELECTED_NODES, MIN_RESULTING_DIMENSIONS, MIN_SELECTED_NODES } from '../../shared/constants';
import { DATA_LINK_JOBS_IN_PROGRESS, JOBS_IN_PROGRESS } from '../../model/IJobStatus';
import ContextHelp from '../ContextHelp';
import { addError } from '../../actions/errors';
import { IGetProject } from '../../model/IGetProject';

const containerStyle = css`
  padding:${theme.spacing(2)}px;  
`
const sliderContainerStyle = css`
  margin-right:${theme.spacing(2)}px;
  display: flex;   
  align-items: center;
`
const rightSpacingLabelStyle = css`
  padding-right:${theme.spacing(2)}px;  
  white-space: nowrap;
`
const leftSpacingLabelStyle = css`
  white-space: nowrap;   
  padding-left:${theme.spacing(0.5)}px;  
`
const inputContainerStyle = css`
  padding-right:${theme.spacing(2)}px; 
  display: flex; 
`
const lastRowContainerStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: start;
`

const resultingDimensionsStyle = css`  
  display: flex;  
  margin-right:${theme.spacing(2)}px;
  width: ${theme.spacing(46.25)}px; 
`

const fixWidthStyle = css`  
  width: ${theme.spacing(46.25)}px; 
`

const appyButtonStyle = css`  
  padding-top: ${theme.spacing(1)}px;  
`

const placHolder = css`
  height: ${theme.spacing(3)}px; 
`

export interface IValue {
  noModelRuns: string; 
  desiredDimensions: string;
}

interface IProps {
  initialValues: IValue;
  noEvents: number;
  resultingDimensions: number;
  totalExplainedVarianceRatio: number;
}

const validationNoTestRuns = "Allowed are " + MIN_SELECTED_NODES + "-" + MAX_SELECTED_NODES
const validationDesiredDimensions = "Allowed are " +  MIN_RESULTING_DIMENSIONS + "-" +  MAX_RESULTING_DIMENSIONS
const validationSchema = Yup.object().shape({ 
  noModelRuns: Yup.number().typeError(validationNoTestRuns).required(validationNoTestRuns).min(MIN_SELECTED_NODES, validationNoTestRuns).required(validationNoTestRuns).max(MAX_SELECTED_NODES, validationNoTestRuns),
  desiredDimensions: Yup.number().typeError(validationDesiredDimensions).required(validationDesiredDimensions).min(MIN_RESULTING_DIMENSIONS, validationDesiredDimensions).required(validationDesiredDimensions).max(MAX_RESULTING_DIMENSIONS, validationDesiredDimensions),
});

const AdvancedSettingsForm = (props: IProps) => { 
  const {resultingDimensions, totalExplainedVarianceRatio, initialValues} = props;
  const intl = useIntl();
  const dispatch = useDispatch();

  const fastWaveConfig: IFastWaveConfig = useSelector(
    (state: IState) => state.mapContent.fastWaveConfig
  );  

  const jobs: Array<IJob>  = useSelector(
    (state: IState) => state.job.jobs
  ); 

  const project: IGetProject | null = useSelector(
    (state: IState) => state.projectContent.project
  );

  const job = getJob(jobs, JOBS.MDA) 

  const isProcssing = job !== undefined && JOBS_IN_PROGRESS.includes(job.status.toLowerCase())

  const canApplySettings = useMemo(() => {
    const canCreateContent = project && project.capabilities && project.capabilities.canCreateContent
    const dataLinkJob = getJob(jobs, DATALINKJOBS.DATAEXTRACTION)
    const jobStatus = dataLinkJob !== undefined && dataLinkJob.status ? dataLinkJob.status : ""  
    const dataExtractionJobIsRunning = DATA_LINK_JOBS_IN_PROGRESS.includes(jobStatus.toLowerCase());
    return dataExtractionJobIsRunning ? false : canCreateContent && fastWaveConfig && fastWaveConfig.data_link_output_file && fastWaveConfig.data_link_output_file.dataset_id ? true : false
  } , [project, fastWaveConfig, jobs])

  const onSubmit = useCallback((values: IValue) => { 
    const noOfEvents = parseInt(values.noModelRuns)
    const desiredDimens  = parseInt(values.desiredDimensions)
    if (desiredDimens >= noOfEvents){
      dispatch(addError(intl.formatMessage({id: 'advancedSettings.error'})))
    }
    else{
      dispatch(createContainer(JOBS.MDA, {...fastWaveConfig, no_events: noOfEvents, n_pca_components: desiredDimens }))
    }  
  }, [dispatch, fastWaveConfig, intl])

  return (
    <div className={containerStyle}>       
      <Formik
        validateOnMount
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize 
        initialTouched={{ 
          desiredDimensions: true,
          noModelRuns: true
        }}    
        onSubmit={onSubmit}
        render={formikProps => {
          const { handleChange, setFieldTouched, handleSubmit, isValid } = formikProps
          const handleOnChange = (name, e) => {
            e.persist()
            handleChange(e)
            setFieldTouched(name, true, false)              
          } 

          return (
            <Form> 
              <div className={sliderContainerStyle}> 
                <div className={fixWidthStyle}>              
                  <Typography className={rightSpacingLabelStyle} variant="body2">{intl.formatMessage({id: 'setup.desiredDimensionsUsedInPrincipalComponentAnalysis'})}</Typography>         
                 
                </div>
                <div className={inputContainerStyle}>     
                  <TextField
                    onChange={handleOnChange.bind(null, 'name')}
                    name='desiredDimensions'        
                    label={intl.formatMessage({id: 'setup.desiredDimensions'})}                                        
                    addPlaceHolder={true}
                  /> 
                  <ContextHelp helpTexts={[intl.formatMessage({id: 'advancedSettings.desiredDimensionHelpText'})]}/>                      
                </div>   
              </div>
              <div className={resultingDimensionsStyle}> 
                <Typography className={rightSpacingLabelStyle} variant="body2">{intl.formatMessage({id: 'setup.resultingDimensions'}) + " " + resultingDimensions}</Typography>
                {totalExplainedVarianceRatio !== 0 && <Typography className={leftSpacingLabelStyle} variant="body2">{intl.formatMessage({id: 'setup.variance'}) + " " + totalExplainedVarianceRatio * 100 + " %"}</Typography>}            
              </div>
             
              <div className={placHolder} />  
              <div className={lastRowContainerStyle}>
                <div className={sliderContainerStyle}>
                  <div className={fixWidthStyle}>
                    <Typography className={rightSpacingLabelStyle} variant="body2">{intl.formatMessage({id: 'setup.maxDissimilarity'})}</Typography>        
                  </div>
                  <div className={inputContainerStyle}>             
                    <TextField                   
                      onChange={handleOnChange.bind(null, 'name')}
                      name='noModelRuns'        
                      label={intl.formatMessage({
                        id: 'setup.noModelRun'
                      })}                                        
                      addPlaceHolder={true}                     
                    />
                    <ContextHelp helpTexts={[
                      intl.formatMessage({id: 'advancedSettings.maxDissimilarityHelpText1'}),
                      intl.formatMessage({id: 'advancedSettings.maxDissimilarityHelpText2'}),
                      intl.formatMessage({id: 'advancedSettings.maxDissimilarityHelpText3'}),
                      intl.formatMessage({id: 'advancedSettings.maxDissimilarityHelpText4'}),
                      intl.formatMessage({id: 'advancedSettings.maxDissimilarityHelpText5'})
                    ]}/>                 
                  </div> 
                </div> 
                <div className={appyButtonStyle}>
                  <MikeButton  disabled={!canApplySettings || !isValid} active={isProcssing} id="nextButton" buttontype="secondary" onClick={() => handleSubmit()}>
                    {intl.formatMessage({id: 'setup.applySettings'})}               
                  </MikeButton> 
                </div>
              </div>
              </Form>
              )
            }}
          />
    </div>
  )
}

export default AdvancedSettingsForm