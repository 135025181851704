import React, { useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useIntl } from 'react-intl';
import { iconSecondaryStyle } from '../EditPointsForm/iconStyles';
import { ReactComponent as Upload } from "@mike/mike-shared-frontend/media/icons/Upload";
import { ReactComponent as Computer } from "@mike/mike-shared-frontend/media/icons/Computer";
import { ReactComponent as Cloud } from "@mike/mike-shared-frontend/media/icons/Cloud";
import { css } from 'emotion'
import theme from '../../shared/mikeSharedTheme'
import { useDropzone } from 'react-dropzone';
import ContextHelp from '../ContextHelp';

interface IProps {
  entityType: string;
  disabled?: boolean; 
  onUploadFromComputer: (files,entityType: string) => void;
  onUploadFromCLoud: (entityType: string) => void;
}

const buttonStyle = css`  
  &.MuiButtonBase-root {  
    justify-content: start; 
  }
`
const menuItemStyle = css`
  &.MuiMenuItem-root {
    height: ${theme.spacing(5)}px;
    justify-content: space-between; 
  }
`  
const buttonLabelStyle = css`  
 padding-left: ${theme.spacing(1)}px;
`

const MeshUpload = (props: IProps) => {
  const intl = useIntl();
  const {entityType, disabled, onUploadFromComputer, onUploadFromCLoud} = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUploadFromCloud = useCallback(() => {
    onUploadFromCLoud(entityType)
    setAnchorEl(null);
  }, [entityType, onUploadFromCLoud]);

  const handleUploadFromComputer = useCallback((files) => {
    onUploadFromComputer(files,entityType)
    setAnchorEl(null);
  }, [entityType, onUploadFromComputer]);

  const { getInputProps, getRootProps  } = useDropzone({ onDrop: handleUploadFromComputer, accept: '.zip' });  

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="text"
        color="secondary"
        onClick={handleClick}
        disabled={disabled}
      >
        <Upload className={iconSecondaryStyle(disabled)} width={24} height={24} viewBox={"0 0 40 40"}/>
        {intl.formatMessage({id: 'autoMesh.upload'})}
      </Button>
      <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem className={menuItemStyle}>
          <Button className={buttonStyle} color='primary' variant='text' onClick={handleUploadFromCloud}>
            <Cloud/>
            <div className={buttonLabelStyle}>{intl.formatMessage({id: 'platform.cloud'})}</div>
          </Button> 
          <ContextHelp primary helpTexts={[intl.formatMessage({id: 'autoMesh.copyFromPlatform_hint'})]}/> 
        </MenuItem>
        <MenuItem className={menuItemStyle}>
          <Button className={buttonStyle} color='primary' variant={'text'} {...getRootProps() as any}>
            <Computer/>
            <div className={buttonLabelStyle}>{intl.formatMessage({id: 'desktop.desktop'})}</div>
            <input {...getInputProps()}/>
          </Button>
          <ContextHelp primary helpTexts={[intl.formatMessage({id: 'autoMesh.uploadShapefile_hint'})]}/>   
        </MenuItem>         
      </Menu>
    </div>
  );
}

export default MeshUpload;