import React from 'react';
import MoreVert from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { MIKE_COLORS } from '@mike/mike-shared-frontend/mike-shared-styles/mike-colors';

export interface IAction {
  name: string;
  callBack: (row: object, name: string) => void;
  capabilities?: object;
  disableGutters?: boolean;
  icon?: React.ReactNode;
  render?: (row: object, capabilities?: object) => void;
}

interface IProps {
  row: object;
  actions: Array<IAction>;
  actionsPopupStateChange?: (open: boolean, row: object) => void;
}

const Actions = (props: IProps) => {
  const { row, actions, actionsPopupStateChange } = props;

  return (
    <PopupState variant="popover" popupId="action-popup-menu">
      {(popupState) => {
        actionsPopupStateChange && actionsPopupStateChange(popupState.isOpen, row)
        return (
        <React.Fragment>
          <MoreVert {...bindTrigger(popupState)} />
          <Menu
            {...bindMenu(popupState)}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            MenuListProps={{
              style: {
                padding: 0,
                backgroundColor: MIKE_COLORS.XLIGHTGREY,
              },
            }}
          >
            {actions.map((a, index) => {
              return (
                <MenuItem
                  key={index}
                  onClick={(e) => {
                    e.preventDefault();
                    popupState.close();
                    a.callBack(row, a.name);
                  }}
                  disableGutters={a.disableGutters}
                >
                  {a.icon ? a.icon : ''}
                  {a.render ? a.render(row, a.capabilities) : a.name}
                </MenuItem>
              );
            })}
          </Menu>
        </React.Fragment>
      )
      }}
    </PopupState>
  );
};

export default Actions;
