import React, { useMemo } from 'react';
import { css } from 'emotion';
import Mouse from '@material-ui/icons/Mouse';
import theme from '../../shared/mikeSharedTheme'
import MIKE_COLORS from '@mike/mike-shared-frontend/mike-shared-styles/mike-colors';
import { Typography } from '@material-ui/core';
import ContextHelp from '../ContextHelp';
import { intl } from '../..';
import { ReactComponent as Draw } from "@mike/mike-shared-frontend/media/icons/Draw";
import { ReactComponent as TableResults } from "@mike/mike-shared-frontend/media/icons/TableResults"
import { ReactComponent as Geometry } from "@mike/mike-shared-frontend/media/icons/Geometry"

export enum EGeometryItemTypes {
  POINT = 'Point',
  MULTI_POINT = 'MultiPoint',
  POLYGON = 'Polygon',
  MULTI_POLYGON = 'MultiPolygon',
  LINE_STRING = 'LineString',
  MULTI_LINE_STRING = 'MultiLineString',
}

export const GEOMETRY_ITEM_TYPES = {
  POINT: EGeometryItemTypes.POINT,
  MULTI_POINT: EGeometryItemTypes.MULTI_POINT,
  POLYGON: EGeometryItemTypes.POLYGON,
  MULTI_POLYGON: EGeometryItemTypes.MULTI_POLYGON,
  LINE_STRING: EGeometryItemTypes.LINE_STRING,
  MULTI_LINE_STRING: EGeometryItemTypes.MULTI_LINE_STRING,
};

const contentContainerStyle = css`
  min-width: ${theme.spacing(50)}px;
`;

const HelpKeyStyle = css`
  font-family: 'Courier New', Courier, monospace;
  font-size: 0.8em;
  background: ${MIKE_COLORS.MEDIUMGREY_LIGHT};
  border-radius: 5px;
`;

const HelpKeyPlusStyle = css`
  margin: ${theme.spacing(1)}px;
`;

const HelpKeyOnStyle = css`
  margin-left: ${theme.spacing(1)}px;
`;

const HelpKeyContainer = css`
  display: flex;
  align-items: center;
  min-width: ${theme.spacing(15)}px;
`;

const KeyboardShortcutDescriptionStyle = css`
  padding: ${theme.spacing(1)}px;
`;

const KeyboardShortcutItemStyle = css`
  display: flex;
  align-items: center;
`;

const getAddShortcuts = (geometry: EGeometryItemTypes) => {
  if (!geometry) {
    return [];
  }
  switch (geometry) {
    case EGeometryItemTypes.POINT:
    case EGeometryItemTypes.MULTI_POINT: {
      const items = [
        {
          id: 'PANEL_CLICK',
          description: intl.formatMessage({id: 'draw.PANEL_CLICK'}),  
          Icons: (
            <div className={HelpKeyContainer}>
              <Mouse fontSize="small" />
              <span className={HelpKeyOnStyle}>on</span>
              <TableResults width={30} height={30} viewBox={"0 0 40 40"}/>
            </div>
          ),        
        },        
        {
          id: 'KEY_MOUSE_LEFT_PRESS_MODIFY_DRAWING_POINT',
          description: intl.formatMessage({id: 'draw.KEY_MOUSE_LEFT_PRESS_MODIFY_DRAWING_POINT'}),
          Icons: (
            <div className={HelpKeyContainer}>
              <Mouse fontSize="small" />
              <span className={HelpKeyOnStyle}>on</span>
              <Geometry width={30} height={30} viewBox={"0 0 40 40"}/>
            </div>
          ),
        },  
        {
          id: 'KEY_MOUSE_SHIFT_LEFT_PRESS_DRAW',
          description: intl.formatMessage({id: 'draw.KEY_MOUSE_SHIFT_LEFT_PRESS_DRAW'}),
          Icons: (
            <div className={HelpKeyContainer}>
              <span className={HelpKeyStyle}>{intl.formatMessage({id: 'draw.SHIFT'})}</span>
              <span className={HelpKeyPlusStyle}>+</span>
              <Mouse fontSize="small" />
            </div>
          ),
        },
        {
          id: 'KEY_SCROLL',
          description: intl.formatMessage({id: 'draw.KEY_SCROLL'}),
          Icons: (
            <div className={HelpKeyContainer}>
              <span className={HelpKeyStyle}>{intl.formatMessage({id: 'draw.SCROLL'})}</span>
            </div>
          ),
        },
      ];
      return items;
    }
    case EGeometryItemTypes.LINE_STRING:
    case EGeometryItemTypes.MULTI_LINE_STRING:
    case EGeometryItemTypes.POLYGON:
    case EGeometryItemTypes.MULTI_POLYGON: {
      const items = [
        {
          id: 'DRAW_BUTTON_CLICK',
          description: intl.formatMessage({id: 'draw.DRAW_BUTTON_CLICK'}),
          Icons: (
            <div className={HelpKeyContainer}>
              <Draw width={24} height={24} viewBox={"0 0 40 40"} />
              <span className={HelpKeyStyle}>{intl.formatMessage({id: 'draw.onMap'})}</span>
            </div>
          ),
        },
        {
          id: 'KEY_MOUSE_LEFT_PRESS_DRAW',
          description: intl.formatMessage({id: 'draw.KEY_MOUSE_LEFT_PRESS_DRAW'}),
          Icons: (
            <div className={HelpKeyContainer}>
              <Mouse fontSize="small" />
              <span className={HelpKeyOnStyle}>on</span>
              <Geometry width={30} height={30} viewBox={"0 0 40 40"}/>
            </div>
          ),
        },

        {
          id: 'KEY_MOUSE_DOUBLE_LEFT_PRESS_DRAW',
          description: intl.formatMessage({id: 'draw.KEY_MOUSE_DOUBLE_LEFT_PRESS_DRAW'}),
          Icons: (
            <>
              <div className={HelpKeyContainer}>
                <Mouse fontSize="small" />
                <span className={HelpKeyPlusStyle}>+</span>
                <Mouse fontSize="small" />
              </div>
            </>
          ),
        },
        {
          id: 'KEY_SPACE',
          description: intl.formatMessage({id: 'draw.KEY_SPACE_DRAW'}),
          Icons: (
            <div className={HelpKeyContainer}>
              <span className={HelpKeyStyle}>{intl.formatMessage({id: 'draw.KEY_SPACE'})}</span>
            </div>
          ),
        },

        // while drawing
        {
          id: 'KEY_BACKSPACE',
          description: intl.formatMessage({id: 'draw.KEY_BACKSPACE_DRAW'}),
          Icons: (
            <div className={HelpKeyContainer}>
              <span className={HelpKeyStyle}>{intl.formatMessage({id: 'draw.KEY_BACKSPACE'})}</span>
            </div>
          ),
        },
        {
          id: 'KEY_ESC',
          description: intl.formatMessage({id: 'draw.KEY_ESC_DRAW'}),
          Icons: (
            <div className={HelpKeyContainer}>
              <span className={HelpKeyStyle}>{intl.formatMessage({id: 'draw.KEY_ESC'})}</span>
            </div>
          ),
        },

        {
          id: 'KEY_MOUSE_SHIFT_LEFT_PRESS_DRAW',
          description: intl.formatMessage({id: 'draw.KEY_MOUSE_SHIFT_LEFT_PRESS_DRAW'}),
          Icons: (
            <div className={HelpKeyContainer}>
              <span className={HelpKeyStyle}>{intl.formatMessage({id: 'draw.SHIFT'})}</span>
              <span className={HelpKeyPlusStyle}>+</span>
              <Mouse fontSize="small" />
            </div>
          ),
        },
        {
          id: 'KEY_SCROLL',
          description: intl.formatMessage({id: 'draw.KEY_SCROLL'}),
          Icons: (
            <div className={HelpKeyContainer}>
              <span className={HelpKeyStyle}>{intl.formatMessage({id: 'draw.SCROLL'})}</span>
            </div>
          ),
        },
      ];
      return items;
    }
    default:
      return [];
  }
};

const renderShortcuts = ({ id, description, Icons }) => (
  <div key={id} className={KeyboardShortcutItemStyle}>
    {Icons}
    <Typography className={KeyboardShortcutDescriptionStyle} variant="caption">
      {description}
    </Typography>
  </div>
);

interface IProps {  
  geometryType: EGeometryItemTypes;
  primary?: boolean;
}

export function KeyboardEditHelp(props: IProps) {
  const { geometryType, primary } = props;  
  const shortCuts = useMemo(
    () => {
      const sh = getAddShortcuts(geometryType); 
      if (!sh) {
        return [];
      } else {
        return sh;
      }
    },
    [geometryType],
  );

  return (
    <ContextHelp small primary={primary} content={<div className={contentContainerStyle}>{shortCuts.map(renderShortcuts)}</div>} />
  );
}
