import { takeEvery, put, delay } from 'redux-saga/effects'
import ActionType from '../actions/ActionType'
import { removeMessage } from '../actions/message'

const DEFAULT_AUTOHIDE_DURATION = 6000;

export default function* watchAddMessage() {
  yield takeEvery(ActionType.ADD_MESSAGE, handleAddMessage)
}

export function* handleAddMessage() {
    yield delay(DEFAULT_AUTOHIDE_DURATION)
    yield put(removeMessage())
}