import { IMikeTheme } from '../../shared/mikeSharedTheme';
import { makeStyles, createStyles } from '@material-ui/core';

export const useDropdownStyles = makeStyles((theme: IMikeTheme) => {
  return createStyles({
    formControl: {     
      marginTop: 0,
      marginBottom: 0
    },
    input: {
      paddingTop: theme.spacing(2.25),
      paddingBottom: 0,
    },
    inputNoLabel: {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(0.75),
    },
    iconDisabled: {
      fill: theme.palette.mediumGrey && theme.palette.mediumGrey.main,
    },
    iconError: {
      fill: theme.palette.error.main,
    },
    iconHidden: {
      opacity: 0,
    },
    menuList: {
      backgroundColor: theme.palette.mediumGrey && theme.palette.mediumGrey.light,
    },
    primaryDisabled: {
      color: theme.palette.mediumGrey && theme.palette.mediumGrey.main,
    },
    primarySelected: {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
    },
    helperTextPlaceHolder: {
      minHeight: theme.spacing(2),
      backgroundColor: 'transparent',
    },
    menuItem: {
      display: 'flex',
      alignItems: 'center'
    }
  });
});
