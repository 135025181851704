import { eventChannel } from 'redux-saga'


export const createEventChannelCallback = async cb => {
  let channel

  const emit = await new Promise(resolve => {
    channel = eventChannel(emit => {
      resolve(emit)
      return () => {}
    })
  })

  const enhancedCb = (...args) => cb(emit, ...args)

  return [channel, enhancedCb]
}


