import ActionType from "../actions/ActionType"
import { IAction } from "../actions/Action"
import { INotification } from '@mike/mike-shared-frontend/mike-notification-list/INotification'

export interface INotificationState {
  hideBlueDot: boolean;
  notification: INotification[];
}

const initState = {
  hideBlueDot: true,  
  notification: Array<INotification>()
}

const notification = (state = initState, action: IAction<any>) => {
  switch (action.type) {
    case ActionType.HIDE_BLUE_DOT:
      return { ...state, hideBlueDot: action.data };
    case ActionType.SET_NOTIFICATION:
      return {
        ...state,
        notification: action.data,
        hideBlueDot: action.data === null
      };      
    default:
        return state;
  }
};

export default notification
