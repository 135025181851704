import React from 'react';
import classNames from 'classnames';
import { Button as MuiButton, makeStyles, createStyles, CircularProgress } from '@material-ui/core';
import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import { PaletteColor } from '@material-ui/core/styles/createPalette';
import { IMikeTheme } from '../../shared/mikeSharedTheme';

export interface IMikeButtonProps extends MuiButtonProps {
  /**
   * The type of button as defined in the DHI Guidelines
   * @deprecated Ultimate buttons are only kept for compatibility and will be removed in a future release.
   */
  buttontype?: 'primary' | 'secondary' | 'ultimate' | 'text' | 'default';

  /**
   * If true, a spinner will be shown and children will be hidden
   */
  active?: boolean;
}

const useStyles = makeStyles((theme: IMikeTheme) => {
  let ultimate: PaletteColor = theme.palette.ultimate as PaletteColor;

  if (!ultimate) {
    ultimate = theme.palette.primary;
  }

  return createStyles({
    mikeButtonRoot: (props: IMikeButtonProps) =>
      props.buttontype === 'ultimate'
        ? {
            color: ultimate.contrastText,
            backgroundColor: ultimate.main,
            '&:hover': {
              backgroundColor: ultimate.dark,
              // Reset on touch devices, it doesn't add specificity
              '@media (hover: none)': {
                backgroundColor: ultimate.main,
              },
            },
            '&:disabled': {
              backgroundColor: ultimate.light,
              color: '#fff',
            },
          }
        : {},
    spinner: {
      opacity: 0.8, // todo hevo Can the opacity be taken from theme somehow?
    },
  });
});

/**
 * @name MikeButton
 * @summary A convenience wrapper for the MuiButton with support the the DHI Guideline buttons: Primary, Secondary (outlined), Ultimate and Text/Link
 */
const MikeButton = (props: IMikeButtonProps) => {
  const {
    buttontype,
    active,
    children,
    color: colorProp,
    variant: variantProp,
    className: classNameProp,
    ...rest
  } = props;

  const classes = useStyles(props);

  // Keep variant if defined in props
  let variant = variantProp;

  if (!variant) {
    switch (buttontype) {
      case 'primary':
      case 'ultimate':
      case 'default':
        variant = 'contained';
        break;

      case 'secondary':
        variant = 'outlined';
        break;

      case 'text':
        variant = 'text';
        break;

      default:
        variant = 'contained';
        break;
    }
  }

  // keep color if defined in props
  const classname = !colorProp ? classNames(classes.mikeButtonRoot, classNameProp) : classNameProp;

  return (
    <MuiButton className={classname} variant={variant} color={colorProp} {...rest}>
      {active && (
        <CircularProgress
          size={28}
          variant="indeterminate"
          color="inherit"
          className={classes.spinner}
        />
      )}

      {!active && children}
    </MuiButton>
  );
};

export default MikeButton;
