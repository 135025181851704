import React, { useCallback } from 'react'
import { Formik, Form } from 'formik'
import merge from 'lodash/fp/merge'
import Button from '@material-ui/core/Button'
import { ReactComponent as Delete } from '@mike/mike-shared-frontend/media/icons/Delete'
import { ReactComponent as Checkmark } from "@mike/mike-shared-frontend/media/icons/Checkmark"
import { ReactComponent as MapPinOutline } from "@mike/mike-shared-frontend/media/icons/MapPinOutline"
import TextField from '../formik/TextField'
import { useIntl } from 'react-intl'
import {Feature} from "geojson"
import { css } from 'emotion'
import theme, { mikePalette } from '../../shared/mikeSharedTheme'
import * as Yup from "yup";
import { iconPrimaryStyle } from './iconStyles'
import { isEmpty, uniq } from 'lodash';
import { useDispatch, useSelector } from 'react-redux'
import { IState } from '../../reducers'
import { getNextFreeNumber } from '../../helpers/fastwave'
import { addError } from '../../actions/errors'

export interface IValue {
  newName?: string;
  newLong?: string;
  newLat?: string;
  features: Array<Feature>
}
interface IProps { 
  initialValues: IValue
  onSubmit: (values: IValue) => void
}

Yup.addMethod(Yup.array, 'tuple', function(schema) {
  if (!this.isType(schema)) Yup.ValidationError();
  return Yup.object({
    tuple: Yup.array().min(schema.length).max(schema.length),
    ...Object.fromEntries(Object.entries(schema)),
  }).transform((value, originalValue) => {
    if (!this.isType(originalValue)) Yup.ValidationError();
    return {
      tuple: originalValue,
      ...Object.fromEntries(Object.entries(originalValue))
    };
  });
});

const getValidationSchema = (epsg: number) => {
  const longValidation = "Must be within [-180:180]"
  const latValidation = "Must be within [-90:90]"
  const nameValidation = "Remove special characters"
  const valExpression = /^([a-zA-Z0-9]*)+$/g
  if (epsg !== 4326){
    const numericValidation = "Must be numeric"
    return Yup.object().shape({ 
      newName: Yup.string().matches(
        valExpression,
          nameValidation
        ),
      newLong: Yup.number().typeError(numericValidation),
      newLat: Yup.number().typeError(numericValidation), 
      
      features: Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.string().matches(
              valExpression,
                nameValidation
              ),
            geometry: Yup.object().shape({
            coordinates: Yup.array().tuple([
              Yup.number().typeError(numericValidation).required(numericValidation),
              Yup.number().typeError(numericValidation).required(numericValidation)
            ]) 
          })})
        )
    });
  }
  else{
    return  Yup.object().shape({ 
      newName: Yup.string().matches(
        valExpression,
          nameValidation
        ),
      newLong: Yup.number().typeError(longValidation).min(-180, longValidation).max(180, longValidation), // Latitude [°N][-90:90]//[-180:180],
      newLat: Yup.number().typeError(latValidation).min(-90, latValidation).max(90, latValidation), // Latitude [°N][-90:90]
      
      features: Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.string().matches(
              valExpression,
                nameValidation
              ),
            geometry: Yup.object().shape({
            coordinates: Yup.array().tuple([
              Yup.number().typeError(longValidation).required(longValidation).min(-180, longValidation).max(180, longValidation),
              Yup.number().typeError(latValidation).required(latValidation).min(-90, latValidation).max(90, latValidation)
            ]) 
          })})
        )
    });
  }
}

const buttonStyle = css` 
  &.MuiButton-textSecondary {
     color: ${mikePalette.primary.main};
      &:hover {
        background-color: ${mikePalette.text.disabled};
      }
  }
  `

const containerStyle = css`
  padding: ${theme.spacing(1)}px;
`

const rowStyle = css`
  display: flex;
  justify-content: space-between; 
  align-items: center;
`
const rowItem35Style = css`
  padding-right: ${theme.spacing(1)}px;
  width: 23vw;
`
const rowItem7dot5Style = css`
  padding-right: ${theme.spacing(1)}px;
  width: 7.5vw; 
`
const rowItem15Style = css`
  padding-right: ${theme.spacing(1)}px;
  width: 15vw;   
`

const flexStyle = css`
   display: flex;
   align-items: center;
   padding-right: ${theme.spacing(1)}px;
   `
const indexPlaceHolder =  css`
   width: ${theme.spacing(13)}px;
 `

const placeHolder =  css`
  width: ${theme.spacing(5)}px;
`

const header =  css`
padding-top: ${theme.spacing(1)}px;
padding-bottom: ${theme.spacing(1)}px;
`

const defaultInitialValues = { 
  newName: "",
  newLong: "",
  newLat: "", 
  features: []
}

const EditPointsForm = (props: IProps ) => { 
  const dispatch = useDispatch()
  const epsg = useSelector(
    (state: IState) => state.mapContent.epsgCode
  ); 

  const points = useSelector(
    (state: IState) => state.mapContent.points
  );  

  const intl = useIntl()
  const { initialValues, onSubmit } = props
  const mergedInitialValues = merge(defaultInitialValues, initialValues)

  const inputValues = {  
    newName: "",
    newLong: "",
    newLat: "", 
    features: mergedInitialValues.features
  }

  const onUpdate = (values: IValue) => { 
    onSubmit(values)
  }

  const removeFeature = (md: Feature, values: IValue) => {
    const newValues = values.features.filter((v: Feature) => v.id !== md.id)
    onSubmit({features: newValues })
  }

  const removeAllFeatures = () => {
    onSubmit({features: [] })
  }

  const submitFeature = (values: IValue) => { 
    const emptyIds = values.features.filter((f) => !f.id)
    if (emptyIds.length > 0){
      dispatch(addError(intl.formatMessage({id: 'feature.ensureUniqNames'})))
    }
    const ids = values.features.map((f) => f.id)
    const uniqIds = uniq(ids)
    if (ids.length !== uniqIds.length){
      dispatch(addError(intl.formatMessage({id: 'feature.ensureUniqNames'})))
    }
    else{
      onSubmit(values)
    }
  }

  const submitNewFeature = useCallback((values: IValue) => {   
    const name = values.newName
    const point = points.find((p) => p.id === name)
    if (point !== undefined){
      dispatch(addError(intl.formatMessage({id: 'feature.ensureUniqNames'})))
    }
    else{
      const idForEmpryFeature = values.newName ? values.newName : 'Point' + getNextFreeNumber(points) 
      const emptyFeature: Feature = {
        type: "Feature",
        id: idForEmpryFeature,
        properties:{
          id: idForEmpryFeature
        },
        geometry: {
          type: "Point",
          coordinates: [parseFloat(values.newLong), parseFloat(values.newLat)]
        }}
      
      onSubmit({features: [emptyFeature, ...values.features]})
    }    
  }, [dispatch, intl, onSubmit, points])

  const getDisabled = (validationErrors: object, index: number) => {
    if (isEmpty(validationErrors)){
      return false
    }
    if (index === -1){
      //New point
      const keys = ["newName", "newLong", "newLat"]
      const errorKeys = Object.keys(validationErrors)
      errorKeys.forEach((key: string) => {
        if (keys.includes(key)){
          return true
        }
      })
    }
    else{
      const f = "features"
      if (validationErrors[f] && validationErrors[f][index]){
        return true
      }
    }
    return false
  }

  return (
    <Formik
      validationSchema={getValidationSchema(epsg)}
      initialValues={inputValues}
      enableReinitialize     
      onSubmit={onUpdate}
      render={formikProps => {
        const { values, errors } = formikProps
        return (
          <Form>
            <div className={containerStyle}>
              <div className={rowStyle}>
                <div className={indexPlaceHolder}/>
                <div className={rowItem35Style}>                
                  <div className={header}>
                  <b>{intl.formatMessage({id: 'editPointsForm.point'})}</b>
                  </div>                  
                </div>
                <div className={rowItem35Style}>
                  <div className={header}>
                  <b>{intl.formatMessage({id: 'editPointsForm.xCoord'})}</b>
                  </div>
                </div>
                <div className={rowItem35Style}>
                  <div className={header}>
                  <b>{intl.formatMessage({id: 'editPointsForm.yCoord'})}</b>
                  </div>
                </div>
                {values.features.length === 0 ?  <div className={rowItem15Style}/> : 
           
                <div className={rowItem15Style}>
                  <Button  
                    className={buttonStyle}                                
                    onClick={() => removeAllFeatures()}
                    disabled={values.features.length === 0}
                  >
                    {intl.formatMessage({
                      id: 'feature.deleteAll'
                    })}
                    <Delete />
                  </Button>
                </div>}
              </div>  
              <div className={rowStyle}>      
                <div className={flexStyle}>
                  <MapPinOutline/>
                </div>
                <div className={rowItem35Style}>
                  <TextField
                    name={'newName'}               
                    label={intl.formatMessage({
                      id: 'feature.name'
                    })}
                    fullWidth                      
                    addPlaceHolder={true} 
                  />
                </div>
                <div className={rowItem35Style}>
                  <TextField
                   margin="dense"
                    name={'newLong'}        
                    label={intl.formatMessage({
                      id: 'feature.longitude'
                    })}
                    fullWidth                      
                    addPlaceHolder={true}
                  />
                  </div>
                  <div className={rowItem35Style}>
                    <TextField 
                     margin="dense"  
                      name={'newLat'}                         
                      label={intl.formatMessage({
                        id: 'feature.latitude'
                      })}
                      fullWidth                      
                      addPlaceHolder={true} 
                    />
                  </div>
                  <div className={rowItem7dot5Style}>
                    <Button
                      disabled={values.newName === "" || values.newLat === "" || values.newLong === "" || getDisabled(errors, -1)}
                      style={{ minWidth: 40, padding: 0 }}
                      onClick={() => {
                        submitNewFeature(values)                        
                      }}
                    >
                      <Checkmark className={iconPrimaryStyle(values.newName === "" ||  values.newLat === "" || values.newLong === "" || getDisabled(errors, -1))} />
                    </Button>
                  </div> 
                  <div className={rowItem7dot5Style}>
                    <div className={placeHolder}/>
                  </div>           
                </div>
                {inputValues.features.map((val: Feature, index: number) => (
                  <div key={index} className={rowStyle}>
                    <div className={flexStyle}>
                      <MapPinOutline/>
                    </div>
                    <div className={rowItem35Style}>
                      <TextField
                        name={`features.${index}.id`}               
                        label={intl.formatMessage({
                          id: 'feature.name'
                        })}
                        fullWidth                      
                        addPlaceHolder={true} 
                      />
                    </div>
                    <div className={rowItem35Style}>
                      <TextField
                        name={`features.${index}.geometry.coordinates[0]`}               
                        label={intl.formatMessage({
                          id: 'feature.longitude'
                        })}
                        fullWidth
                        required
                        addPlaceHolder={true} 
                      />
                    </div>
                    <div className={rowItem35Style}>
                      <TextField
                        name={`features.${index}.geometry.coordinates[1]`}              
                        label={intl.formatMessage({
                          id: 'feature.latitude'
                        })}
                        fullWidth
                        required
                        addPlaceHolder={true}    
                      />
                    </div>
                    <div className={rowItem7dot5Style}>   
                      <Button
                        style={{ minWidth: 40, padding: 0 }}
                        onClick={() => submitFeature(values)}
                        disabled={getDisabled(errors, index)}
                      >
                        <Checkmark className={iconPrimaryStyle(getDisabled(errors, index))}/>
                      </Button>
                    </div> 
                    <div className={rowItem7dot5Style}> 
                      <Button
                        style={{ minWidth: 40, padding: 0 }}
                        onClick={() => removeFeature(val, values)}
                      >
                        <Delete />
                      </Button>
                    </div> 
                  </div>                                
                ))}
            </div>
            
          </Form>
        )
      }}
    />
  )
}

export default EditPointsForm