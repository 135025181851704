import ActionType from "../actions/ActionType";
import { IAction } from "../actions/Action";

export interface IMOODState 
{
  publishingResultsToMOOD: boolean; 
}

const initState = 
{ 
  publishingResultsToMOOD: false 
}

const mood = (state = initState, action: IAction<string>) => {
  switch (action.type) {
    case ActionType.PUBLISHING_DATA_TO_MOOD:
      return { ...state, publishingResultsToMOOD: action.data };       
    default:
        return state;
  }
};

export default mood