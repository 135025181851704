import { ICreateProject } from '../model/ICreateProject'
import { metadata, rawApi, coordinateSystem, administration } from './support'

const BASE_JOB_URL = 'process'
const BASE_CS_URL = 'crs/coordinate-system'
const BASE_IAM_URL = 'iam'
const BASE_ADMIN_URL = 'admin'

export const getUserTenants = () =>
  administration.getJson(`${BASE_IAM_URL}/tenant/my`, "3")

export const getTenantFeatures = () =>
  administration.getJson(`${BASE_ADMIN_URL}/feature/tenant/list`, "3")

export const getCoordinateSystems = async (searchText = "") => {
  const query = searchText ? `?fulltext=${searchText}` : ''
  return coordinateSystem.getJson(`${BASE_CS_URL}/list${query}`, "2")
}

export const getCoordinateSystem = srid =>
  coordinateSystem.getJson(`${BASE_CS_URL}/${srid}`, "2")

export const getAuthorities = () =>
  coordinateSystem.getJson(`${BASE_CS_URL}/authority`)

export const getRawService = () => {
  return metadata.getJson(`services/raw/baseuri`)
}

export const updateDataset = (datasets, sasToken: string) => {  
  return rawApi.putJson(`filesync/upload`, datasets, "2", {"dhi-sas-token": sasToken})
}

export const getSASToken = (projectId: string, datasetId: string) => {
  return metadata.getJson(`services/sastoken?projectId=${projectId}&resourceId=${datasetId}`)
}

export const getJobStatus = (jobId: string, projectId: string) => metadata.getJson(`${BASE_JOB_URL}/job/${jobId}`, "3", {'dhi-project-id': projectId, 'dhi-service-id': 'job' })

export const cancelJob = (jobId: string, projectId: string) => metadata.putJson(`${BASE_JOB_URL}/job/${jobId}/cancel`, {}, "3", {'dhi-project-id': projectId, 'dhi-service-id': 'job' })


export const getRootProjects = async (
  newOffset: number,
  newLimit: number,
  sortBy: string,
  sortOrder: 'Asc' | 'Desc',
  namePrefix: string
) => {
  const query = `?offset=${newOffset}&limit=${newLimit}&sortBy=${sortBy}&sortOrder=${sortOrder}&namePrefix=${namePrefix}`
  const response = await metadata.getJson(`metadata/project/offset-list${query}`, "3");
  const { data, totalCount, offset, limit } = response 
  return { data: data, totalCount: totalCount, offset: offset, limit: limit }
}

export const getProject = (id: string) => {
  return metadata.getJson(`metadata/project/${id}`, "3")
}

export const postProject = (data: ICreateProject) => {
  return metadata.postJson(`metadata/project`, data, "3")
}

export const postSubproject = (data: ICreateProject, projectId: string) => {
  return metadata.postJson(`metadata/project/${projectId}/subproject`, data, "3")
}

export const getProjectDatasets = async (projectId: string) => {
  const { data } = await metadata.getJson(
    `metadata/project/${projectId}/dataset/list`,
    "3"
  )
  return data
}

export const getProjectSubprojects = async (projectId: string) => {
  const { data } = await metadata.getJson(
    `metadata/project/${projectId}/subprojects`,
    "3"
  )
  return data
}

export const getProjectFolders = async (
  projectId: string,
  newOffset: number,
  newLimit: number,
  sortBy: string,
  sortOrder: string,
  namePrefix: string
) => {
  //
  const query = `?offset=${newOffset}&limit=${newLimit}&sortBy=${sortBy}&sortOrder=${sortOrder}&namePrefix=${namePrefix}`
  const { data, totalCount, offset, limit } = await metadata.getJson(
    `metadata/project/${projectId}/subproject/offset-list${query}`,
    "3"
  )
  return { data: data, totalCount: totalCount, offset: offset, limit: limit }
}

export const getProjectDataset = (datasetId: string) =>
  metadata.getJson(`metadata/dataset/${datasetId}`, "3")

// TODO: Update to latest MD version when Metadata service exposes GET customer/users --> do mapping with display name and email client-side
export const getProjectMembers = async (projectId: string) => {
  const members = await metadata.getJson(
    `metadata/project/${projectId}/member`,
    "3"
  )
  return members.data
}

export const getCapabilities = async (projectId: string) => {
  return await metadata.getJson(`metadata/project/${projectId}/capabilities`, "3")
}

export const delProject = async (projectId: string) =>
  await metadata.delJson(`metadata/project/${projectId}`, "3")

export const delProjectDataset = (datasetId: string) =>
  metadata.delJson(`metadata/dataset/${datasetId}`, "3")

export const putProject = (data: object) => metadata.putJson('metadata/project', data, "3")

export const putProjectDataset = (projectId: string, data: object) =>
  metadata.putJson(`metadata/project/${projectId}/dataset/`, data, "3")

export const moveDataset = (datasetId: string, targetProjectId: string) => {
  const data = { targetProjectId: targetProjectId }
  metadata.postJson(`metadata/dataset/${datasetId}/move`, data, "3")
}

export const copyDataset = (datasetId, body) => {
  return metadata.postJson(`metadata/dataset/${datasetId}/convert`, body, "3")
}

export const updateDataset1 = (datasetId, body) => {
  return metadata.postJson(`metadata/dataset/${datasetId}/update`, body, "3")
}

export const getProjectPath = async (projectId: string) => {
  const { data } = await metadata.getJson(
    `metadata/project/${projectId}/path`,
    "3"
  )
  return data
}
