import { css } from 'emotion'
import { mikePalette } from '../../shared/mikeSharedTheme'
import { makeStyles, createStyles } from '@material-ui/core'
import { IMikeTheme } from '../../shared/mikeSharedTheme'

export const useStyles = makeStyles((theme: IMikeTheme) => {
  return createStyles({
    button: {
      color: theme.palette.secondary.main,
      '&:disabled': {
        color: theme.palette.secondary.light
      }
    },

    buttonSecondaryBorder: {
      padding: 0,
      paddingRight: 12,
      border: '2px solid ' + mikePalette.secondary.main,
      color: mikePalette.secondary.main,
      '&[disabled]': {
        color: mikePalette.secondary.light,
        opacity: 0.5,
        cursor: 'not-allowed'
      }
    }
  })
})

export const iconButtonStyle = css`
  button {
    color: ${mikePalette.secondary.main};
    '&:disabled': {
      color: theme.palette.primary.light;
    }
  }
`

export const iconStyle = css`
  path {
    fill: ${mikePalette.secondary.main};
  }
`

export const iconPrimaryStyle = (disabled = false) => {
  const color = disabled ? mikePalette.primary.light : mikePalette.primary.main
  return css`
    path {
      fill: ${color};
      stroke: ${color};
    }
  `
}

export const iconStrokePrimaryStyle = (disabled = false) => {
  const color = disabled ? mikePalette.primary.light : mikePalette.primary.main
  return css`
    path {     
      stroke: ${color};
    }
  `
}

export const iconFillPrimaryStyle = (disabled = false) => {
  const color = disabled ? mikePalette.primary.light : mikePalette.primary.main
  return css`
    path {     
      stroke: ${color};
    }
  `
}

export const iconWhiteStyle = (disabled = false) => {
  const color = disabled
  ? mikePalette.background.paper
  : mikePalette.background.default  
 
  return css`
    path {
      fill: ${color};
      stroke: ${color};
    }
  `
}

export const uploadIconStyle = css`
&..MuiButton-startIcon {
  margin-right: 0;  
}
`;

export const linkStyle = (disabled = false) => {
  const cursor = disabled ? 'not-allowed' : 'default'
  return css`
    display: content,
    text-Decoration: none,
    cursor: ${cursor};
  `
}

export const iconSecondaryStyle = (disabled = false) => {
  const color = disabled
    ? mikePalette.secondary.light
    : mikePalette.secondary.main
  return css`
    path {
      fill: ${color};
      stroke: ${color};
    }
  `
}


export const iconCustomSizeStyle = (size = 32) => {
  const height = size
  return css`
    svg {
      height: ${height};
      width: ${height};
    }
  `
}
