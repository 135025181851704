import React, { useCallback } from 'react';
import { ClassNameMap } from '@material-ui/styles/withStyles';
import classNames from 'classnames';
import { makeStyles, createStyles, Typography } from '@material-ui/core';
import Dialog, { DialogClassKey } from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import theme, { IMikeTheme } from '../../shared/mikeSharedTheme';
import MikeButton from './../mike-button';
import MikeDialogTitle from './MikeDialogTitle';
import { css } from 'emotion'

export type MikeDialogClassKey =
  | 'dialogPaper'
  | 'dialogTitle'
  | 'dialogActions'
  | 'dialogContent'
  | 'contentTitle';

export const mediumButtonStyle = css`
  &.MuiButton-root {
    height: 2.5rem;  
    min-width: 0;
    padding: 0 1rem  
  }
  `
export const dialogContentStyle = css`
    background-color: ${theme.palette.background.paper}; 
    padding-bottom: ${theme.spacing(5)}px; 
  }`

export const contentTitleStyle = css`
  font-weight: bold;
  padding-bottom: ${theme.spacing(1)}px; 
  padding-top: ${theme.spacing(1)}px; 
}`

export const dialogActionsStyle = css`
  background-color: ${theme.palette.background.paper}; 
  color: ${theme.palette.background.paper}; 
  padding-right: ${theme.spacing(3)}px; 
  width: 100%; 
}`

const useStyles = makeStyles((theme: IMikeTheme) => {
  return createStyles({
    dialogPaper: {
      minHeight: '75vh',
      maxHeight: '75vh',
    },
    dialogTitle: {
      paddingLeft: theme.spacing(3),      
      borderRadius: '4px 4px 0px 0px',      
      height: theme.spacing(8),
      color: theme.palette.background.paper,
      width: '100%',
      /* zIndex: 11,
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.16)', */
    },   
  });
});

interface IProps {
  cancelButtonLabel?: string;
  children?: React.ReactNode;
  classes?: Partial<ClassNameMap<MikeDialogClassKey>>;
  dialogActions?: React.ReactNode;
  dialogClasses?: Partial<ClassNameMap<DialogClassKey>>;
  contentTitle?: string;
  dialogTitle?: string;
  hideActions?: boolean;
  message?: string;
  okButtonDisabled?: boolean;
  okButtonLabel?: string;
  open: boolean;
  onCancel: () => void;
  onOk?: () => void;
}

const MikeDialog = (props: IProps) => {
  const currentClasses = useStyles();
  const {
    children,
    classes = {},
    dialogActions,
    dialogClasses = {},
    contentTitle,
    dialogTitle,
    hideActions,
    message,
    okButtonDisabled = false,
    okButtonLabel = 'Ok',
    cancelButtonLabel = 'Cancel',
    onCancel,
    onOk,
    open,
    ...rest
  } = props;

  const handleOK = useCallback(() => {
    if (onOk){
      onOk();
    }
  }, [onOk])

  return (
    <Dialog onClose={onCancel} open={open} scroll={'paper'} {...rest} classes={dialogClasses}>
      <MuiDialogTitle className={classNames(classes.dialogTitle, currentClasses.dialogTitle)}>
        <MikeDialogTitle title={dialogTitle} onClose={onCancel} />
      </MuiDialogTitle>
      {children ? (
        children
      ) : (
        <MuiDialogContent
          className={classNames(classes.dialogContent, dialogContentStyle)}
        >
          <Typography
            variant="h3"
            className={classNames(classes.contentTitle, contentTitleStyle)}
          >
            {contentTitle}
          </Typography>
          <Typography variant="body2">{message}</Typography>
        </MuiDialogContent>
      )}
      {hideActions ? null : dialogActions ? dialogActions : (
        <DialogActions className={classNames(classes.dialogActions, dialogActionsStyle)}>       
          <MikeButton className={mediumButtonStyle} onClick={onCancel} buttontype="text">
            {cancelButtonLabel}
          </MikeButton>
          <MikeButton className={mediumButtonStyle} onClick={handleOK} buttontype="primary" disabled={okButtonDisabled}>
            {okButtonLabel}
          </MikeButton>
        </DialogActions>
      )}
      
    </Dialog>
  );
};

export default MikeDialog;
