import React from 'react';
import { Typography, Link } from '@material-ui/core';
import { ReactComponent as ArrowRight } from '@mike/mike-shared-frontend/media/icons/ArrowRight';
import { ILinkConfig } from '@mike/mike-shared-frontend/mike-support/ILinkConfig';
import theme, { mikePalette } from '../../shared/mikeSharedTheme'
import { css } from 'emotion';

const LinkStyle = css`
  height: ${theme.spacing(7.5)}px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: ${theme.spacing(0)}px;
  padding-left: ${theme.spacing(0.5)}px;
  padding-right: ${theme.spacing(2)}px;
  color: ${mikePalette.primary.main};
  background-color: ${mikePalette.lightGrey.main};
  &:hover {
    background-color: ${mikePalette.mediumGrey.main};
  }
`;

const LinkTextStyle = css`
  padding-left: ${theme.spacing(2)}px;
  color: ${mikePalette.primary.main};
  font-size: 0.875rem;
  line-height: 1.286;
`;

interface IProps {
  /**
   * Configuration.
   */
  config: ILinkConfig;
  /**
   * Click callback.
   */
  onClickCallBack: () => void;
}

/**
 * @name MikeSubscribedAppsLinkItem
 * @summary A generic app link. Meant to be placed into the MikeSubscribedApps popper.
 */
export const MikeSubscribedAppsLinkItem = (props: IProps) => {
  const { config = {} as ILinkConfig, onClickCallBack } = props;

  return (
    <Link
      rel="noopener noreferrer"
      href={config.href}
      target={config.target ? config.target : ''}
      className={LinkStyle}
      underline="none"
      onClick={onClickCallBack}
    >
      {config.icon ? config.icon : <ArrowRight />}
      {config.label && <Typography className={LinkTextStyle}>{config.label}</Typography>}
    </Link>
  );
};

export default MikeSubscribedAppsLinkItem;
