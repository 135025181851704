import React from 'react';
import { ClassNameMap } from '@material-ui/styles/withStyles';
import classNames from 'classnames';
import { IMikeTheme } from '../../shared/mikeSharedTheme';
import { makeStyles, createStyles, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

export type DialogTitleClassKey = 'titleBar' | 'onlyIconBar' | 'titleHeadings' | 'closeButton';

const useStyles = makeStyles((theme: IMikeTheme) => {
  return createStyles({
    titleBar: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    onlyIconBar: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    titleHeadings: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    closeButton: {
      padding: 0,
      color: theme.palette.primary.main,
    },
  });
});

interface IProps {
  classes?: Partial<ClassNameMap<DialogTitleClassKey>>;
  title?: string;
  onClose: () => void;
}

const MikeDialogTitle = (props: IProps) => {
  const className = useStyles();
  const { classes = {}, title, onClose } = props;

  return (
    <div
      className={
        title
          ? classNames(classes.titleBar, className.titleBar)
          : classNames(classes.onlyIconBar, className.onlyIconBar)
      }
    >
      {title ? (
        <div className={classes.titleHeadings}>
          <Typography variant="h3">{title}</Typography>
        </div>
      ) : null}
      <div>
        <IconButton
          aria-label="Close"
          onClick={onClose}
          className={classNames(classes.closeButton, className.closeButton)}
        >
          <CloseIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default MikeDialogTitle;
