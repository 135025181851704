import React from 'react';
import { useDropzone } from 'react-dropzone';
import Button from '@material-ui/core/Button';
import { ReactComponent as Plus } from "@mike/mike-shared-frontend/media/icons/Plus"
import { iconWhiteStyle, iconSecondaryStyle } from '../EditPointsForm/iconStyles'
import { css } from 'emotion'
import theme from '../../shared/mikeSharedTheme'
import ContextHelp from '../ContextHelp';


interface IProps { 
  fileType: string | Array<string>;
  onDrop: (files: any) => void;
  disabled: boolean;
  label: string;
  variant?: string;
  info?: Array<string>; 
}

const buttonStyle = css`
  white-space: nowrap;
  &.MuiButton-root {
    padding-right: ${theme.spacing(2)}px;
    padding-left: ${theme.spacing(0.5)}px;
  }
  `
  const containerStyle = css`
    display: flex;
    padding-right: ${theme.spacing(2)}px;
    padding-left: ${theme.spacing(0.5)}px;
    white-space: nowrap;
  `

const UploadButton = (props: IProps) => {
  const { 
    fileType,
    onDrop,
    disabled,
    label,
    variant = "text",
    info
  } = props;

  const { getInputProps, getRootProps  } = useDropzone({ onDrop, accept: fileType });  

  return info ?  (
    <div className={containerStyle}>     
      <Button disabled={disabled} variant={variant}  {...getRootProps() as any}>
        <Plus className={variant === "contained" ? iconWhiteStyle(disabled) : iconSecondaryStyle(disabled)} width={24} height={24} viewBox={"0 0 40 40"}/>
        {label}
        <input {...getInputProps()}/>
      </Button>
      <ContextHelp primary={variant === "outlined"} helpTexts={info} disabled={disabled}/>
    </div>
  ) : (
    <Button disabled={disabled} variant={variant}  {...getRootProps() as any} className={buttonStyle}>
      <Plus className={variant === "contained" ? iconWhiteStyle(disabled) : iconSecondaryStyle(disabled)} width={24} height={24} viewBox={"0 0 40 40"}/>
      {label}
      <input {...getInputProps()}/>
    </Button>
  );
};

export default UploadButton;
