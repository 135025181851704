import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider, createIntl, defineMessages } from "react-intl";
import './index.css';
import App from './components/App/App';
import { Provider } from "react-redux";
import storeConfig from './ConfigureStore'
import { MuiThemeProvider } from "@material-ui/core";
import theme from "./shared/mikeSharedTheme";
import messagesEn from "./messages/en";
import fetchConfig from './apis/config'
import { OidcProvider, useOidc } from '@axa-fr/react-oidc'
import { OidcConfiguration } from '@axa-fr/react-oidc/dist/vanilla/oidc'
import { BrowserRouter as Router } from 'react-router-dom'
import init from './apis/support';
import OpenIdStatusPage from './components/App/login/OpenIdStatusPage';

interface IndexProps {
  openIdConfig: OidcConfiguration
}
const language = "en";
const messages = { en: messagesEn };
// export intl
export const intl  = createIntl({ locale: 'en', messages: messagesEn})

const localMessages = defineMessages({ 
  headline: {
    id: 'openIdStatusPage.headline'
  },
  pleaseWait: {
    id: 'openIdStatusPage.pleaseWait'
  },
  errorHeadline: {
    id: 'openIdStatusPage.errorHeadline'
  },
  errorBody: {
    id: 'openIdStatusPage.errorBody'
  },
  loading: {
    id: 'openIdStatusPage.loading'
  }
})

const Index = (props: IndexProps) => {
  const { login } = useOidc()
  const { openIdConfig } = props

  return (
    <IntlProvider locale={language} messages={messages[language]}>
    <OidcProvider
          configuration={openIdConfig}
          onSessionLost={() => {
            login()
          }}
          authenticatingComponent={() => (
            <OpenIdStatusPage
              headline={intl.formatMessage(localMessages.headline)}
              body={intl.formatMessage(localMessages.pleaseWait)}
            />
          )}
          callbackSuccessComponent={() => (
            <OpenIdStatusPage
              headline={intl.formatMessage(localMessages.headline)}
              body={intl.formatMessage(localMessages.pleaseWait)}
            />
          )}
          authenticatingErrorComponent={() => (
            <OpenIdStatusPage
              headline={intl.formatMessage(localMessages.errorHeadline)}
              body={intl.formatMessage(localMessages.errorBody)}
              showRetryButton={true}
            />
          )}
          loadingComponent={() => (
            <OpenIdStatusPage
              headline={intl.formatMessage(localMessages.loading)}
              body={intl.formatMessage(localMessages.pleaseWait)}
            />
          )}
        >
       
          <MuiThemeProvider theme={theme}>
            <Provider store={storeConfig.store}>
              <Router>
                <App/>
              </Router>
            </Provider>
          </MuiThemeProvider>
        
        </OidcProvider>
        </IntlProvider>
  )
};

fetchConfig().then((config) => {
  const openIdConfig = {
    client_id: config.oAuth2.clientName,
    redirect_uri: `${window.location.origin}/authentication/callback`,
    silent_redirect_uri: `${window.location.origin}/authentication/silent-callback`,
    scope: 'openid offline_access',
    authority: config.oAuth2.url,
    service_worker_relative_url: '/OidcServiceWorker.js',
    service_worker_only: false,
    refresh_time_before_token_expiration_in_second: 120,
  };
  init(config);
  navigator.serviceWorker.getRegistration().then(function (reg) {
    // On a hard refresh (shift + refresh) there's an active SW, but no controller for this tab.
    // https://stackoverflow.com/questions/51597231/register-service-worker-after-hard-refresh
    if (reg!.active && !navigator.serviceWorker.controller) {
      // Perform a soft reload to load everything from the SW and get
      // a consistent set of resources.
      window.location.reload();
    }
  });
  ReactDOM.render(<Index openIdConfig={openIdConfig} />, document.getElementById('root'));
});

