import { uniq, sortBy } from 'lodash-es';
import { toLocaleRoundedString } from './utils';
import {
  IElementDataArray,
  IDrawnDataGradientSettings,
} from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/IMikeVisualizerModels';

import MikeVisualizerAnnotations from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/MikeVisualizerAnnotations';
import { DEFAULTS } from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/MikeVisualizerConfiguration';
import { IRepresentation } from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/models/IRepresentation';

const { getDataRangeColors, getUniqueValuesColors } = MikeVisualizerAnnotations;

export interface IWorkspaceAttributeSettings {
  name: string;
  isCategorical?: boolean;
  defaultRepresentation?: IRepresentation;
  defaultPointSize?: number;
  defaultGradientSettings?: IDrawnDataGradientSettings;
}


/**
 * Contains special settings of attributes. Only non-default settings does have to be defined.
 * Not all attributes are yet prefixed with mmg by the API/ALG.
 *
 */
export const ATTRIBUTE_SETTINGS: Array<IWorkspaceAttributeSettings> = [
  { name: 'mmg_AspectRatio' },
  { name: 'AspectRatio' },

  {
    name: 'mmg_Area',
    defaultGradientSettings: { gradientPreset: 'Red to Blue Rainbow' },
  },
  {
    name: 'Area',
    defaultGradientSettings: { gradientPreset: 'Red to Blue Rainbow' },
  },

  { name: 'mmg_ConditionNumber' },
  { name: 'ConditionNumber' },

  {
    name: 'mmg_Region',
    isCategorical: true,
  },
  {
    name: 'Region',
    isCategorical: true,
  },

  {
    name: 'mmg_ElementType',
    isCategorical: true,
  },

  {
    name: 'ElementType',
    isCategorical: true,
  },

];


export interface IAnnotatedValue {
  value;
  annotation: string;
  rgba: Array<number>;
}

interface IAnnotation {
  value: number;
  annotation: string;
}

/**
 * Gets values to be annotated for an given attribute of an element.
 *
 * @param elementId The element.
 * @param gradientDataArray
 * @param gradientSettings
 *
 * @public
 */
export const getAnnotatedValues = (
  elementId: string,
  gradientDataArray: IElementDataArray,
  gradientSettings: IDrawnDataGradientSettings,
): Array<IAnnotatedValue> => {
  if (!elementId || !gradientDataArray) {
    return [];
  }

  const attributeName = gradientDataArray.id;
  if (!attributeName) {
    return [];
  }

  const { gradientColorMap } = gradientSettings || DEFAULTS.gradientSettings;

  if (gradientColorMap) {
    return self.getSelectAnnotatedValues();
  }

  const attributeSetting = ATTRIBUTE_SETTINGS.find((a) => a.name === attributeName);

  const isCategorical = attributeSetting ? attributeSetting.isCategorical : false;

  if (isCategorical) {
    return self.getCategoricalAnnotatedValues(elementId, attributeName);
  }

  const { range } = gradientDataArray;
  return self.getDataRangeAnnotatedValues(elementId, attributeName, range, gradientSettings);
};

/**
 * Will create legends for selctions.
 * Currently no legends are returned
 */
const getSelectAnnotatedValues = (): Array<IAnnotatedValue> => {
  return [];
};

/**
 * Will create annotations for categorical values for a given attribute such as elementtypes, regions etc.
 * An annotation will be created for each unique value in the data.
 *
 * @param elementId
 * @param attributeName
 */
const getCategoricalAnnotatedValues = (elementId: string, attributeName: string): Array<IAnnotatedValue> => {
  if (!elementId || !attributeName) {
    return [];
  }

  const valueColors = getUniqueValuesColors(elementId, attributeName);

  if (valueColors && valueColors.length) {
    const annotations = valueColors.map(({ value, rgba }) => ({
      value,
      rgba,
      annotation: getCategoryDisplayName(attributeName, value),
    }));

    return sortBy(annotations, 'value');
  }

  return [];
};

/**
 * Will create annotated values for the data range of the attribute.
 * Annotations will be rounded according to gradientSettings
 *
 * @param elementId
 * @param attributeName
 * @param range An array holding the min and max value
 * @param gradientSettings Specifies the number of annotated values and the rounding of numbers
 */
const getDataRangeAnnotatedValues = (
  elementId: string,
  attributeName: string,
  range: Array<number>,
  gradientSettings: IDrawnDataGradientSettings,
): Array<IAnnotatedValue> => {
  if (!elementId || !attributeName || !range) {
    return [];
  }

  const { numberOfLegends, numberOfSignificantDigits } = gradientSettings || DEFAULTS.gradientSettings;

  const maxAnnotationTextLength = 7;

  const valueColors = getDataRangeColors(elementId, attributeName, range, numberOfLegends);

  if (valueColors && valueColors.length) {
    const annotations = valueColors.map(({ value, rgba }) => ({
      value,
      rgba,
      annotation: toLocaleRoundedString(value, numberOfSignificantDigits, maxAnnotationTextLength),
    }));

    return annotations;
  }

  return [];
};

/**
 * Will create display names (value and annotation) for categorical values for a given attribute such as elementtypes, regions etc.
 * A display name  will be created for each unique value in the data.
 *
 * @param data Contains the data
 * @param attributeName The name of attribute
 */
export const getCategoricalNames = (data: Array<any>, attributeName: string): Array<IAnnotation> => {
  const categories = uniq(data);
  const annotations = categories.map((value) => ({
    value,
    annotation: getCategoryDisplayName(attributeName, value),
  }));

  return sortBy(annotations, 'value');
};

/**
 * Gets the displayname of a categorcal value
 *
 * @param attributeName The name of the categorical attribute
 * @param value The value of the categorical attribute
 */
export const getCategoryDisplayName = (_attributeName: string, value): string => {
  return value.toString();
  /* const upperName = attributeName.toUpperCase();
  const valuestring = value.toString();

  let key = `PROP_CATEGORY_${upperName}_${valuestring}`;

  let display = t(key);

  if (display === key) {
    key = `PROP_CATEGORY_${upperName}`;
    display = t(key, 1, { value: valuestring });
  }

  if (display === key) {
    display = value;
  } 

  return display;*/
};

const self = {
  getAnnotatedValues,
  getCategoricalAnnotatedValues,
  getDataRangeAnnotatedValues,
  getSelectAnnotatedValues,
};

export default self;
