import React from 'react';
import get from 'lodash/get';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useDropdownStyles } from './sharedDropdownStyles';
import { ReactComponent as ColorCircle } from "@mike/mike-shared-frontend/media/icons/ColorCircle"
import { css } from 'emotion'
interface IProps {
  autoFocus?: boolean;
  disabled?: boolean;
  error?: boolean;
  fullWidth?: boolean;
  items: Array<any>;
  helperText?: string;
  hideIcon?: boolean;
  label?: string;
  name: string;
  value: any;
  valueField: string;
  primaryField: string;
  secondaryField?: string;
  onChange?: (event: React.ChangeEvent<{ value: unknown }>, child?: React.ReactNode) => void;
  showColorIcon?: boolean;
}

export const iconStyle = (color: string) => { 
  return css`  
  path {
    fill: ${color};
  }
`}

export const StyledSelect = (props: IProps) => {
  const classes = useDropdownStyles();
  const {
    autoFocus,
    disabled,
    error,
    fullWidth,
    hideIcon,
    helperText,
    items,
    label,
    name,
    primaryField,
    secondaryField,
    value,
    valueField,
    onChange,
    showColorIcon = true
  } = props;
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>, child?: React.ReactNode) => {
    setOpen(false);
    if (onChange) {
      onChange(event, child);
    }
  };

  const forceUpdateOnItemListChange = `${name}-${items.length}`;

  return (
    <FormControl    
      className={classes.formControl}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      margin={'dense'}
      variant={'filled'}
    >
      {label && (
        <InputLabel id="styled-select-label" htmlFor={name} required={false} disabled={disabled}>
          {label}
        </InputLabel>
      )}
      <Select
        labelId="styled-select-label"
        autoFocus={autoFocus}
        key={forceUpdateOnItemListChange}
        inputProps={{
          name,
          id: name,
          classes: {
            root: value !== '' ? (label ? classes.input : classes.inputNoLabel) : '',
            icon: hideIcon
              ? classes.iconHidden
              : error
              ? classes.iconError
              : disabled && classes.iconDisabled,
          },
        }}
        open={open}
        onChange={handleChange}
        onClose={handleClose}
        onOpen={handleOpen}
        MenuProps={{ classes: { paper: classes.menuList } }}
        value={value}
      >
        {items && items.map((item) => {
          const itemValue = get(item, valueField);
          const selectedItemValue = value;
          const primary = get(item, primaryField);
          const secondary = secondaryField && get(item, secondaryField);
          return (
            <MenuItem  key={itemValue} value={itemValue}>
              {item.color ? 
              <div className={classes.menuItem}>
                {showColorIcon && <ColorCircle width={30} height={30}  viewBox={"0 0 40 40"} className={iconStyle(item.color)}/>}           
                <ListItemText
                  classes={
                    disabled
                      ? { primary: classes.primaryDisabled }
                      : itemValue === selectedItemValue && open
                      ? { primary: classes.primarySelected }
                      : {}
                  }
                  primary={primary}
                  secondary={itemValue === selectedItemValue && !open ? null : secondary}
                />
              </div> :
              <ListItemText
                classes={
                  disabled
                    ? { primary: classes.primaryDisabled }
                    : itemValue === selectedItemValue && open
                    ? { primary: classes.primarySelected }
                    : {}
                }
                primary={primary}
                secondary={itemValue === selectedItemValue && !open ? null : secondary}
              />}
            </MenuItem>
          );
        })}
      </Select>
      {helperText ? (
        <FormHelperText>{helperText}</FormHelperText>
      ) : (
        <div className={classes.helperTextPlaceHolder} />
      )}
    </FormControl>
  );
};
