import React, { useState } from 'react';
import classNames from 'classnames';
import { makeStyles, createStyles, Collapse } from '@material-ui/core';
import { IMikeTheme } from '../../shared/mikeSharedTheme';
import IProjection from './model/IProjection';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme: IMikeTheme) => {
  return createStyles({
    detail: {
      fontSize: '0.8em',
    },
    code: {
      fontSize: '0.8em',
    },
    noDetail: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
    },
    button: {
      height: theme.spacing(0.5),
      fontSize: '0.8em',
    },
    label: {
      fontWeight: theme.typography.fontWeightBold,
    },
    section: {
      whiteSpace: 'normal',
      wordBreak: 'break-all',
    },
    expandedSection: {
      maxWidth: '50vw',
    },
  });
});

interface IProps {
  item: IProjection;
}

const formatWkt = (wkt: string) => wkt.trim().replace(/&quot;/g, '"');

const MikeProjectionDetails = (props: IProps) => {
  const classes = useStyles(props);
  const { item } = props;

  const [open, setOpen] = useState(false);

  const changeOpen = (openChanged: boolean) => (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setOpen(openChanged);
  };

  const wkt = formatWkt(item.wkt);
  const proj4String = item.proj4String.trim();

  return (
    <div>
      {open ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <div>
            <div className={classes.detail}>
              <label className={classes.label}>wkt</label>
              <section className={classNames(classes.section, classes.expandedSection)}>
                {wkt}
              </section>
            </div>

            <div className={classes.detail}>
              <label className={classes.label}>proj4String</label>
              <section className={classNames(classes.section, classes.expandedSection)}>
                {proj4String}
                <Button className={classes.button} onClick={changeOpen(false)}>
                  less
                </Button>
              </section>
            </div>
          </div>
        </Collapse>
      ) : (
        <div className={classes.noDetail}>
          <div className={classes.code}>Code: {item.id}</div>
          <Button className={classes.button} onClick={changeOpen(true)}>
            more
          </Button>
        </div>
      )}
    </div>
  );
};

export default MikeProjectionDetails;
