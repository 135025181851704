import React, { useState } from 'react';
import { css } from 'emotion';
import { mikePalette } from '../../shared/mikeSharedTheme';
import theme from '../../shared/mikeSharedTheme'
import { ReactComponent as Up } from "@mike/mike-shared-frontend/media/icons/Up"
import { ReactComponent as Down } from "@mike/mike-shared-frontend/media/icons/Down"
import Typography from '@material-ui/core/Typography';
import { ReactComponent as Layer } from "@mike/mike-shared-frontend/media/icons/Layer"
import LayerGroup from './LayerGroup';
import { useSelector } from 'react-redux';
import { IState } from '../../reducers';
import { ILayerGroup } from '../../reducers/legend';
import { useIntl } from 'react-intl';
import { orderLayers } from '../../helpers/fastwave';


const WrapperStyle = css`
  position: absolute;
  top: 16px;
  right: 16px;
  z-index:9999;
  background-color: ${mikePalette.background.paper};   
`;

const HeaderStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleAndButtonStyle = css`
  display: flex;
  align-items: center;
`;

const TitleStyle = css`
  padding-left: ${theme.spacing(1)}px;
`;

export const ViewerLegend = () => {
  const intl = useIntl();
  const layerGroups = useSelector(
    (state: IState) => state.legend.layerGroups
  ); 
  const [open, setOpen] = useState(false);

  const handleOnLayerClick = () => {
    orderLayers(); 
  }
  
  return (    
     <div className={WrapperStyle}>     
        <div className={HeaderStyle}>
          <div className={TitleAndButtonStyle}>
            <Layer onClick={handleOnLayerClick}/>
            <Typography className= {TitleStyle} variant="body2"><b>{intl.formatMessage({id: 'viewerLegend.layers'})}</b></Typography>
          </div>          
          {open ? <Up onClick={() => setOpen(!open)}/> : <Down onClick={() => setOpen(!open)} />}
        </div> 
        {open && <div>
          {layerGroups.map((l: ILayerGroup) => <LayerGroup  key={l.title} layerGroup={l}/>)}
        </div>}  
    </div>  
  );
};

export default ViewerLegend