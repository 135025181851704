
import ActionType from "../actions/ActionType"
import { IAction } from "../actions/Action"
import { IJob } from "../model/IFastWaveConfig";
import { JOB_STATUSES } from "../model/IJobStatus";

export interface IJobState {
  jobs: Array<IJob>;
  storedJobs: Array<IJob>; 
}

const initState = {
  jobs: new Array<IJob>(),
  storedJobs: new Array<IJob>()  
}

const getOtherJobs = (state: IJobState, jobIndex: string) => {
  return state.jobs.filter((job: IJob) => job.rowKey !== jobIndex)
} 

const getJob = (state: IJobState, jobIndex: string) => {
  return state.jobs.find((job: IJob) => job.rowKey === jobIndex)
}

const job = (state = initState, action: IAction<any>) => {
  switch (action.type) {
    case ActionType.JOBS_SAVE:
      return {...state, storedJobs: action.data}  

    case ActionType.SET_JOB:{
      const { jobIndex, jobId, start } = action.data   
      const otherJobs = getOtherJobs(state, jobIndex)   
      return { ...state, jobs: [...otherJobs, {rowKey: jobIndex, jobId: jobId, status: JOB_STATUSES.PENDING, start: start}]};
    }
     
    case ActionType.SET_JOB_STATUS: {   
      const { jobIndex, jobStatus } = action.data   
      const job = getJob(state, jobIndex ) 
      const otherJobs = getOtherJobs(state, jobIndex)    
      if (job){
         return {
          ...state,
          jobs: [...otherJobs, {...job, status: jobStatus}]
        }; 
      }
      else{
        return state
      }     
    }
    
    case ActionType.SET_DATA_LINK_JOB:{
      const { jobIndex, jobId, start } = action.data   
      const otherJobs = getOtherJobs(state, jobIndex)   
      return { ...state, jobs: [...otherJobs, {rowKey: jobIndex, jobId: jobId, status: JOB_STATUSES.PENDING, start: start}]};
    }
     
    case ActionType.SET_DATA_LINK_JOB_STATUS: {   
      const { jobIndex, jobStatus } = action.data   
      const job = getJob(state, jobIndex ) 
      const otherJobs = getOtherJobs(state, jobIndex)    
      if (job){
         return {
          ...state,
          jobs: [...otherJobs, {...job, status: jobStatus}]
        }; 
      }
      else{
        return state
      }     
    }  
          
    default:
        return state;
  }
};

export default job