import uniqid from 'uniqid'
import filter from 'lodash/fp/filter'
import without from 'lodash/fp/without'
import isObject from 'lodash/fp/isObject'

import { isApiError } from '../apis/support'

export const ERROR_CODES = {
  AUTH_FAILED: 'AuthError'
}

export const getAuthErrors = filter({ name: ERROR_CODES.AUTH_FAILED })

export const getNotAuthErrors = without({ name: ERROR_CODES.AUTH_FAILED })

export const parseError = (error, custom = {}) => {
/*   const isAnObject = isObject(error)
  const isErrorFromApi = isApiError(error) */
  return {
    id: custom.id || uniqid.time(),
    name: custom.name || (isObject(error) ? error.name : ''),
    message: isApiError(error) ? "Server error" : error,
    isApiError: isApiError(error),
    source: error,
    timestamp: Date.now()
  }
} 
