import React from 'react';
import { css } from 'emotion';
import get from 'lodash/fp/get';
import capitalize from 'lodash/capitalize';
import toLower from 'lodash/fp/toLower';
import { Checkbox, TableCell } from '@material-ui/core';
import { ReactComponent as Folder } from '@mike/mike-shared-frontend/media/icons/Folder';
import { IGetProject } from '../../model/IGetProject';
import { IGetDataset } from '../../model/IGetDataset';
import LongText from './../renders/LongText';
import DateTime from './../renders/DateTime';
import { IColumn } from './../TableComponents/support';
import { isDataset } from './../MikeProjectContentTable/support';
import { ReactComponent as Alert } from '@mike/mike-shared-frontend/media/icons/Alert';

// eslint-disable-next-line no-undef
export const firstTableCellRender = (
  item: IGetProject | IGetDataset,
  isSelectable: boolean,
  isSelected: boolean,
  onClick: (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>, item: IGetProject | IGetDataset) => void,
) => {
  if (isDataset(item)) {
    if (isSelectable) {
      const dataset: IGetDataset = item as IGetDataset;
      const hasSpatialReference = dataset.spatialInformation && dataset.spatialInformation.srid;
      return (
        <TableCell padding="checkbox" onClick={(event) => hasSpatialReference && onClick(event, item)}>
          <Checkbox disabled={!hasSpatialReference} checked={isSelected} />
        </TableCell>
      );
    } else {
      return <TableCell padding="checkbox" />;
    }
  } else {
    return (
      <TableCell padding="checkbox">
        <Folder />
      </TableCell>
    );
  }
};

const noSRIDStyle = css`
  display: flex;
  align-items: center;
`;

const hoverStyle = (item: IGetProject | IGetDataset) => {
  const className =
    'datasetType' in item
      ? null
      : item.capabilities && item.capabilities.canListContent
        ? css`
            &:hover {
              cursor: pointer;
            }
          `
        : null;
  return className;
};

const getGeometryType = get('properties.GeometryType');
const typeRender = (value: string, item: IGetProject | IGetDataset) => {
  const geometryType = getGeometryType(item);  
  const type = value ? geometryType ? capitalize(geometryType) : capitalize(value) : 'Folder';
  return <LongText text={type} />;
};

const formatRender = (value: string, item: IGetProject | IGetDataset) => { 
  return <LongText text={value} />;
};

const typeComparator = (item: IGetProject | IGetDataset) => {
  const geometryType = getGeometryType(item);
  const type =
    'datasetType' in item
      ? geometryType
        ? toLower(item.datasetType) + ' / ' + toLower(geometryType)
        : toLower(item.datasetType)
      : 'zzzz';
  return type;
};

const getSRID = get('spatialInformation.srid');
const projectionSystemRender = (_value: string, item: IGetProject | IGetDataset) => {
  const isDS = isDataset(item);
  const srid = isDS && getSRID(item);
  return !isDS ? (
    ''
  ) : srid ? (
    'EPSG ' + srid
  )  : (
    <div className={noSRIDStyle}>
      <Alert />
      {'N/A'}
    </div>
  );
};

const projectionSystemComparator = (item: IGetProject | IGetDataset) => {
  const isDS = isDataset(item);
  const srid = isDS && getSRID(item);
  const ps = isDS ? (srid ? srid : 88888888) : 99999999;
  return ps;
};

export const itemNameRender = (value: string) => <LongText text={value} />;
const nameComparator = (item: IGetProject | IGetDataset) => item.name.toLowerCase();

const getDatasetFormat= get('datasetFormat');
const formatComparator = (item: IGetProject | IGetDataset) => {
  const isDS = isDataset(item);
  const format = isDS && getDatasetFormat(item);
  const ps = isDS ? (format ? format.toLowerCase() : "") : "";
  return ps;
};

export const dateTimeRender = (value: string) => <DateTime jsonTime={value} />;

export const projectContentColumns = [
  {
    field: 'name',
    label: 'Name',
    render: itemNameRender,
    comparator: nameComparator,
    className: hoverStyle,
  },
  {
    field: 'datasetType',
    label: 'Type',
    render: typeRender,
    comparator: typeComparator,
    className: hoverStyle,
  },
  {
    field: 'datasetFormat',
    label: 'Format',
    render: formatRender,   
    comparator: formatComparator, 
    className: hoverStyle,
  },
  {
    field: 'spatialInformation',
    label: 'Projection system',
    render: projectionSystemRender,
    comparator: projectionSystemComparator,
    className: hoverStyle,
  },
  {
    field: 'createdAt',
    label: 'Created on',
    render: dateTimeRender,
    className: hoverStyle,
  },
  {
    field: 'updatedAt',
    label: 'Edited on',
    render: dateTimeRender,
    className: hoverStyle,
  }  
] as Array<IColumn>;
