/* eslint-disable no-console */
import { END } from 'redux-saga'
import { delay, call, put, takeEvery, take, fork } from 'redux-saga/effects'
import {
  StorageURL,
  BlockBlobURL,
  Aborter,
  uploadBrowserDataToBlockBlob,
  AnonymousCredential
} from '@azure/storage-blob'

import { createEventChannelCallback } from '../helpers/saga'

import { getUploadUrl } from '../apis/blobStorage'

import {
 
  uploadStart,
  updateProgress,
  uploadComplete,
  uploadFail,
  removeUpload
} from '../actions/uploads'
import ActionType from '../actions/ActionType'

const LOG_TAG = 'saga.uploads'

export const UPLOAD_REMOVE_ON_DONE_DELAY = 3000

export default function* watchUploads() {
  yield takeEvery(ActionType.UPLOAD_FILE, uploadFile)
}

export function* uploadFile(action) {  

  const { id, fileName, obj, isFile } = action.data

  let file: File
  if (isFile){
    file = obj
  }
  else{
    const blob = new Blob([JSON.stringify(obj)], {type: 'application/json'});
    file = new File([blob], fileName, {type: 'application/json'});
  }


  try {
    yield put(uploadStart(id))

    const uploadUrl = yield call(getUploadUrl)
    yield call(uploadToAzureStorage, id, file, uploadUrl)

    yield put(uploadComplete(id, uploadUrl, file.size))
    yield delay(UPLOAD_REMOVE_ON_DONE_DELAY)
    yield put(removeUpload(id))
  } catch (error) {
    console.error(LOG_TAG, id, 'Upload failed!', error)
    yield put(uploadFail(id, error))
  }
}

const pipeline = StorageURL.newPipeline(new AnonymousCredential())

export function* uploadToAzureStorage(id, file, uploadUrl) {
 
  const blockBlobURL = new BlockBlobURL(uploadUrl, pipeline)

  const [channel, progressHandler] = yield call(
    createEventChannelCallback,
    createEnhancedProgressCallback(file.size)
  )

  yield fork(uploadBrowserDataToBlockBlob, Aborter.none, file, blockBlobURL, {
    progress: progressHandler
  })

  try {
    while (true) {
      const loadedBytes = yield take(channel)
      const progress = Number(((loadedBytes / file.size) * 100).toFixed(1))

      yield put(updateProgress(id, progress, file.name))
    }
  } finally {
    console.log(LOG_TAG, id, 'Upload completed!') // NOTE: finally needed by the channel api
  }
}

export const createEnhancedProgressCallback = fileSize => (
  emit,
  { loadedBytes }
) => emit(fileSize - loadedBytes <= 0 ? END : loadedBytes)
