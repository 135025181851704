import filter from 'lodash/fp/filter';
import includes from 'lodash/fp/includes';
import toLower from 'lodash/fp/toLower';
import { IGetProject } from '../../model/IGetProject';
import { IGetDataset } from '../../model/IGetDataset';

export const DATASETTYPE = 'datasetType';
export const DATASETFORMAT = 'datasetFormat';

export const isDataset = (item: IGetDataset | IGetProject) => {
  return DATASETFORMAT in item;
};

export const filterProjectContent = (origText: string) => {
  const text = toLower(origText);
  return filter(
    (projectContent: IGetDataset | IGetProject) =>
      includes(text)(toLower(projectContent.name)) ||
      includes(text)(toLower(projectContent.description))
  );
};
