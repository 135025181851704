/* eslint-disable no-console */
import getJSONDatasetContent from "../apis/dataset";
import getTextDatasetContent from "../apis/mesh";
import { delay, call, put, takeEvery, select, all } from 'redux-saga/effects'

import {
  GET_EXPORT_INFO_DELAY,  
  DATASETS,
  MESHTYPE,
  SHORELINE, 
  CSVEXTENSION,
  BOUNDARY_CONDITION_SOURCES,
  BOUNDARY_CONDITION_SOURCE,
} from '../shared/constants'

import {
  TRANSFER_STATUSES,
  getTransfer,
  transferDownloadConvert
} from '../apis/transfer'

import { mapUserDownloadDataToConvertApiBody } from '../helpers/import'
import ActionType from '../actions/ActionType'
import {
  exportStart,
  updateExportProgress,
  exportComplete,
  exportFail,
  removeExport
} from '../actions/exports'
import { fastWaveConfigSet, loadPoints,  pointUpdate, setBoundaryCondition, setLoadingConfig, setSelectedMesh, testRunOutputSet, pointLayerSet, extractionPeriodStartSet, extractionPeriodEndSet, setProj4String, setSelectedOutline, setAreasOfInterest, setBathymetryDatasets, setLoadingSetups, setupsSet, getPointDecimals } from "../actions/mapContent";
import { getCoordinateSystem, getProjectDatasets } from "../apis/metadataApi";
import { IGetDataset } from "../model/IGetDataset";
import uniqid from "uniqid";
import { addError } from "../actions/errors";
import { getBoundaryConditionDataset, getCoordSystems, getExtractionInfoDataset, getFastWaveConfig, getPoints, getProject } from '../reducers/state';
import { extractionPeriod, isOwnUserData } from '../helpers/extractionInfo';
import { getDataLinkExtractionArchive, getLatestDataLinkExtractionArchive } from "../helpers/fastwave";
import { IFastWaveConfig } from "../model/IFastWaveConfig";
import { IWorkspaceGeometry, IWorkspaceVariable, getSetups, getWorkspace, getWorkspaceData } from "../apis/backendApi";
import { IWorkspace } from "../model/IWorkspace";
import IProjection from "../components/mike-projection-select/model/IProjection";
import { IWorkspaceBounds } from "../model/IWorkspaceBounds";
import {AOI, DOMAIN, GEBCO, GEBCO_BATHYMETRY } from "../reducers/createMesh";
import { addShoreline, getCreateMeshPayload, setMeshType, setVtk, setVtkItemId, setWorkspaceScenario } from "../actions/createMesh";
import { AOI_ID, AUTO_MESH_ID, GEBCO_ID, OWN_SHORELINE_ID } from "../components/Viewer";
import { addLayer, getBackgroundLayer, setLayerConfig, setLoadingLayerConfig } from "../actions/legend";
import { meshInputLayers } from "../reducers/legend";
import MikeVisualizerLib from '@mike/mike-shared-frontend/lab/mike-visualizer/lib/MikeVisualizer';
import { setEnvironmentalDataType, setTransformOwnDataLog } from "../actions/ownData";
import { ISetup } from "../model/ISetup";
import { IGetProject } from "../model/IGetProject";

export default function* watchExports() {
  yield takeEvery(ActionType.GET_CONTENT_OF_DATASET, getContent)
}

export function* getContent(action) {
  const project : IGetProject | null = yield select(getProject); 
  if (!project){
    return;
  }
  const { id, datasetId, data, fileName, datasetType, download, setPreviousExtraction } = action.data

  const title = fileName ? 'Downloading (' + fileName + ')' : ''
  try {
    yield put(exportStart(id, title))

    const result = yield call(
      transferDownloadConvert,     
      datasetId,
      mapUserDownloadDataToConvertApiBody(data)
    )   

    let info
    while (true) {
      info = yield call(getTransfer, result.id)  

      if (info.status === TRANSFER_STATUSES.IN_PROGRESS) {
        yield put(updateExportProgress(id, 50, title))
      } else if (info.status === TRANSFER_STATUSES.ERROR) {
        throw new Error(info.errorMessage)
      } else if (info.status === TRANSFER_STATUSES.COMPLETED) {
        break
      }

      yield delay(GET_EXPORT_INFO_DELAY)
    }

    yield put(exportComplete(id))   
    yield put(removeExport(id))
    if (download){      
      yield call(window.open, info.downloadPath, "_self")
    }
    else{    
      let response
      try{   
        const jsonDatasets = [DATASETS.MESHBOUNDARY, DATASETS.TESTRUNOUTPUT, DATASETS.EXTRACTION_INFO, DATASETS.TRANFORM_USER_DATA_LOG]   
        if (jsonDatasets.includes(datasetType)){
          response = yield call(getTextDatasetContent, info.downloadPath)
        }
        else{
          response = yield call(getJSONDatasetContent, info.downloadPath)
        }
      }
      catch(error){
        yield put(addError(error))
      }
      if (response){ 
        if (datasetType === DATASETS.POINTS){  
          if (response.features && response.features.length > 0){
            const points = yield select(getPoints)
            yield put(pointUpdate(points.concat(response.features)))
            yield put(loadPoints(null))
          }
        }
        else if (datasetType === DATASETS.TESTRUNOUTPUT){  
          if (response && response.length > 0){         
            yield put(testRunOutputSet(response))         
          }
        }
        else if (datasetType === DATASETS.TRANFORM_USER_DATA_LOG){  
          if (response && response.length > 0){         
            yield put(setTransformOwnDataLog(response))         
          }
        }
        else if (datasetType === DATASETS.EXTRACTION_INFO){  
          if (response && response.length > 0){ 
            const config: IFastWaveConfig = yield select(getFastWaveConfig)   
            const ownDataFileName = config.userdefined_metocean_data ? config.userdefined_metocean_data : ""
            const extractionZipDataset = yield select(getBoundaryConditionDataset)
            const extractionInfoDataset = yield select(getExtractionInfoDataset)
            const extractionZipName = extractionZipDataset ? extractionZipDataset.name : ""
            if (extractionZipName){
              const infoText: string = response
              let infoFileIsValid = false;
              switch (config.boundary_condition_source){
                case BOUNDARY_CONDITION_SOURCES.UPLOAD:{
                  infoFileIsValid = ownDataFileName && infoText.includes(ownDataFileName.replace(CSVEXTENSION, ""))
                  break;
                }
                case BOUNDARY_CONDITION_SOURCES.PROVIDER:{
                  infoFileIsValid = infoText.includes(extractionZipName);
                  break;
                }
                default:{
                  infoFileIsValid = true
                  break;
                }                
              } 
              
              if (infoFileIsValid){
                const period = extractionPeriod(response)               
                const start = period[0]
                const end = period[1]
                if (setPreviousExtraction || (config && config.boundary_condition_source && config.boundary_condition_source !== BOUNDARY_CONDITION_SOURCES.PREVIOUS)){
                  const configHasExtractionDataset = config.data_link_output_file && config.data_link_output_file.dataset_id && config.data_link_output_file.dataset_id === extractionZipDataset.id ? true : false      
                  const configHasExtractionInfoDataset = configHasExtractionDataset && config.data_link_output_info_file && config.data_link_output_info_file.dataset_id === extractionInfoDataset.id      
                  const startTimeInConfigIsUptodate = configHasExtractionInfoDataset && config.start_time && config.start_time === start ? true : false
                  const configIsUptodate = startTimeInConfigIsUptodate && config.end_time === end
                  if (!configIsUptodate){
                    const updatedConfig = {...config, start_time: start, end_time: end, test_event_time: start,
                      data_link_output_info_file: {name: extractionInfoDataset.name, dataset_id: extractionInfoDataset.id} ,
                      data_link_output_file: {name: extractionZipDataset.name, dataset_id: extractionZipDataset.id},
                      
                    }
                    yield put(fastWaveConfigSet(updatedConfig, true))                  
                  } 
                  yield put(setEnvironmentalDataType(config.boundary_condition_source ? config.boundary_condition_source : BOUNDARY_CONDITION_SOURCES.PROVIDER))
                }             

                const infoFromOwnUserData = isOwnUserData(response)
                if (!infoFromOwnUserData){
                  yield put(extractionPeriodStartSet(start))
                  yield put(extractionPeriodEndSet(end))
                }   
              }         
            }                   
          }
        }   
        else if (datasetType === DATASETS.LAYER_CONFIG){
          yield put(setLayerConfig(response)) 
          yield put(setLoadingLayerConfig(false)) 
          const hiddenLayers = response.hiddenBackgroundLayers ? response.hiddenBackgroundLayers : Array<string>()
          const project = yield select(getProject)
          yield put(getBackgroundLayer(project.id, hiddenLayers))
        }    
        else if (datasetType === DATASETS.CONFIG){ 
          let updatedConfig: IFastWaveConfig = response 
          yield put(fastWaveConfigSet(response)) 
          const project = yield select(getProject)
          const datasets: Array<IGetDataset> = yield call(getProjectDatasets, project.id)
          
          yield put(setLoadingSetups(true))
          let setup = response.setup ? response.setup : ""  
          try{
            const setupResponse = yield call(getSetups)
            const genericSetups = setupResponse ? setupResponse.setups : null
            if (genericSetups && genericSetups.length > 0){
              const genericSetupsNames = genericSetups.map((setup: ISetup) => setup.name)
              const customSetupDatasets = datasets.filter((d: IGetDataset) => d.name.endsWith(".sw") && !genericSetupsNames.includes(d.name))
              const customSetups = customSetupDatasets.map((ds: IGetDataset) => {return {datasetId: ds.id, name: ds.name, displayName: ds.name, description: ds.description, isCustom: true}})
              yield put(setupsSet(genericSetups.concat(customSetups)))
              if (setup){
                const configReferencesGenericSetup = genericSetupsNames.includes(setup)
                if (!configReferencesGenericSetup){
                  const setupDataset = customSetupDatasets.find((ds: IGetDataset) => ds.name === setup)  
                  const setupUnvalid = setupDataset === undefined      
                  if (setupUnvalid) {
                    setup = ""
                    delete updatedConfig.setup                
                  }
                }                
              }
              if (!setup){
                updatedConfig = {...updatedConfig, setup: genericSetupsNames[genericSetupsNames.length - 1]}
                yield put(fastWaveConfigSet(updatedConfig))             
              }   
            } 
          }
          catch (error){
            console.log(error)
          }
          finally{
            yield put(setLoadingSetups(false))
          }  

          const meshId = response.mesh_file && response.mesh_file.dataset_id ? response.mesh_file.dataset_id : ""
          const dataLinkExtractionId = response.data_link_output_file && response.data_link_output_file.dataset_id ? response.data_link_output_file.dataset_id : ""      
                  
          
          const points = response.output_points;
          if (points && points.length > 0){
            const features = points.map((p: any) => {
              const idForNewFeature = p.name ? p.name : uniqid.time()
              return {
                type: "Feature",
                id: idForNewFeature,
                properties:{
                  id: idForNewFeature
                },        
                geometry: {
                  type: "Point",
                  coordinates: [p.x, p.y]
                }
              }
            })
            const geojson = {
              type: "FeatureCollection",
              features: features
            } 
            yield put(pointLayerSet(geojson))  
          }  
         
          
          const latestExtraction = getLatestDataLinkExtractionArchive(datasets)
          
          let meshDataset = undefined;
          if (meshId || dataLinkExtractionId){  
            if (!latestExtraction && updatedConfig.data_link_output_file){              
              delete updatedConfig.data_link_output_file
            } 
           
            if (updatedConfig.mesh_file){
              if (meshId){
                meshDataset = datasets.find((ds: IGetDataset) => ds.id === meshId);
              } 
              if (!meshDataset){              
                delete updatedConfig.mesh_file
              } 
            } 
            yield put(fastWaveConfigSet(updatedConfig))
            if (meshDataset !== undefined){                      
              yield put(setSelectedMesh(meshDataset, project.id))           
            } 
          }
          let boundarySource = BOUNDARY_CONDITION_SOURCES.PROVIDER;
          if (updatedConfig.data_link_output_file){
            if (updatedConfig.boundary_condition_source){
              boundarySource = updatedConfig.boundary_condition_source
            }
            else if (updatedConfig.userdefined_metocean_data){
              boundarySource = BOUNDARY_CONDITION_SOURCES.UPLOAD
            }
          }
          updatedConfig = {...updatedConfig, [BOUNDARY_CONDITION_SOURCE] : boundarySource}  
          yield put(fastWaveConfigSet(updatedConfig))   
          yield put(setEnvironmentalDataType(boundarySource))                    
         
          if (updatedConfig.createMeshConfig && updatedConfig.createMeshConfig.targetSrid){            
            const workspace: IWorkspace = yield call(getWorkspace, project.id )
            if (workspace && workspace.epsgCode === updatedConfig.createMeshConfig.targetSrid && workspace.scenario !== "NotSet"){             
              const { updateData } = MikeVisualizerLib;
              yield put(setWorkspaceScenario(workspace.scenario))
              yield put(setMeshType(MESHTYPE.AUTOMESH)) 
              yield put(getCreateMeshPayload())              
              const coordinateSystems: Array<IProjection> = yield select(getCoordSystems)
              let proj4String;
              let wkt;
              const cs = coordinateSystems.find((p: IProjection) => p.id === workspace.epsgCode)
              if (cs && cs.proj4String){
                proj4String = cs.proj4String
                wkt = cs.wkt
              }
              else{
                const coordinateSystem: IProjection = yield call(getCoordinateSystem, workspace.epsgCode.toString())
                proj4String = coordinateSystem.proj4String
                wkt = coordinateSystem.wkt
              }  
              if (wkt){
                yield put(getPointDecimals(wkt))
              }  
                      
              yield put(setProj4String(proj4String, workspace.epsgCode, workspace.bounds as IWorkspaceBounds, false))
              const variableCount = workspace.variables.length
              if (variableCount > 0){
                const gebco = workspace.variables.find((variable: IWorkspaceVariable) => variable.name.startsWith(GEBCO));    
                let bathys = [] 
                let priority = variableCount + 1;           
                if (gebco !== undefined){
                  priority = priority - 1
                  bathys = [{...gebco, name: GEBCO_BATHYMETRY, priority: priority, selected: true}];                      
                  yield put(setVtkItemId(gebco.id, GEBCO_ID))          
                }
                const bathymetries = workspace.variables.filter((variable: IWorkspaceVariable) => !variable.name.startsWith(GEBCO));
                if (bathymetries.length > 0){
                  const vtks = yield all(bathymetries.map((b: IWorkspaceVariable) => call(getWorkspaceData, project.id, b.id)))      
                  
                  vtks.forEach((vtk: string, index: number,) => {        
                    updateData(vtk, bathymetries[index].id, [0.9490196078431372, 0.9607843137254902, 0.9686274509803922, 1],
                      [0.043137254901960784,0.27058823529411763,0.4, 0.8], {edgeVisibility: false, representation: 2}, 6, false, undefined, null, true, true);
                  });
                  bathys = bathys.concat(bathymetries.map((b: IWorkspaceVariable) => {
                    priority = priority - 1
                    return {...b, priority: priority, selected: true}
                  } ))                 
                  yield all(bathymetries.map((b: IWorkspaceVariable, index: number) => put(addLayer(meshInputLayers, {
                    groupTitle: meshInputLayers,
                    title: b.name,
                    id: b.id,
                    visible: true,
                    isTwoD: false,
                    loaded: true,
                    order: (index * -1) - 1
                  }))))
                }
                               
                yield put(setBathymetryDatasets(bathys))  
              }
              if (workspace.geometries.length > 0){
                const domain = workspace.geometries.find((geometry: IWorkspaceGeometry) => geometry.name === DOMAIN);
                if (domain !== undefined){
                  yield put(setSelectedOutline(domain))
                }
                const aoi = workspace.geometries.find((geometry: IWorkspaceGeometry) => geometry.name === AOI);
                if (aoi !== undefined){
                  yield put(setAreasOfInterest([{...aoi, selected: true}]))
                  yield put(setVtkItemId(aoi.id, AOI_ID))
                }
                const ownShoreline = workspace.geometries.find((geometry: IWorkspaceGeometry) => geometry.name.toLowerCase().startsWith(SHORELINE)); // && geometry.name !== NOAASHORELINE);
                if (ownShoreline){
                  const vtk = yield call(getWorkspaceData,  project.id, ownShoreline.id);
                  yield put(setVtk(vtk, OWN_SHORELINE_ID, ownShoreline.name, project.id))          
                  yield put(addShoreline(ownShoreline))                  
                }
              }
              if (workspace.meshes.length > 0){
                const mesh = workspace.meshes[0];                
                if (mesh.id){
                  yield put(setVtkItemId(mesh.id, AUTO_MESH_ID))                 
                }
              }
            }
            else{
              updatedConfig = {...updatedConfig, createMeshConfig: {} }
            }
          }

          if (updatedConfig.mesh_file){  
            if (updatedConfig.data_link_output_file && updatedConfig.boundary_condition_source && updatedConfig.boundary_condition_source === BOUNDARY_CONDITION_SOURCES.PREVIOUS){
              const selected = getDataLinkExtractionArchive(datasets, updatedConfig.data_link_output_file.name)
              yield put(setBoundaryCondition(selected))
            }    
            else if (!dataLinkExtractionId || (latestExtraction && latestExtraction.id !== dataLinkExtractionId)){
              yield put(setBoundaryCondition(latestExtraction, true))
            }              
          }          
        } 
      }
    }
  } catch (error) {  
    yield put(exportFail(id, error))
  }
  finally{
    if (datasetType === DATASETS.CONFIG){
      yield put(setLoadingConfig(false))
    }
  }
}
