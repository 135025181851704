import React from 'react'
import PropTypes from 'prop-types'

import MuiTextField from '@material-ui/core/TextField'

import withFormik from './withFormik'

const TextField = ({
  autoComplete = 'off',
  autoFocus,
  disabled,
  error,
  fullWidth,
  helperText,
  label,
  name,
  onBlur,
  onChange,
  required,
  rows = 3,
  type = 'text',
  value,
  addPlaceHolder = false
}) => (
  <>
    <MuiTextField
      style={{ minWidth: '8em', whiteSpace: 'nowrap' }}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      helperText={helperText}
      InputLabelProps={{ required: false }}
      label={label}
      multiline={type === 'textarea'}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      required={required}
      minRows={rows}
      type={type === 'textarea' ? 'text' : type}
      value={value}
      margin={'dense'}
      variant={'filled'}
    />
    {/* To avoid jumping when error message appears */}
    {addPlaceHolder && !helperText && (
      <div
        style={{
          minHeight: '24px',
          backgroundColor: 'transparent'
        }}
      />
    )}
  </>
)
TextField.propTypes = {
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  rows: PropTypes.number,
  type: PropTypes.string,
  value: PropTypes.any.isRequired,
  addPlaceHolder: PropTypes.bool
}

export default withFormik(TextField)
