import ActionType from "./ActionType";
import { INotification } from '@mike/mike-shared-frontend/mike-notification-list/INotification'

export const hideBlueDot = (hide = true) => ({
  type: ActionType.HIDE_BLUE_DOT,
  data: hide
})

export const setNotification = (notifications: INotification[]) => ({
  type: ActionType.SET_NOTIFICATION,
  data: notifications
})
