import React, { useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles, createStyles } from '@material-ui/core'
import { IMikeTheme } from '../../shared/mikeSharedTheme'
import { PaletteColor } from '@material-ui/core/styles/createPalette'
import Fade from '@material-ui/core/Fade'
import MikeMyappsButton from '@mike/mike-shared-frontend/mike-myapps-button/mike-myapps-button'
import MikeSupportMenuContainer from '@mike/mike-shared-frontend/mike-support'
import { ReactComponent as FastWaveEmulator } from "@mike/mike-shared-frontend/media/icons/FastWaveEmulator"
import MikeTopbar from '@mike/mike-shared-frontend/mike-topbar/mike-topbar'
import MikeSubscribedApps from '../mike-subscribed-apps';
import { IApplications } from '@mike/mike-shared-frontend/mike-subscribed-apps/model/IApplications';
import MikeNotificationContainer from '@mike/mike-shared-frontend/mike-notification-list'
import ProgressSpinner from '@mike/mike-shared-frontend/mike-topbar-progress-spinner'
import MikeNavWarnBanner from '@mike/mike-shared-frontend/mike-nav-warn-banner/MikeNavWarnBanner'
import { IProgressItem } from '@mike/mike-shared-frontend/mike-topbar-progress-spinner';
import { INotification } from '@mike/mike-shared-frontend/mike-notification-list/INotification'
import { IConfig } from "../../model/IConfig";
import { IState } from "../../reducers";
import {
    hideBlueDot as hideBlueDotAction
  } from '../../actions/notification'
import { useMatch } from 'react-router-dom';
import MikeLogin from '../App/login';
import { IUser } from '../../model/IUser';
import AppBreadcrumpsNav from '../AppBreadcrumpsNav';
import { cancelJob } from '../../actions/job';


export interface AuditCompareRouteParams {
  id: string;
}

const messages = defineMessages({
  appTitle: { id: 'app.title' },
  supportEmailSubject: { id: 'supportEmail.subject' },
  supportEmailBody: { id: 'supportEmail.body' }
})

const styles = makeStyles((theme: IMikeTheme) => {   
    const lightGrey = theme.palette.lightGrey as PaletteColor
    return createStyles({
    root: {
      display: 'flex'
    },
    content: {  
      flexGrow: 1,
      height: '100vh', 
      paddingBottom: theme.spacing(0),
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      paddingTop: theme.spacing(7.5),
      backgroundColor: lightGrey.main,     
      overflow: 'auto',
    },
    contentHideVerticalScroll: {  
      flexGrow: 1,
      height: '100vh', 
      paddingBottom: theme.spacing(0),
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      paddingTop: theme.spacing(7.5),
      backgroundColor: lightGrey.main,    
      overflowY: 'hidden' 
    },
    appBarSpacer: {
      minHeight: theme.spacing(5),
      backgroundColor: theme.palette.lightGrey && theme.palette.lightGrey.main
    },
  })
})

interface IProps 
{
  children: React.ReactNode
}

const AppLayout = (props: IProps) => {  
  const match = useMatch('/project/:id') 
  const matchBC = useMatch('/project/:id/boundaryconditions')
  const matchControl = useMatch('/project/:id/control')
  const matchSetup = useMatch('/project/:id/setup')  
  const matchPoints = useMatch('/project/:id/points')  
  const matchCreateMesh = useMatch('/project/:id/createmesh')   
  const matchInitialSelection = useMatch('/project/:id/initialSelection')    

  const id =  match && match.params.id ? match.params.id : 
  matchBC && matchBC.params.id ? matchBC.params.id : 
  matchControl && matchControl.params.id ? matchControl.params.id : 
  matchSetup && matchSetup.params.id ? matchSetup.params.id : 
  matchCreateMesh && matchCreateMesh.params.id ? matchCreateMesh.params.id : 
  matchInitialSelection && matchInitialSelection.params.id ? matchInitialSelection.params.id : 
  matchPoints && matchPoints.params.id ? matchPoints.params.id :null

  const { children } = props

  const classes = styles()

  const intl = useIntl()

  const dispatch = useDispatch();

  const config: IConfig = useSelector((state: IState) => state.auth.config);

  const apps: IApplications = useSelector((state: IState) => state.applications.applications);

  const loadingApps: boolean = useSelector((state: IState) => state.applications.loading);

  const progressItems: IProgressItem[] = useSelector((state: IState) => state.progressItems);

  const hideBlueDot = useSelector((state: IState) => state.notification.hideBlueDot);

  const user: IUser | null = useSelector((state: IState) => state.auth.user);

  const notifications: INotification[] = useSelector(
    (state: IState) => state.notification.notification
  );

  const isMapRoute = useMemo(() => {
    return matchInitialSelection || matchCreateMesh || matchPoints || matchBC || matchControl || matchSetup
  }, [matchBC, matchControl, matchCreateMesh, matchInitialSelection, matchPoints, matchSetup])

  const mailConfig = {
    to: config && config.supportEmail,
    subject: intl.formatMessage(messages.supportEmailSubject),
    body: intl.formatMessage(messages.supportEmailBody)
  }

  const handleCancelJob = React.useCallback((jobId: string) => {
    dispatch(cancelJob(jobId))    
  }, [dispatch])

  const ProgressActions = () => {
    return (
      <ProgressSpinner progressItems={progressItems} onClickItemButton={(id: string) => dispatch(cancelJob(id)) }/>
  )};

  const onHandleNotificationClickAway = React.useCallback(() => {
    dispatch(hideBlueDotAction(true))
  }, [dispatch])

  const NotificationsDropdown = () => {
    return (
      <MikeNotificationContainer
        showDot={!hideBlueDot}
        notifications={notifications}
        tooltipPosition={{ top: -60, left: -20 }}
        clickAwayCallback={onHandleNotificationClickAway}
      />
  )}
    

  const TopBar = () => {
    return (
      <MikeTopbar
        actionsArea={<ProgressActions/>}
        alertArea={<NotificationsDropdown/>}
        appIcon={<TopbarIcon/>}
        appTitle={intl.formatMessage(messages.appTitle)} 
        breadcrump={id && <AppBreadcrumpsNav />}           
        homeButton={<TopbarHomeButton />}              
        loginButton={<TopbarLoginButton />}
        supportButton={ <MikeSupportMenuContainer
          documentationConfig={[
            { label: "MIKE MS Documentation", href: "https://doc.mikepoweredbydhi.help/webhelp/mike-cloud/metoceansimulator/index.htm", target: '_blank'},
            { label: "MIKE MS Documentation (as pdf-file)", href: "https://doc.mikepoweredbydhi.help/webhelp/mike-cloud/PDF-documentation/DHICloud_MetOcean_Simulator.pdf", target: '_blank'},
            { label: "MIKE Cloud Documentation", href: "https://doc.mikepoweredbydhi.help/webhelp/mike-cloud/MIKE_CLOUD_Documentation_Overview.htm", target: '_blank'}
          ]}
          mailConfig={mailConfig}             
          tooltipPosition={{ top: -60, left: -20 }}            
        />}
        appsDropdown={id ? getSubscribedApps() : null}
      />
    )}

  const TopbarLoginButton = () => {
    return (
      <MikeLogin />
    )}

  const getSubscribedApps = () => {
    return (
      <MikeSubscribedApps
        currentAppName={intl.formatMessage({id: 'app.newRegisteredName'})}
        applications={apps}
        projectId={id ? id : undefined}
        loading={loadingApps}
        tenantId={user?.tenantId}
      />
    )}

  const TopbarHomeButton = () => {
    return (
      <MikeMyappsButton
        loggedIn={true}
        hrefLink={config && config.cloudHomeUri + "?tenantId=" + user?.tenantId}
      />
    )}

  const TopbarIcon = () => {
    return (
      <FastWaveEmulator />
    )}

    return (
      <>
        <CssBaseline />
        <Fade in>
        <div className={classes.root}>
        <TopBar />      
        <main className={isMapRoute ? classes.contentHideVerticalScroll : classes.content}>            
          <MikeNavWarnBanner showAlways={false}/>             
          {children}
        </main>
        </div>
        </Fade>
      </>
    )
  }
  
  export default AppLayout