import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LandingLayout from './LandingLayout';

const AuthenticationCallback = () => {
  const navigate = useNavigate();

  useEffect(
    () => {
      const search = window.location.search;
      const pathName = window.location.pathname;
      const pathAndSearch = search.length > 1 ? pathName + search : pathName;
      navigate(pathAndSearch);
    },
    [navigate],
  );

  return <LandingLayout />;
};

export default AuthenticationCallback;
