import { takeEvery, put, call, select, all } from 'redux-saga/effects'
import ActionType from '../actions/ActionType'
import { IAction } from '../actions/Action'

import { setFolders, setLoadingFolders, getFolders, setCreatingFolder, setCreateFolderPanelOpen, setDeletingFolders } from '../actions/folders'
import { addError } from '../actions/errors'
import { IGetProjectResponse } from '../model/IGetProjectResponse'
import { IState } from "../reducers";
import { delProject, getProjectFolders, getProjectPath, postSubproject, putProject } from '../apis/metadataApi'
import { IGetProjectPath } from '../model/IGetProject'
import { setUpdatingProject } from '../actions/projects'

interface IGetFolders{
  projectId: string;
  offset: number;
  rowsPerPage: number;
  sortBy: string
  sortOrder: 'asc' | 'desc';
  namePrefix: string
}

export const getProjectId = (state: IState) => state.folders.projectId;
export const getPage = (state: IState) => state.folders.page;
export const getRowsPerPage = (state: IState) => state.folders.rowsPerPage;
export const getSortBy = (state: IState) => state.folders.sortBy;
export const getSortOrder = (state: IState) => state.folders.sortOrder;
export const getNamePrefix = (state: IState) => state.folders.namePrefix;

export default function* watchGetFolders() {
  yield takeEvery(ActionType.GET_FOLDERS, handleGetFolders)
  yield takeEvery(ActionType.SET_FOLDERS_PAGINATION, handleSetFoldersPagination)
  yield takeEvery(ActionType.SET_FOLDERS_ROWSPERPAGE, handleSetFoldersRowsPerPage)
  yield takeEvery(ActionType.CREATE_FOLDER, handleCreateFolder)
  yield takeEvery(ActionType.DELETE_FOLDERS, deleteSubProjects)
  yield takeEvery(ActionType.UPDATE_FOLDER, handleUpdateFolder)
}

function* deleteSubProjects(action) {
  const ids = action.data
  const projectId = yield select(getProjectId)
  const page = yield select(getPage)
  const rowsPerPage = yield select(getRowsPerPage)
  const namePrefix = yield select(getNamePrefix)
  const sortBy = yield select(getSortBy)
  const sortOrder = yield select(getSortOrder)

  try {
    yield put(setDeletingFolders())
    yield all(ids.map(id => call(delProject, id)))
    yield put(getFolders(projectId, page, rowsPerPage, sortBy, sortOrder, namePrefix)) 
  } catch (error) {
    yield put(addError(error)); 
  } finally {
    yield put(setDeletingFolders(false))
  }
}

function* handleUpdateFolder(action){
  const data = action.data
  try{
    yield put(setCreateFolderPanelOpen(false))
    yield put(setUpdatingProject())
    yield call(putProject, data)    
    const projectId = yield select(getProjectId)
    const page: number = yield select(getPage);
    const rowsPerPage: number = yield select(getRowsPerPage);
    const sortBy: string = yield select(getSortBy);
    const sortOrder: 'asc' | 'desc' = yield select(getSortOrder);
    const namePrefix: string = yield select(getNamePrefix);    
    yield put(getFolders(projectId, page, rowsPerPage, sortBy, sortOrder, namePrefix)) 
  }
  catch (error){
    yield put(addError(error)); 
  } 
  finally{
    yield put(setUpdatingProject(false))
  }
}

function* handleCreateFolder(action){
  const {data, projectId} = action.data
  try{
    yield put(setCreateFolderPanelOpen(false))
    yield put(setCreatingFolder())
    yield call(postSubproject, data, projectId)
    const page: number = yield select(getPage);
    const rowsPerPage: number = yield select(getRowsPerPage);
    const sortBy: string = yield select(getSortBy);
    const sortOrder: 'asc' | 'desc' = yield select(getSortOrder);
    const namePrefix: string = yield select(getNamePrefix);    
    yield put(getFolders(projectId, page, rowsPerPage, sortBy, sortOrder, namePrefix)) 
  }
  catch (error){
    yield put(addError(error)); 
  } 
  finally{
    yield put(setCreatingFolder(false))
  }
}

function* handleGetFolders(action: IAction<IGetFolders>) {
  try{
    yield put(setLoadingFolders())
    const { projectId, rowsPerPage, sortBy, sortOrder, namePrefix} = action.data

    let projectPath = Array<IGetProjectPath>()
    try{
      projectPath = yield call(getProjectPath, projectId)        
    }
    catch (error){
      yield put(addError(error)); // Add error to global store (errors are shown in an error snackbar)
    }

    const itemInPath = projectPath.find((path: IGetProjectPath) => path.id === projectId)
    if (itemInPath !== undefined){
      if(itemInPath.isDeleted){
        yield put(addError("Folder " + itemInPath.name + " has been deleted (" + projectId + ")")); 
        yield put(setFolders(projectPath, projectId,[], 0, rowsPerPage, 0))
      }
      else{
        let capabilities = itemInPath && itemInPath.capabilities ? itemInPath.capabilities : null  
        // We should only query the backend for folders if parent folder has respective capabilities      
        if (capabilities && capabilities.canListContent){
          const response: IGetProjectResponse = yield call(getProjectFolders, projectId, action.data.offset, rowsPerPage, sortBy, sortOrder ==='asc' ? 'Asc': 'Desc', namePrefix)    
          const { data, totalCount, offset, limit } = response
          yield put(setFolders(projectPath, projectId, data, totalCount, limit !== undefined ? limit : rowsPerPage, offset !== undefined ? offset : 0 ))  
        } 
        else{
          yield put(setFolders(projectPath, projectId,[], 0, rowsPerPage, 0))
        } 
      }      
    }
    else{
      yield put(setFolders(projectPath, projectId,[], 0, rowsPerPage, 0))
    }
    
  }
  catch (error){
    yield put(addError(error)); 
  } 
  yield put(setLoadingFolders(false))
}

function* handleSetFoldersPagination(action: IAction<number>) {
  try{
    yield put(setLoadingFolders())
    const projectId = yield select(getProjectId)
    const rowsPerPage: number = yield select(getRowsPerPage);
    const sortBy: string = yield select(getSortBy);
    const sortOrder: 'asc' | 'desc' = yield select(getSortOrder);
    const namePrefix: string = yield select(getNamePrefix);
    const page = action.data
    yield put(getFolders(projectId, page * rowsPerPage, rowsPerPage, sortBy, sortOrder, namePrefix)) 
  }
  catch (error){
    yield put(addError(error)); 
  } 
  yield put(setLoadingFolders(false))
}

function* handleSetFoldersRowsPerPage(action: IAction<number>) {
  try{
    yield put(setLoadingFolders())
    const projectId = yield select(getProjectId)
    const page: number = yield select(getPage);
    const sortBy: string = yield select(getSortBy);
    const sortOrder: 'asc' | 'desc' = yield select(getSortOrder);
    const namePrefix: string = yield select(getNamePrefix);
    const rowsPerPage = action.data
    yield put(getFolders(projectId, page * rowsPerPage, rowsPerPage, sortBy, sortOrder, namePrefix))
  }
  catch (error){
    yield put(addError(error)); 
  } 
  yield put(setLoadingFolders(false))
}
