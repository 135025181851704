import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import theme from '../../shared/mikeSharedTheme'
import { css } from 'emotion';

const SkeletonStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${theme.spacing(7.5)}px;
  padding-left: ${theme.spacing(1)}px;
  padding-right: ${theme.spacing(1)}px;
`;

interface IProps {
  count: number;
}

const AppSkeleton = (props: IProps) => {
  const { count } = props;

  const skeletonRows = Array.from({ length: count }, (_v, k) => k + 1);

  return (
    <>
      {skeletonRows.map((_row, ind) => {
        return (
          <div className={SkeletonStyle} key={ind}>
            <Skeleton variant="circle" width={40} height={40} />
            <Skeleton width="70%" />
          </div>
        );
      })}
    </>
  );
};

export default AppSkeleton;
