
import ActionType from "./ActionType";

export const addMessage = (message: string) => ({
  type: ActionType.ADD_MESSAGE,
  data: message
})

export const removeMessage = (message = "") => ({
  type: ActionType.REMOVE_MESSAGE,
  data: message
})
