import React from 'react';
import { ViewerToolsWrapperStyle, ViewerToolsContainerStyle } from './ViewerStyles';
import {ViewerToolZoomIn} from './tools/ZoomIn'
import {ViewerToolZoomOut} from './tools/ZoomOut'
import {ViewerToolZoomToExtent} from './tools/ZoomToExtent'
// import ViewerToolDrawPoint from './tools/Draw';

export const ViewerTools = () => {
  return (
    <div className={ViewerToolsWrapperStyle}>
      <ul className={ViewerToolsContainerStyle}>
        <li><ViewerToolZoomIn /></li>
        <li><ViewerToolZoomOut /></li>
        <li><ViewerToolZoomToExtent/></li>
        {/* <li><ViewerToolDrawPoint/></li> */}
      </ul>
    </div>
  );
};

export default ViewerTools