import { DATASETS } from "../shared/constants";
import  ActionType  from "./ActionType";

export const setOwnDataDialogType = (type: string) => ({
  type: ActionType.OWNDATA_SHOW_DIALOG,
  data: type
})

export const setEnvironmentalDataType = (type: string) => ({
  type: ActionType.OWNDATA_SET_DATATYPE,
  data: type
})

export const resetBoundaryCondition = () => ({ 
  type: ActionType.RESET_BOUNDARY_CONDITION
})

export const uploadOwnData = (data: any, fileName: string, datasetType = DATASETS.OWNDATA) => ({
  type: ActionType.OWNDATA_UPLOAD,
  data: {data: data, fileName: fileName, datasetType}
})

export const setOwnDataUploaded = (datasetId: string) => ({
  type: ActionType.OWNDATA_UPLOADED,
  data: datasetId
})

export const downloadOwnDataTemplate = () => ({
  type: ActionType.OWNDATA_DOWNLOAD
})

export const setTransformOwnDataLog = (log: string) => ({
  type: ActionType.SET_TRANFORM_DATA_LOG,
  data: log
})
