import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => {
  return createStyles({
    /* Styles applied to the option elements. */
    option: {
      overflowX: 'hidden',
    },
    /* Styles applied to the `listbox` component. */
    listbox: {
      maxHeight: '20vh',
    },
    primary: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  });
});
