import { OWN_DATA_TEMPLATE_DOWNLOAD_NAME } from "../shared/constants";

const downloadWithNewName = async function downloadFile(url: string, fileName: string) {
  const resp = await fetch(url);
  const res = await resp.text();
  const copy = new File([res], OWN_DATA_TEMPLATE_DOWNLOAD_NAME, {type: 'plain/text' });  
  const aElement = document.createElement('a');
  aElement.setAttribute('download', fileName);
  const href = URL.createObjectURL(copy);
  aElement.href = href;
  aElement.setAttribute('target', '_blank');
  document.body.appendChild(aElement) 
  aElement.click()
  document.body.removeChild(aElement)
  URL.revokeObjectURL(href);  
};

export default downloadWithNewName