import { combineReducers } from 'redux'

import ownData, {IOwnDataState} from './ownData'

import auth, {IAuthState} from './auth'

import notification, {INotificationState} from './notification'

import progressItems  from './progressItems'

import errors, { IErrorsState }  from './errors'

import message, { IMessageState }  from './message'

import { IProgressItem } from '@mike/mike-shared-frontend/mike-topbar-progress-spinner';

import projects, { IProjectsState }  from './projects'

import projectContent, { IProjectContentState }  from './projectContent'

import mapContent, { IMapContentState }  from './mapContent'

import applications, { IAppsState }  from './applications'

import uploads, { IUploadState} from './uploads' 

import imports, { IImportState} from './imports'

import job, {IJobState } from './job'

import check, {ICheckState} from './check'

import createMesh, {ICreateMeshState} from './createMesh'

import legend, {ILegendState} from './legend'

import mood, {IMOODState} from './mood'

import folders, {IFoldersState} from './folders'

export interface IState {
  auth: IAuthState; 
  errors: IErrorsState; 
  message: IMessageState;
  notification: INotificationState; 
  progressItems: IProgressItem[];
  projects: IProjectsState;
  projectContent: IProjectContentState;
  mapContent: IMapContentState;
  applications: IAppsState; 
  uploads: IUploadState;
  imports: IImportState;
  job: IJobState;
  check: ICheckState;
  createMesh: ICreateMeshState;
  legend: ILegendState;
  ownData: IOwnDataState;
  mood: IMOODState;
  folders: IFoldersState;
}

export default combineReducers({
  auth,  
  errors, 
  message,
  notification,
  progressItems,
  projects,
  projectContent,
  mapContent,
  applications, 
  uploads,
  imports,
  job,
  check,
  createMesh,
  legend,
  ownData,
  mood,
  folders
})
