const GIS = "gis"
const GEOJSON = "geojson"
const SHAPEFILE = 'esri shapefile'
const MESH = 'mesh'

export const getCopyDatasetPayload = (format: string) => {  
  switch (format.toLowerCase()){
    case GIS:
      return {readerName:"GISReader", writerName:"GISWriter"}
    case GEOJSON:
      return {readerName:"GeoJsonReader", writerName:"GISWriter"}
    case SHAPEFILE:
      return {readerName:"ShpReader", writerName:"GISWriter"}
    case MESH:
      return {readerName:"MeshFileReader", writerName:"FileWriter"}
  }
}